import AuctionCardWishlistIcon from 'src/components/auction/auction-card-wishlist-icon'
import * as React from 'react'
import { differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns'
import { IAuction } from 'src/_models/auction.model'
import { cn } from 'src/lib/utils'
import { useEffect, useState } from 'react'
import { isPastAuction } from 'src/lib/auction.util'

const AuctionHoursMinutesLeft = ({ auction }: { auction: IAuction }) => {
  const hoursLeft = differenceInHours(auction.auctionEndDate, new Date())
  const [hours, setHours] = useState(hoursLeft)
  const [minutes, setMinutes] = useState(differenceInMinutes(auction.auctionEndDate, new Date()) - hoursLeft * 60)
  useEffect(() => {
    const timeout = setTimeout(() => {
      const hoursLeft = differenceInHours(auction.auctionEndDate, new Date())
      const minutesLeft = differenceInMinutes(auction.auctionEndDate, new Date()) - hoursLeft * 60
      setHours(hoursLeft)
      setMinutes(minutesLeft)
    }, 60_000)

    return () => clearTimeout(timeout)
  }, [])

  return (
    <div className="h-[24px] rounded-[5px] 2xl:h-[31px] flex items-center bg-[#F33] align-middle text-white">
      <div className="px-[10px] py-[5px] align-middle text-[10px] font-bold uppercase leading-[12.1px] 2xl:py-2 2xl:text-xs 2xl:leading-[14.63px]">
        {hours}:{minutes} left
      </div>
    </div>
  )
}

const AuctionCardTopStrip = ({
  auction,
  highestBidCurrentUser,
}: {
  auction: IAuction
  highestBidCurrentUser?: boolean
}) => {
  const createdDays = differenceInDays(new Date(), auction.createdAt)
  const auctionNoLongerLive = auction.auctionStatus === 'CANCELLED' || auction.auctionStatus === 'ENDED'

  const showDaysLeft = () => {
    const days = differenceInDays(auction.auctionEndDate, new Date())

    if (isPastAuction(auction)) {
      return
    }

    if (days === 0) {
      return <AuctionHoursMinutesLeft auction={auction} />
    }

    return (
      <div
        className={
          'h-[24px] rounded-[5px] 2xl:h-[31px] ' +
          (days < 5 ? 'flex items-center bg-[#F33] align-middle text-white' : 'bg-white')
        }
      >
        <div className="px-[10px] py-[5px] align-middle text-[10px] font-bold uppercase leading-[12.1px] 2xl:py-2 2xl:text-xs 2xl:leading-[14.63px]">
          {days} {days === 1 ? 'Day' : 'Days'} left
        </div>
      </div>
    )
  }

  return (
    <div
      className={cn(
        'absolute -top-[1.4rem] h-[24px] w-full px-[15px] 2xl:-top-[1.4rem] 2xl:h-[31px] 2xl:px-5',
        highestBidCurrentUser && '-top-[27px]',
      )}
    >
      <div className="flex justify-between">
        {createdDays < 2 && !auctionNoLongerLive ? (
          <div className="h-[24px] rounded-[5px] bg-orange 2xl:h-[31px]">
            <div className="flex px-[10px] py-[5px] align-middle text-xs font-bold uppercase text-white 2xl:py-2">
              New
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <div className="flex gap-2.5 2xl:gap-4">
          {showDaysLeft()}
          {!auctionNoLongerLive && <AuctionCardWishlistIcon auctionId={auction.id} />}
        </div>
      </div>
    </div>
  )
}
export default AuctionCardTopStrip
