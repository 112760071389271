import { Button } from 'src/components/ui/button'
import * as React from 'react'
import DocumentUpload, { UploadCompanyDocBtn } from 'src/pages/auth/signup/document-upload'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { ICompanyDoc } from 'src/_models/company-doc.model'
import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'

const ProfileSavedDocuments = () => {
  const [companyDocs, setCompanyDocs] = useState<ICompanyDoc[]>()

  const { isLoading: isLoadingCompanyDocs, data: dataCompanyDocs } = useQuery({
    queryKey: ['companyDocs'],
    queryFn: (): Promise<AxiosResponse<{ companyDocs: ICompanyDoc[] }>> => {
      const token = localStorage.getItem('token')
      return axios.get('/api/company-docs', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  useEffect(() => {
    if (dataCompanyDocs?.data && dataCompanyDocs.data.companyDocs.length > 0) {
      setCompanyDocs(dataCompanyDocs.data.companyDocs)
    }
  }, [dataCompanyDocs])

  return (
    <div className="flex w-full">
      <div className="mx-10 w-full max-w-[500px] 2xl:mx-20 2xl:max-w-[600px]">
        <div className="mb-5 w-full">
          <h1 className="pb-[10px]  text-2xl font-bold text-txtblack">{t('documentUpload.heading')}</h1>
          <p className="text-sm font-normal text-txtblack">{t('documentUpload.subHeading')}</p>
        </div>
        {companyDocs?.map((companyDoc) => {
          return <UploadCompanyDocBtn key={companyDoc.id} companyDoc={companyDoc} />
        })}
      </div>
    </div>
    // <div className="flex w-full">
    //   <div className="mx-10 w-full max-w-[724px] 2xl:mx-20 2xl:max-w-[724px]">
    //     <div className="flex w-full flex-col gap-[30px]">
    //       <div className="grid grid-cols-3 gap-5">
    //         <div className="flex flex-col rounded-[20px] bg-white px-5 py-5 align-middle">
    //           <div className="aspect-[0.8:1] relative mx-auto mb-5 py-[8px] text-center">
    //             <img src="/docs-img.svg" className="block h-auto object-cover" />
    //           </div>
    //           <div className="text-center text-sm font-normal 2xl:text-base">Company Registration</div>
    //         </div>
    //         {/* card2 */}
    //         <div className="flex flex-col rounded-[20px] bg-white px-5 py-5 align-middle">
    //           <div className="aspect-[0.8:1] relative mx-auto mb-5 py-[8px] text-center">
    //             <img src="/docs-img.svg" className="block h-auto object-cover" />
    //           </div>
    //           <div className="text-center text-sm font-normal 2xl:text-base">FERC/NERC Registrations</div>
    //         </div>
    //         {/* card3 */}
    //         <div className="flex flex-col rounded-[20px] bg-white px-5 py-5 align-middle">
    //           <div className="aspect-[0.8:1] relative mx-auto mb-5 py-[8px] text-center">
    //             <img src="/docs-img.svg" className="block h-auto object-cover" />
    //           </div>
    //           <div className="text-center text-sm font-normal 2xl:text-base">Proof of Fuel/Electricity Generation</div>
    //         </div>
    //         {/* card3 */}
    //         <div className="flex flex-col rounded-[20px] bg-white px-5 py-5 align-middle">
    //           <div className="aspect-[0.8:1] relative mx-auto mb-5 py-[8px] text-center">
    //             <img src="/docs-img.svg" className="block h-auto object-cover" />
    //           </div>
    //           <div className="text-center text-sm font-normal 2xl:text-base">FedRAMP Document</div>
    //         </div>
    //
    //         {/* card3 */}
    //         <div className="flex flex-col rounded-[20px] bg-white px-5 py-5 align-middle">
    //           <div className="aspect-[0.8:1] relative mx-auto mb-5 py-[8px] text-center">
    //             <img src="/docs-img.svg" className="block h-auto object-cover" />
    //           </div>
    //           <div className="text-center text-sm font-normal 2xl:text-base">Utility Interconnection Agreements</div>
    //         </div>
    //
    //         {/* card3 */}
    //         <div className="px-4.5 flex flex-col rounded-[20px] bg-white py-5 align-middle">
    //           <div className="aspect-[0.8:1] relative mx-auto mb-5 py-[8px] text-center">
    //             <img src="/docs-img.svg" className="block h-auto object-cover" />
    //           </div>
    //           <div className="text-center text-sm font-normal 2xl:text-base">Company Incorporation Document</div>
    //         </div>
    //       </div>
    //       <Button className="w-fit px-[82px] py-[16px]">Update Details</Button>
    //     </div>
    //   </div>
    // </div>
  )
}

export default ProfileSavedDocuments
