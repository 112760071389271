import {IAuction} from "src/_models/auction.model";
import {Dispatch, SetStateAction} from "react";
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import {useUserStore} from "src/_store/user.store";
import {DialogContent} from "src/components/ui/dialog";
import BuyerAuctionUpdateBidDialog from "src/components/auction/buyer-auction-update-bid-dialog";
import BuyerAuctionPlaceBidDialog from "src/components/auction/buyer-auction-place-bid-dialog";
import * as React from "react";

const BuyerAuctionDialog = ({ auction, setShowDialog }: { auction: IAuction; setShowDialog: Dispatch<SetStateAction<boolean>> }) => {
  const { user } = useUserStore((state) => ({
    user: state.user,
  }))

  if(!user) {
    return <></>
  }

  const buyerBidExists = auction.bids.some((bid) => bid.userId === user.id)
  // const highestBidCurrentUser = user?.userType === 'BUYER' && getHighestBidUserId() === user.id

  return buyerBidExists ? (
    <BuyerAuctionUpdateBidDialog auction={auction} setShowDialog={setShowDialog} />
  ) : (
    <BuyerAuctionPlaceBidDialog auction={auction} setShowDialog={setShowDialog} />
  )
}

export default BuyerAuctionDialog
