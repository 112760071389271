import { Dispatch, SetStateAction } from 'react'
import { Button } from 'src/components/ui/button'
import { ISupplierOrder } from 'src/_models/order.model'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import OrderTotalPayableAmount from 'src/components/order/total-payable-amount'
import OrderDocumentStatus from 'src/components/order/document-status'

const SupplierOrderDocumentUpload = ({
  order,
  goNext,
  setShowOrderStatusModal,
}: {
  order: ISupplierOrder
  goNext: () => void
  setShowOrderStatusModal: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation('translation')
  const queryClient = useQueryClient()

  return (
    <>
      <div className="flex max-h-[calc(90vh-514px)] flex-col overflow-y-scroll px-[30px]">
        <OrderDocumentStatus order={order} />
      </div>
      <div className="mt-5 h-[136px] px-[30px]">
        <hr className=" mt-[10px] h-px border-0 bg-[#E0E0E0]" />
        <OrderTotalPayableAmount order={order} />
        <Button variant="secondary" onClick={() => setShowOrderStatusModal(false)}>
          Cancel
        </Button>
      </div>
    </>
  )
}

export default SupplierOrderDocumentUpload
