import { IAuction } from 'src/_models/auction.model'
import { Input } from 'src/components/ui/input'
import * as React from 'react'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Button } from 'src/components/ui/button'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import AuctionDialogTopHeader from 'src/components/auction/auction-dialog-top-header'
import { Checkbox } from 'src/components/ui/checkbox'
import { Label } from 'src/components/ui/label'
import ErrorMessage from 'src/components/ui/error-message'
import { DialogClose } from 'src/components/ui/dialog'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { toast } from 'src/components/ui/use-toast'
import { differenceInDays } from 'date-fns'
import { FUEL_FEES_CALC, BUYER_FEES_CALC, TAXES_CALC } from 'src/lib/constants'
import CurrencyFormatter from 'src/components/ui/currency-formatter'
import AuctionDialogHeader from 'src/components/auction/auction-dialog-header'
import AuctionDialogEstimatedShippingAndShippingRadius from 'src/components/auction/auction-dialog-estimated-shipping-and-shipping-radius'
import { isPastAuction } from 'src/lib/auction.util'
import AuctionDialogFeesCalculation from 'src/components/auction/auction-dialog-fees-calculation'
type IBidForm = {
  bidAmount: number
}

const BuyerAuctionPlaceBidDialog = ({
  auction,
  setShowDialog,
}: {
  auction: IAuction
  setShowDialog: Dispatch<SetStateAction<boolean>>
}) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('translation')
  const { control, watch } = useForm<IBidForm>({
    defaultValues: {
      bidAmount: auction.auctionType === 'AUCTION' ? 0 : (auction.sellingPricePerUnit / 100.0) * auction.fuelWeight,
    },
  })
  const [confirm, setConfirm] = useState<boolean>(false)
  const [anonymous, setAnonymous] = useState<boolean>(false)
  const [total, setTotal] = useState(0)
  const getHighestBid = () => {
    if (!auction.bids) {
      return (auction.sellingPricePerUnit / 100.0) * auction.fuelWeight
    }
    if (auction.bids.length === 0) {
      return (auction.sellingPricePerUnit / 100.0) * auction.fuelWeight
    }

    let highest = 0
    for (const bid of auction.bids) {
      if (highest < bid.bidAmount) highest = bid.bidAmount
    }
    return highest / 100
  }

  const fuelWeightUnitText = t('fuelWeightUnit.' + auction.fuelWeightUnit)

  const getPrice = () => {
    return (auction.sellingPricePerUnit / 100.0) * auction.fuelWeight
  }

  const bidAmount = watch('bidAmount')

  const getBidPrice = () => {
    // const currency = t('currency.' + auction.sellingCurrency)
    return (bidAmount || 0) / auction.fuelWeight
    // return `${currency} ${price}`
  }

  const getTotalBidPriceInDollars = () => {
    return bidAmount
  }

  const purchaseNowMutation = useMutation({
    mutationFn: ({ bidAmount }: { bidAmount: number }) => {
      const token = localStorage.getItem('token')
      return axios.post(
        `/api/buyer/purchase-now/${auction.id}`,
        {
          bidAmount,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })

  const bidMutation = useMutation({
    mutationFn: ({ bidAmount }: { bidAmount: number }) => {
      const token = localStorage.getItem('token')
      return axios.post(
        `/api/buyer/auctions/${auction.id}`,
        {
          bidAmount,
          anonymous,
          // shippingCost: shippingCost,
          totalAmount: total,
          bidConfirmTime: new Date().toISOString(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })

  const performBuyNowMutation = () => {
    purchaseNowMutation.mutate(
      { bidAmount },
      {
        onSuccess: () => {
          toast({
            title: 'Purchase completed.',
          })
          setShowDialog(false)
          queryClient.invalidateQueries()
        },
        onError: (e) => {
          const error = e as AxiosError<{ message: string }>
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description: error.response?.data?.message,
          })
        },
      },
    )
  }

  const performBidMutation = () => {
    bidMutation.mutate(
      { bidAmount },
      {
        onSuccess: () => {
          toast({
            title: 'Bid completed.',
          })
          setShowDialog(false)
          queryClient.invalidateQueries()
        },
        onError: (e) => {
          const error = e as AxiosError<{ message: string }>
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description: error.response?.data?.message,
          })
        },
      },
    )
  }

  return (
    <div className="mx-auto h-full w-full max-w-4xl rounded-[40px] bg-white py-[30px] pl-[30px] pr-3 font-montserrat">
      <div className="text-secoundary">
        <AuctionDialogHeader auction={auction} />
      </div>
      <hr className="mb-[15px] mr-7 mt-5 h-px border-0 bg-[#E0E0E0]" />
      <div className="max-h-[calc(100vh_-_440px)] overflow-auto pr-7">
        <AuctionDialogTopHeader auction={auction} />

        {auction.auctionType === 'AUCTION' ? (
          <>
            <div className="my-5 flex w-full flex-col rounded-[10px] border-[1px] border-[#E0E0E0] bg-[#F2F2F2] py-[10px]">
              <div className="flex justify-around divide-x-[1px] divide-verticalline align-middle">
                <div className="flex w-full flex-col items-center justify-center gap-[10px]">
                  <p className=" block text-sm font-normal text-secoundary 2xl:text-base">Quantity</p>
                  <p className="block text-base font-bold text-secoundary 2xl:text-xl">
                    {/* {t('fuelWeightUnit.' + auction.fuelWeightUnit)} */}
                    {auction.fuelWeight} {fuelWeightUnitText}
                  </p>
                </div>
                <div className="flex w-full flex-col items-center justify-center gap-[10px]">
                  <p className="block text-sm font-normal text-secoundary 2xl:text-base">
                    {auction.bids.length > 0 ? 'Highest Bid' : 'Floor Price'}
                  </p>
                  <p className="block text-base font-bold text-secoundary 2xl:text-xl">
                    <CurrencyFormatter currency={auction.sellingCurrency} amount={getHighestBid()} cents={false} />
                  </p>
                </div>
                <div className="flex w-full flex-col items-center justify-center gap-[10px]">
                  <p className="flex gap-1 text-sm font-normal text-secoundary 2xl:text-base">Total Bids</p>
                  <p className="block text-lg font-bold text-secoundary 2xl:text-xl">{auction.bids.length}</p>
                </div>
              </div>
            </div>
            <div className="flex w-full flex-col rounded-[10px] border-[1px] border-[#FFFFFF] px-[30px] py-[15px] 2xl:py-[20px]">
              <div className="flex flex-col justify-center">
                <div className="mb-[10px] flex w-full items-center justify-between">
                  <p className=" block text-sm text-secoundary 2xl:text-base">Market Price per {fuelWeightUnitText}</p>
                  <p className=" block text-sm text-secoundary 2xl:text-base">
                    <CurrencyFormatter
                      currency={auction.sellingCurrency}
                      amount={auction.sellingPricePerUnit}
                      cents={true}
                    />
                  </p>
                </div>
                <div className="flex w-full items-center justify-between gap-[10px]">
                  <p className=" block text-sm text-secoundary 2xl:text-base">Bid Price per {fuelWeightUnitText}</p>
                  <p className=" block text-sm text-secoundary 2xl:text-base">
                    <CurrencyFormatter currency={auction.sellingCurrency} amount={getBidPrice()} cents={false} />
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-[10px] flex flex-col justify-around 2xl:mt-5">
              <div className="flex w-full items-center justify-between align-middle">
                <p className=" block pl-[30px]  text-sm text-secoundary 2xl:text-base">Place Your Bid</p>

                <p className=" block text-lg font-bold text-secoundary 2xl:text-xl">
                  <Controller
                    name="bidAmount"
                    control={control}
                    rules={{
                      required: { value: true, message: 'error.required' },
                      min: { value: 0, message: 'error.shouldBePositive' },
                    }}
                    render={({ field, fieldState }) => {
                      return (
                        <>
                          {confirm ? (
                            <div className="border:none w-[200px] rounded-[10px] bg-[#F2F2F2] py-4  pr-[30px] text-right ring-0">
                              <CurrencyFormatter currency={auction.sellingCurrency} amount={bidAmount} cents={false} />
                            </div>
                          ) : (
                            <Input
                              disabled={confirm}
                              type="number"
                              className="text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                              {...field}
                              onChange={(e) =>
                                e.target.value ? field.onChange(parseFloat(e.target.value)) : field.onChange(null)
                              }
                            />
                          )}
                          {fieldState.error && (
                            <ErrorMessage className="text-right" message={fieldState.error.message as string} />
                          )}
                        </>
                      )
                    }}
                  />
                </p>
              </div>
            </div>
          </>
        ) : (
          <div className="my-5 flex justify-between gap-5">
            <div className="flex w-1/2 items-center justify-between rounded-[10px] border-[1px] border-[#E0E0E0] bg-[#F2F2F2] px-[20px] py-[22px]">
              <div className="text-sm font-normal 2xl:text-base">Quantity</div>
              <div className="text-lg font-bold 2xl:text-xl">
                {/* {t('fuelWeightUnit.' + auction.fuelWeightUnit)} */}
                {auction.fuelWeight} {fuelWeightUnitText}
              </div>
            </div>

            <div className="flex w-1/2 items-center justify-between rounded-[10px] border-[1px] border-[#E0E0E0] bg-[#F2F2F2] px-[20px] py-[22px]">
              <div className="text-sm font-normal 2xl:text-base">Buying Price</div>
              <div className="text-lg font-bold 2xl:text-xl">
                <CurrencyFormatter currency={auction.sellingCurrency} amount={getPrice()} cents={false} />
              </div>
            </div>
          </div>
        )}

        <div className="mt-[20px] flex w-full flex-col">
          <div className="flex flex-col px-[30px]">
            <AuctionDialogFeesCalculation
              auction={auction}
              bidAmount={getTotalBidPriceInDollars()}
              setTotal={setTotal}
            />
            <AuctionDialogEstimatedShippingAndShippingRadius auction={auction} />
          </div>
        </div>
      </div>
      <hr className="mr-7 mt-[10px] h-px border-0 bg-[#E0E0E0]" />
      <div className="flex w-full flex-col rounded-lg py-5 pr-7">
        <div className="flex flex-col px-[30px]">
          <div className="flex w-full items-center justify-between gap-[10px]">
            <p className="2xl:leading[24.3px] block text-base font-bold leading-[19.5px] text-secoundary 2xl:text-xl">
              Total Payable Amount
            </p>
            <p className="2xl:leading[24.3px] block text-base font-bold leading-[19.5px] text-secoundary 2xl:text-xl">
              <CurrencyFormatter currency={auction.sellingCurrency} amount={total} cents={false} />
            </p>
          </div>
        </div>
      </div>
      {/* Only show the buttons if the auction is not past auction */}
      {!isPastAuction(auction) && (
        <>
          {confirm && (
            <>
              <div className="mt-[10px] flex flex-col px-[27.5px]">
                <p className="w-full items-center gap-1 text-sm font-normal text-secoundary 2xl:text-base">
                  *Are you sure, you want to {auction.auctionType === 'AUCTION' ? 'Place Your Bid' : 'Purchase'}{' '}
                  <span className="uppercase">
                    {auction.fuelColor.name} {auction.fuelType.name}
                  </span>{' '}
                  {auction.fuelPurityLevel.purity} amount {auction.auctionType === 'AUCTION' ? 'at' : 'of'}{' '}
                  <span className="text-lg font-bold 2xl:text-xl">
                    <CurrencyFormatter currency={auction.sellingCurrency} amount={total} cents={false} />
                  </span>
                </p>
              </div>
              {auction.auctionType === 'AUCTION' && (
                <div className="mt-5 flex items-center space-x-2">
                  <Label
                    htmlFor="anonymous"
                    onClick={() => setAnonymous((c) => !c)}
                    className="flex gap-[10px] text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    <Checkbox checked={anonymous} />
                    Keep Identity Anonymous
                  </Label>
                </div>
              )}
            </>
          )}
          {auction.auctionType === 'AUCTION' ? (
            <div className="mt-[10px] flex gap-5 pr-7 2xl:mt-[20px]">
              {confirm ? (
                <>
                  <Button variant="secondary" onClick={() => setConfirm(false)}>
                    Cancel
                  </Button>
                  <Button onClick={performBidMutation}>Confirm Bid</Button>
                </>
              ) : (
                <>
                  <DialogClose asChild>
                    <Button variant="secondary">Cancel</Button>
                  </DialogClose>
                  <Button onClick={() => setConfirm(true)}>Place Bid</Button>
                </>
              )}
            </div>
          ) : (
            <div className="mt-[10px] flex gap-5 pr-7 2xl:mt-[20px]">
              {confirm ? (
                <Button onClick={performBuyNowMutation}>Confirm Purchase</Button>
              ) : (
                <Button tabindex="-1" onClick={() => setConfirm(true)} className="" autoFocus={false}>
                  Buy Now
                </Button>
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default BuyerAuctionPlaceBidDialog
