import * as React from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import StatsCard from 'src/components/common/statsCard'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import DashboardSharedPayment from 'src/pages/dashboard/dashboard-shared-payment'
import DashboardSharedTierStatus from 'src/pages/dashboard/dashboard-shared-tier-status'
import DashboardSharedRecentUpdates from 'src/pages/dashboard/dashboard-shared-recent-updates'
import DashboardCardStats from 'src/pages/dashboard/dashboard-card-stats'
import DashboardSharedRecentTransactions from 'src/pages/dashboard/dashboard-shared-recent-transactions'

export default function BuyerDashboard() {
  const { data, isLoading } = useQuery({
    queryKey: [],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get<{
        auctionClosingSoon: number
        liveAuctionClosingSoon: number
        ordersNeedAttention: number
        ordersCompleted: number
        recTradingAuctionClosingSoon: number
        recTradingLiveAuctionClosingSoon: number
        recTradingOrderNeedAttentionSoon: number
        recTradingOrderCompletedSoon: number
        recSpends: { label: string; value: string; color: string }[]
        totalSpends: { label: string; value: string; color: string }[]
        fuelTradingTotalSpends: { label: string; value: string; color: string }[]
        totalQuantity: number
      }>('/api/dashboard', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
    cacheTime: 60,
  })

  const finalData = data?.data

  return (
    <>
      <div className="mb-[66px] mt-5 w-full bg-lightblue font-montserrat 2xl:mt-10">
        <div className="container mx-auto">
          <div className="flex w-full gap-5">
            {/* dashboard left panel start */}
            <div className="flex-none font-montserrat xl:w-[305px] 2xl:w-[425px]">
              <div className="flex w-full flex-col">
                {/* card start */}
                <DashboardSharedPayment />
                {/* current status box */}
                <DashboardSharedTierStatus totalQuantity={finalData?.totalQuantity || 0} />
                {/* recent updates */}
                <DashboardSharedRecentUpdates />
              </div>
            </div>
            {/* dashboard left panel end */}
            {/* dashboard Right panel start*/}
            {/* <div className="w-[calc(56% - 120px)]min-w-0 flex-1"> */}
            <div className="xl:w-[calc(100%-305px)] 2xl:w-[calc(100%-425px)]">
              {finalData && (
                <>
                  <div className="flex w-full gap-5 2xl:gap-[21px]">
                    <DashboardCardStats label="Total Spend" stats={finalData.totalSpends} />
                    <DashboardCardStats label="Fuel Trading Spend" stats={finalData.fuelTradingTotalSpends} />
                    <DashboardCardStats label="REC Spend" stats={finalData.recSpends} />
                  </div>

                  <div className="w-full py-10">
                    <div className="mb-5 flex w-full">
                      <div className="w-1/2">
                        <h2 className="font-bold xl:text-base 2xl:text-lg">Fuel Trading</h2>
                      </div>
                      <div className="w-1/2">
                        <h2 className="ml-[40px] font-bold xl:text-base 2xl:text-lg"> REC Trading</h2>
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">
                        <div className="flex w-full gap-5">
                          <StatsCard
                            url="/buyer/my-auctions?daysLeft=5"
                            number={finalData.auctionClosingSoon}
                            text="My Auctions Closing Soon"
                            isDotVisible={false}
                          />
                          <StatsCard
                            url="/live-auctions?daysLeft=5"
                            number={finalData.liveAuctionClosingSoon}
                            text="Live Auctions Closing Soon"
                            isDotVisible={false}
                          />
                        </div>

                        <div className="flex w-full gap-5">
                          <StatsCard
                            url="/fuel-trading/orders-in-progress"
                            number={finalData.ordersNeedAttention}
                            text="Orders that Need Attention"
                            isDotVisible={false}
                          />
                          <StatsCard
                            url="/fuel-trading/order-completed"
                            number={finalData.ordersCompleted}
                            text="Orders Completed"
                            isDotVisible={false}
                          />
                        </div>
                      </div>
                      <div className="border-x-1 mx-10 border border-[#bdbdbd] py-[42px]"></div>
                      <div className="w-1/2">
                        <div className="flex w-full gap-5">
                          <StatsCard
                            url="/rec-trading"
                            number={finalData.recTradingAuctionClosingSoon}
                            text="My Auctions Closing Soon"
                            isDotVisible={false}
                          />
                          <StatsCard
                            url="/rec-trading"
                            number={finalData.recTradingLiveAuctionClosingSoon}
                            text="Live Auctions Closing Soon"
                            isDotVisible={false}
                          />
                        </div>

                        <div className="flex w-full gap-5">
                          <StatsCard
                            url="/rec-trading"
                            number={finalData.recTradingOrderNeedAttentionSoon}
                            text="Orders that Need Attention"
                            isDotVisible={false}
                          />
                          <StatsCard
                            url="/rec-trading"
                            number={finalData.recTradingOrderCompletedSoon}
                            text="Orders Completed"
                            isDotVisible={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <DashboardSharedRecentTransactions />
            </div>
            {/* dashboard right panel end */}
          </div>
        </div>
      </div>
    </>
  )
}
