import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { formatAuctionDate } from 'src/lib/format-auction'

const DashboardSharedPlatformUpdates = ({ type }: { type: string }) => {
  const platformUpdatesQuery = useQuery({
    queryKey: [`platformUpdates-${type}`],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get(`/api/platform-updates?type=${type}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }) as Promise<
        AxiosResponse<{
          platformUpdates: {
            id: number
            message: string
            urlLink: string
            createdAt: string
          }[]
        }>
      >
    },
    cacheTime: 60,
  })

  if (platformUpdatesQuery.isLoading) {
    return <div className="text-center">Loading ...</div>
  }

  if (
    platformUpdatesQuery.data &&
    platformUpdatesQuery.data.data &&
    platformUpdatesQuery.data.data.platformUpdates.length === 0
  ) {
    return <div className="text-center">No Updates</div>
  }

  return (
    platformUpdatesQuery.data &&
    platformUpdatesQuery.data.data.platformUpdates.map((platformUpdate) => {
      return (
        <div
          // onClick={() => window.open(platformUpdate.urlLink, '_blank')}
          className="flex w-full flex-col cursor-pointer"
        >
          <div className="mb-[10px] flex justify-between">
            {/*<div className="flex items-center gap-[5px] align-middle">*/}
            {/*<span className="font-bold text-primary xl:text-[10px]">ID# TQ-H-000000001</span>*/}
            {/*</div>*/}
            <div className="text-xs font-bold text-secoundary 2xl:text-sm">
              {formatAuctionDate(platformUpdate.createdAt)}
            </div>
          </div>
          <div className="text-xs font-medium text-secoundary 2xl:text-sm">
            {/*<span className="text-xs font-bold text-secoundary 2xl:text-sm">Green Ammonia</span>*/}
            {/*<span className="font-bold text-primary xl:text-[10px]">99.9995%</span> */}
            {platformUpdate.message} &nbsp;
            {platformUpdate.urlLink && (
              <a href={platformUpdate.urlLink} target="_blank" className="text-primary text-xs xl:text-sm">
                Click here
              </a>
            )}
          </div>
          <hr className="my-5 h-[1px] w-full bg-gray2" />
        </div>
      )
    })
  )
}

export default DashboardSharedPlatformUpdates
