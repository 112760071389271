import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { IBuyerOrder, IOrder, ISupplierOrder } from 'src/_models/order.model'
import { useUserStore } from 'src/_store/user.store'
import { ColumnDef } from '@tanstack/react-table'
import { formatAuctionDate } from 'src/lib/format-auction'
import currencyFormatter from 'src/components/ui/currency-formatter'
import { download, generateCsv, mkConfig } from 'export-to-csv'
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover'
import { Button } from 'src/components/ui/button'
import { DataTable } from 'src/components/data-table'
import { Dialog, DialogContent } from 'src/components/ui/dialog'
import BuyerOrderDialog from 'src/components/order/buyer/buyer-order-dialog'
import SupplierOrderDialog from 'src/components/order/supplier/supplier-order-dialog'
import AuctionFilterBtn from 'src/components/auction/auction-filter-btn'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'src/components/ui/use-toast'

const OrderDetailTables = ({ heading, orders }: { heading: string; orders: IOrder[] }) => {
  const { t } = useTranslation('translation')
  const [searchParams, _] = useSearchParams()
  const [selectedOrder, setSelectedOrder] = useState<ISupplierOrder | null>(null)
  const [showOrderStatusModal, setShowOrderStatusModal] = useState<boolean>(false)

  const orderId = searchParams.get('orderId')
  const error = searchParams.get('error')
  const message = searchParams.get('message')

  const { user } = useUserStore((state) => {
    return {
      user: state.user,
    }
  })

  useEffect(() => {
    if (!orderId) {
      return
    }

    const order = orders.find((order) => order.id === parseInt(orderId))

    if (!order) {
      return
    }
    setShowOrderStatusModal(true)
    setSelectedOrder(order)
  }, [orderId, orders])

  useEffect(() => {
    if (error) {
      toast({
        title: 'Error',
        description: error,
        variant: 'destructive',
      })
    }
  }, [error])

  useEffect(() => {
    if (message) {
      toast({
        title: 'Message',
        description: message,
        variant: 'default',
      })
    }
  }, [message])

  const getBidAmount = (order: ISupplierOrder) => {
    return order.auction.auctionType === 'AUCTION'
      ? order.winningBid?.bidAmount || 0
      : order.auction.fuelWeight * order.auction.sellingPricePerUnit
  }

  const orderNeedsAttention = (order: IOrder) => {
    if (
      (order.supplierNeedsAttention && user?.userType === 'SUPPLIER') ||
      (order.buyerNeedsAttention && user?.userType === 'BUYER')
    ) {
      return <div className="bg-primary w-[10px] h-[10px] rounded-full"></div>
    }

    return <></>
  }

  const columnsOrders: ColumnDef<ISupplierOrder>[] = [
    {
      accessorKey: 'order_date',
      header: 'ORDER DATE',
      cell: ({ row }) => {
        return (
          <div className="text-center flex flex-row gap-2.5 items-center">
            {orderNeedsAttention(row.original)} {formatAuctionDate(row.original.createdAt)}
          </div>
        )
      },
    },
    {
      accessorKey: 'order_id',
      header: 'ORDER ID',
      cell: ({ row }) => {
        return <div className="text-center">{row.original.auction.generatedId}</div>
      },
    },
    {
      accessorKey: 'fuel_type',
      header: 'FUEL TYPE',
      cell: ({ row }) => {
        return <div className="text-center">{row.original.auction.fuelType.name}</div>
      },
    },
    {
      accessorKey: 'color',
      header: 'COLOR',
      cell: ({ row }) => {
        return <div className="text-center">{row.original.auction.fuelColor.name}</div>
      },
    },
    {
      accessorKey: 'purity_level',
      header: 'PURITY LEVEL',
      cell: ({ row }) => {
        return <div className="text-center">{row.original.auction.fuelPurityLevel.purity}</div>
      },
    },
    {
      accessorKey: 'quantity',
      header: 'QUANTITY',
      cell: ({ row }) => {
        return (
          <div className="text-center">
            {row.original.auction.fuelWeight} {t('fuelWeightUnit.' + row.original.auction.fuelWeightUnit)}
          </div>
        )
      },
    },
    {
      accessorKey: 'status_order',
      header: 'STATUS OF ORDER',
      cell: ({ row }) => {
        return <div className="text-center">{t('fuelTradingStatus.' + row.original.status)}</div>
      },
    },
    {
      accessorKey: 'buyer_name',
      header: 'Buyer Name',
      cell: ({ row }) => {
        return <div className="text-center">{row.original.buyer.companyName}</div>
      },
    },
    {
      accessorKey: 'winning_bid',
      header: 'Winning Bid',
      cell: ({ row }) => {
        return (
          <div className="text-center">
            {currencyFormatter({
              currency: row.original.auction.sellingCurrency,
              amount: getBidAmount(row.original),
              cents: true,
            })}
          </div>
        )
      },
    },
    {
      accessorKey: 'action',
      header: '',
      cell: ({ row }) => {
        // const payment = row.original

        return (
          <a
            onClick={() => {
              setSelectedOrder(row.original)
              setShowOrderStatusModal(true)
              if (user?.userType === 'BUYER') {
                row.original = { ...row.original, buyerNeedsAttention: false }
              }
              if (user?.userType === 'SUPPLIER') {
                row.original = { ...row.original, supplierNeedsAttention: false }
              }
            }}
            className="cursor-pointer font-bold text-[#0077B6] underline"
          >
            View&nbsp;Order
          </a>
        )
      },
    },
  ]

  const exportCSV = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ',',
      filename: heading, // export file name (without .csv)
      decimalSeparator: '.',
      useKeysAsHeaders: true,
    })
    const rowData = orders.map((row) => ({
      'ORDER DATE': formatAuctionDate(row.createdAt),
      'ORDER ID': row.auction.generatedId,
      'FUEL TYPE': row.auction.fuelType.name,
      COLOR: row.auction.fuelColor.name,
      'PURITY LEVEL': row.auction.fuelPurityLevel.purity,
      QUANTITY: `${row.auction.fuelWeight} ${t('fuelWeightUnit.' + row.auction.fuelWeightUnit)}`,
      'STATUS OF ORDER': row.status,
      'Buyer Name': row.buyer.companyName,
      'Winning Bid': t('currency.' + row.auction.sellingCurrency) + ' ' + getBidAmount(row),
    }))
    const csv = generateCsv(csvConfig)(rowData)
    download(csvConfig)(csv)
  }

  return (
    <div className="container mx-auto bg-transparent ">
      <div className="mb-[15px] flex w-full items-center justify-between pt-10 align-middle">
        <h2 className="mb-2 text-lg font-bold text-secoundary 2xl:text-xl">{heading}</h2>
        <span className="relative text-sm">
          <div className="flex gap-[10px]">
            <AuctionFilterBtn />
            <Popover>
              <PopoverTrigger asChild>
                <Button type="button" size="icon" variant="iconTable" className="py-0">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 10 13" fill="none">
                    <path
                      d="M0.5 12.5645H10V11.1527H0.5M10 4.79975H7.28572V0.564453H3.21429V4.79975H0.5L5.25 9.74092L10 4.79975Z"
                      fill="#333333"
                    ></path>
                  </svg>
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-48" align="end">
                <div className="cursor-pointer" onClick={exportCSV}>
                  Export As CSV
                </div>
              </PopoverContent>
            </Popover>
          </div>
          {/*{showFilter && (*/}
          {/*  <FilterComponent*/}
          {/*    // className="absolute right-0"*/}
          {/*    onCancel={() => setShowFilter(false)}*/}
          {/*  />*/}
          {/*)}*/}
        </span>
      </div>

      <DataTable variant="column-bg-grey" columns={columnsOrders} data={orders} headerHidden={false} />

      {selectedOrder && (
        <Dialog open={showOrderStatusModal} onOpenChange={(value) => setShowOrderStatusModal(value)}>
          <DialogContent className="mx-auto h-auto max-h-[90vh] w-full max-w-[741px] overflow-y-scroll rounded-[40px] bg-white px-0 py-[30px]">
            {user?.userType === 'BUYER' ? (
              <BuyerOrderDialog order={selectedOrder} setShowOrderStatusModal={setShowOrderStatusModal} />
            ) : (
              <SupplierOrderDialog order={selectedOrder} setShowOrderStatusModal={setShowOrderStatusModal} />
            )}
          </DialogContent>
        </Dialog>
      )}
    </div>
  )
}

export default OrderDetailTables
