import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useUserStore } from 'src/_store/user.store'
import { ColumnDef } from '@tanstack/react-table'
import { formatAuctionDate } from 'src/lib/format-auction'
import currencyFormatter from 'src/components/ui/currency-formatter'
import { download, generateCsv, mkConfig } from 'export-to-csv'
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover'
import { Button } from 'src/components/ui/button'
import { DataTable } from 'src/components/data-table'
import { Dialog, DialogContent } from 'src/components/ui/dialog'
import { IAuctionWithFuelTradingOrders } from 'src/_models/auction.model'
import BuyerAuctionPlaceBidDialog from 'src/components/auction/buyer-auction-place-bid-dialog'
import SupplierAuctionDialog from 'src/components/auction/supplier-auction-dialog'
import AuctionFilterBtn from 'src/components/auction/auction-filter-btn'
import * as React from 'react'

const PastAuctionsTable = ({ heading, auctions }: { heading: string; auctions: IAuctionWithFuelTradingOrders[] }) => {
  const { t } = useTranslation('translation')
  const [selectedAuction, setSelectedAuction] = useState<IAuctionWithFuelTradingOrders | null>(null)

  const { user } = useUserStore((state) => {
    return {
      user: state.user,
    }
  })

  const [showOrderStatusModal, setShowOrderStatusModal] = useState<boolean>(false)

  const columnsOrders: ColumnDef<IAuctionWithFuelTradingOrders>[] = [
    {
      accessorKey: 'auctionEndDate',
      header: 'Auction End Date',
      cell: ({ row }) => {
        return <div className="text-center">{formatAuctionDate(row.original.auctionEndDate.toLocaleString())}</div>
      },
    },
    {
      accessorKey: 'id',
      header: 'Auction Id',
      cell: ({ row }) => {
        return <div className="text-center">{row.original.generatedId}</div>
      },
    },
    {
      accessorKey: 'fuel_type',
      header: 'FUEL TYPE',
      cell: ({ row }) => {
        return <div className="text-center">{row.original.fuelType.name}</div>
      },
    },
    {
      accessorKey: 'color',
      header: 'COLOR',
      cell: ({ row }) => {
        return <div className="text-center">{row.original.fuelColor.name}</div>
      },
    },
    {
      accessorKey: 'purity_level',
      header: 'PURITY LEVEL',
      cell: ({ row }) => {
        return <div className="text-center">{row.original.fuelPurityLevel.purity}</div>
      },
    },
    {
      accessorKey: 'quantity',
      header: 'QUANTITY',
      cell: ({ row }) => {
        return (
          <div className="text-center">
            {row.original.fuelWeight} {t('fuelWeightUnit.' + row.original.fuelWeightUnit)}
          </div>
        )
      },
    },
    {
      accessorKey: 'price_per_kg',
      header: 'Price/Kg',
      cell: ({ row }) => {
        return (
          <div className="text-center">
            {currencyFormatter({
              currency: row.original.sellingCurrency,
              amount: row.original.sellingPricePerUnit,
              cents: true,
            })}
          </div>
        )
      },
    },
    {
      accessorKey: 'buyer_name',
      header: 'Buyer Name',
      cell: ({ row }) => {
        return <div className="text-center">{row.original.fuelTradingOrders[0]?.buyer?.companyName}</div>
      },
    },
    {
      accessorKey: 'winningBid',
      header: 'Winning Bid',
      cell: ({ row }) => {
        const totalAmount = row.original.fuelTradingOrders[0]?.totalAmount
        return totalAmount ? (
          <div className="text-center">
            {currencyFormatter({
              currency: row.original.sellingCurrency,
              amount: row.original.fuelTradingOrders[0]?.totalAmount,
              cents: true,
            })}
          </div>
        ) : (
          '-'
        )
      },
    },
    {
      accessorKey: 'action',
      header: '',
      cell: ({ row }) => {
        // const payment = row.original

        return (
          <a
            onClick={() => {
              setSelectedAuction(row.original)
              setShowOrderStatusModal(true)
            }}
            className="cursor-pointer font-bold text-[#0077B6] underline"
          >
            View&nbsp;Order
          </a>
        )
      },
    },
  ]

  const exportCSV = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ',',
      filename: heading, // export file name (without .csv)
      decimalSeparator: '.',
      useKeysAsHeaders: true,
    })
    const rowData = auctions.map((row) => ({
      'Auction End Date': formatAuctionDate(row.createdAt),
      'Auction Id': row.generatedId,
      // 'Auction Type': t(`auctionType.${row.order.auction.auctionType}`),
      'FUEL TYPE': row.fuelType.name,
      COLOR: row.fuelColor.name,
      'PURITY LEVEL': row.fuelPurityLevel.purity,
      QUANTITY: `${row.fuelWeight} ${t('fuelWeightUnit.' + row.fuelWeightUnit)}`,
      'Price/Kg': t('currency.' + row.sellingCurrency) + ` ${row.sellingPricePerUnit / 100}`,
      // 'Buyer Name': row.order.buyer.companyName,
      // 'Transaction Type': 'PURCHASE',
      // Amount: t('currency.' + row.currency) + ` ${row.amount / 100}`,
    }))
    const csv = generateCsv(csvConfig)(rowData)
    download(csvConfig)(csv)
  }

  return (
    <div className="container mx-auto bg-transparent ">
      <div className="mb-[15px] flex w-full items-center justify-between pt-10 align-middle">
        <h2 className="mb-2 text-lg font-bold text-secoundary 2xl:text-xl">{heading}</h2>
        <span className="relative text-sm">
          <div className="flex gap-[10px]">
            <AuctionFilterBtn />
            <Popover>
              <PopoverTrigger asChild>
                <Button type="button" size="icon" variant="iconTable" className="py-0">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 10 13" fill="none">
                    <path
                      d="M0.5 12.5645H10V11.1527H0.5M10 4.79975H7.28572V0.564453H3.21429V4.79975H0.5L5.25 9.74092L10 4.79975Z"
                      fill="#333333"
                    ></path>
                  </svg>
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-48" align="end">
                <div className="cursor-pointer" onClick={exportCSV}>
                  Export As CSV
                </div>
              </PopoverContent>
            </Popover>
          </div>
          {/*{showFilter && (*/}
          {/*  <FilterComponent*/}
          {/*    // className="absolute right-0"*/}
          {/*    onCancel={() => setShowFilter(false)}*/}
          {/*  />*/}
          {/*)}*/}
        </span>
      </div>

      <DataTable variant="column-bg-grey" columns={columnsOrders} data={auctions} headerHidden={false} />

      {selectedAuction && (
        <Dialog open={showOrderStatusModal} onOpenChange={(value) => setShowOrderStatusModal(value)}>
          <DialogContent className="mx-auto h-auto max-h-[90vh] w-full max-w-[741px] overflow-y-scroll rounded-[40px] bg-white px-0 py-[30px]">
            {user?.userType === 'BUYER' ? (
              <BuyerAuctionPlaceBidDialog auction={selectedAuction} setShowDialog={setShowOrderStatusModal} />
            ) : (
              <SupplierAuctionDialog auction={selectedAuction} setShowDialog={setShowOrderStatusModal} />
            )}
          </DialogContent>
        </Dialog>
      )}
    </div>
  )
}

export default PastAuctionsTable
