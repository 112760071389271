import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const OrderSignatureGuaranteeFileView = ({ fileType, orderId }: { fileType: string; orderId: number }) => {
  const [image, setImage] = useState<string>()
  const { data: fileQueryResp } = useQuery({
    queryKey: [`order-${orderId}-${fileType}`],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get(`/api/order-signature-guarantee/file/${orderId}/${fileType}`, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  useEffect(() => {
    if (!fileQueryResp) {
      return
    }
    const reader = new window.FileReader()
    reader.readAsDataURL(fileQueryResp.data)
    reader.onload = (result) => {
      if (result.loaded && result.target?.result) {
        setImage(result.target?.result as string)
      }
    }
  }, [fileQueryResp])

  // @ts-ignore
  return image && <img alt={fileType} src={image} className="absolute h-full w-full object-cover" />
}

export default OrderSignatureGuaranteeFileView
