import { ISupplierOrder } from 'src/_models/order.model'
import { Dispatch, SetStateAction } from 'react'
import { Button } from 'src/components/ui/button'
import OrderTotalPayableAmount from 'src/components/order/total-payable-amount'
import OrderDocumentStatus from 'src/components/order/document-status'

const BuyerOrderDocumentUpload = ({
  order,
  goNext,
  setShowOrderStatusModal,
}: {
  order: ISupplierOrder
  goNext: () => void
  setShowOrderStatusModal: Dispatch<SetStateAction<boolean>>
}) => {
  return (
    <>
      <div className="flex max-h-[calc(90vh-538px)] flex-col overflow-y-scroll px-[30px]">
        <OrderDocumentStatus order={order} />
      </div>
      <div className=" h-[136px] px-[30px]">
        <hr className=" mt-[10px] h-px border-0 bg-[#E0E0E0]" />
        <OrderTotalPayableAmount order={order} />
        <div className="mt-5 flex flex-row gap-2.5">
          <Button variant="secondary" onClick={() => setShowOrderStatusModal(false)}>
            Cancel
          </Button>
          {/*<Button onClick={() => setShowOrderStatusModal(false)}>Approve</Button>*/}
        </div>
      </div>
    </>
  )
}

export default BuyerOrderDocumentUpload
