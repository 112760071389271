import * as React from 'react'
import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { IAuction } from 'src/_models/auction.model'
import AuctionCard from 'src/components/auction/auction-card'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/autoplay'
import { Autoplay } from 'swiper'
import { useUserStore } from 'src/_store/user.store'
export default function FuelTradingMyWatchlist() {
  const { user } = useUserStore((state) => ({
    user: state.user,
  }))
  const myWatchlist = useQuery({
    queryKey: [`my-watchlist`, 'auctions', user?.fuelTradingStatePreference],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get('/api/wishlist?state=' + user?.fuelTradingStatePreference, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }) as Promise<AxiosResponse<{ auctions: IAuction[]; totalPages: number }>>
    },
    cacheTime: 60,
    enabled: !!user,
  })
  return (
    <section className="w-full">
      <div className="container mx-auto bg-transparent ">
        <div className="mb-[15px] flex w-full justify-between">
          <h2 className="text-lg font-bold text-secoundary 2xl:text-2xl">My Watchlist</h2>
          <a href="/my-watchlist" className="text-sm font-bold text-primary">
            View All
          </a>
        </div>

        <div>
          {myWatchlist.data &&
            (myWatchlist.data.data.auctions.length === 0 ? (
              <div className="cursor-pointer font-montserrat ">
                <div className="-mt-2 flex h-[170px] w-[305px] items-center self-center rounded-3xl border-[5px] border-white bg-[#023047] align-middle 2xl:h-[198px] 2xl:w-[336px]">
                  <div className="flex w-full items-center justify-center p-[20%] text-center align-middle">
                    <p className="pl-[10px] text-lg font-bold text-white 2xl:text-xl">
                      Add auction(s) with &apos;+&apos; to view in watchlist
                    </p>
                  </div>
                </div>
              </div>
            ) : myWatchlist.data.data.auctions.length === 1 ? (
              <div className="flex flex-row gap-5 pt-5">
                {myWatchlist.data.data.auctions.map((auction) => {
                  return <AuctionCard key={`my-watchlist-${auction.id}`} auction={auction} />
                })}
              </div>
            ) : (
              <div className="flex flex-row gap-10">
                <Swiper
                  // modules={[Pagination]}
                  modules={[Autoplay]}
                  loop={myWatchlist.data.data.auctions.length > 4}
                  spaceBetween={20}
                  slidesPerView={4}
                  pagination={{
                    clickable: false,
                  }}
                  grabCursor={true}
                  autoplay={{
                    delay: 2000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  breakpoints={{
                    // when window width is <= 320px
                    320: {
                      slidesPerView: 2,
                      spaceBetween: 5,
                    },
                    // when window width is <= 480px
                    425: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                    // when window width is <= 640px
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                    1440: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                    },
                    1920: {
                      slidesPerView: 5,
                      spaceBetween: 20,
                    },
                  }}
                  className="carousel relative grid gap-10 xl:grid-cols-4 2xl:grid-cols-5"
                >
                  {myWatchlist.data.data.auctions.map((auction) => {
                    return (
                      <SwiperSlide key={`my-watchlist-${auction.id}`} className="relative mt-[1.5rem]">
                        <AuctionCard className="mb-3" auction={auction} />
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}
