import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import FuelTradingTopTicker2 from 'src/components/fuel-trading/top-ticker2'
import FuelTradingTopTickerOrders from 'src/components/fuel-trading/top-ticker-orders'

import FuelTradingBuyerMyAuctions from 'src/pages/buyer/fuel-trading/my-auctions'
import MyWatchlist from 'src/components/fuel-trading/my-watchlist'
import LiveAuctions from 'src/components/fuel-trading/live-auctions'
import StickyRightComponent from 'src/components/common/sticky-right'
import DashboardOrdersInProgress from 'src/pages/fuel-trading/dashboard-orders-in-progress'
import DashboardOrdersCompleted from 'src/pages/fuel-trading/dashboard-orders-completed'
import * as React from 'react'
import StatesFilter from 'src/components/states-filter'
import { GraphFuelTrading } from 'src/pages/fuel-trading/fuel-trading-graphs'

export default function BuyerFuelTradingDashboard() {
  return (
    <>
      {/* <FuelTradingTopTicker2/> */}
      <FuelTradingTopTickerOrders />
      <section className="mb-10 w-full">
        <div className="container mx-auto flex flex-row-reverse">
          <StatesFilter filterType="FUEL_TRADING" className="w-52" />
        </div>
      </section>
      <MyWatchlist />

      {/* Historial fuel start */}
      <section className="mt-10 w-full">
        <div className="container mx-auto">
          <GraphFuelTrading />
        </div>
      </section>
      {/* historial fuel end */}
      <LiveAuctions />
      <FuelTradingBuyerMyAuctions />
      <section className="mb-10 mt-10 w-full">
        <div className="container mx-auto flex gap-8">
          <DashboardOrdersInProgress />
          <DashboardOrdersCompleted />
        </div>
      </section>
      <StickyRightComponent type="FUEL_TRADING" />
    </>
  )
}
