import { Dispatch, SetStateAction } from 'react'
import { Button } from 'src/components/ui/button'
import { DialogClose } from 'src/components/ui/dialog'
import { IOrder } from 'src/_models/order.model'
import OrderDocumentStatus from 'src/components/order/document-status'
import OrderSignatureGuaranteeView from 'src/components/order/signature-guarantee-view'
import TotalPayableAmount from 'src/components/order/total-payable-amount'

const SupplierOrderBuyerPayment = ({
  order,
  goNext,
  setShowOrderStatusModal,
}: {
  order: IOrder
  goNext: () => void
  setShowOrderStatusModal: Dispatch<SetStateAction<boolean>>
}) => {
  return (
    <>
      <div className="flex max-h-[calc(90vh-514px)] flex-col overflow-y-scroll px-[30px] ">
        <OrderDocumentStatus order={order} />
        <OrderSignatureGuaranteeView
          order={order}
          type="BUYER"
          title="Buyer Order Guarantee Signature"
          description="Once the Buyer signs, the order will be considered ready to go."
          orderGuaranteeText={order.buyerOrderGuaranteeText}
          orderGuaranteeMediaKey={order.buyerOrderGuaranteeMedia?.fileKey}
        />
        <OrderSignatureGuaranteeView
          order={order}
          type="SUPPLIER"
          title="Supplier Order Guarantee Signature"
          description="Please sign or upload signature to confirm your purchase. After signing you will not be able to rescind the order."
          orderGuaranteeText={order.supplierOrderGuaranteeText}
          orderGuaranteeMediaKey={order.supplierOrderGuaranteeMedia?.fileKey}
        />
      </div>
      <div className="mt-5 h-[136px] px-[30px]">
        <hr className=" mt-[10px] h-px border-0 bg-[#E0E0E0]" />
        <TotalPayableAmount order={order} />
        <div className="flex gap-5">
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <Button disabled>Guarantee Order</Button>
        </div>
      </div>
    </>
  )
}

export default SupplierOrderBuyerPayment
