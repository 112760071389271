import * as React from 'react'
import CurrencyFormatter from 'src/components/ui/currency-formatter'

const colors = ['#76C2F6', '#EA9184']

const DashboardCardStats = ({
  label,
  stats,
}: {
  label: string
  stats: { label: string; value: string; color: string }[]
}) => {
  if (!stats) return <></>

  const totalSpends = stats.reduce((acc, curr) => acc + parseInt(curr.value), 0)

  const getWidth = (value: number) => {
    if (totalSpends === 0) {
      return 0
    }
    return (value * 100) / totalSpends
  }

  return (
    <div className="shadow-[0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);] flex flex-col rounded-[20px] border-2 border-white bg-[#e9f9ff] p-5 xl:h-[170px] xl:w-[305px] 2xl:h-[210px] 2xl:w-[422px] 2xl:p-[30px]">
      <div className="flex w-full items-center justify-between">
        <div className="w-4/5 text-sm font-bold leading-[17px] text-secoundary 2xl:text-lg 2xl:leading-[19.5px]">
          {label}
        </div>
      </div>
      <div className="my-4 text-xl font-bold leading-[24.38px] 2xl:my-5 2xl:text-2xl 2xl:leading-[29.2px]">
        <CurrencyFormatter currency="USD" cents={true} amount={totalSpends} />
      </div>
      <div className="mb-4 flex w-full gap-[8px] 2xl:gap-4 2xl:mb-5 justify-start">
        {stats.map((stat, index) => (
          <div className="flex gap-[5px]" key={stat.label}>
            <div
              className="h-[16px] w-[16px] rounded-[5px] 2xl:h-[22px] 2xl:w-[22px]"
              style={{ backgroundColor: stat.color }}
            ></div>
            <div className="text-xs font-normal leading-[14px] text-[#000000] 2xl:text-base 2xl:leading-[19.5px]">
              {stat.label}
            </div>
          </div>
        ))}
        {/*<div className="flex gap-[5px]">*/}
        {/*  <div className="h-[16px] w-[16px] rounded-[5px] bg-[#76C2F6] 2xl:h-[22px] 2xl:w-[22px]"></div>*/}
        {/*  <div className="text-xs font-normal leading-[14px] text-[#000000] 2xl:text-base 2xl:leading-[19.5px]">*/}
        {/*    Fuel Trading*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className="flex gap-[5px]">*/}
        {/*  <div className="h-[16px] w-[16px] rounded-[5px] bg-[#EA9184] 2xl:h-[22px] 2xl:w-[22px]"></div>*/}
        {/*  <div className="h-[16px] w-[16px] text-sm font-normal leading-[17px] text-[#000000] 2xl:h-[18px] 2xl:w-[18px] 2xl:text-base 2xl:leading-[19.5px]">*/}
        {/*    REC*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>

      <div className="w-full">
        <div className="flex h-3 overflow-hidden rounded-[5px] bg-[#E0E0E0] text-xs">
          {stats.map((stat, index) => (
            <div
              style={{ backgroundColor: stat.color, width: `${getWidth(parseInt(stat.value))}%` }}
              className="flex flex-col justify-center whitespace-nowrap text-center text-white shadow-none"
              key={stat.label}
            ></div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default DashboardCardStats
