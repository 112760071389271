import { Dispatch, SetStateAction, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { Button } from 'src/components/ui/button'
import { IOrder } from 'src/_models/order.model'
import OrderDocumentStatus from 'src/components/order/document-status'
import TotalPayableAmount from 'src/components/order/total-payable-amount'
import OrderSignatureGuarantee from 'src/components/order/signature-guarantee'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import OrderSignatureGuaranteeView from 'src/components/order/signature-guarantee-view'
import { toast } from 'src/components/ui/use-toast'

const BuyerOrderGuaranteeOrder = ({ goNext, order }: { goNext: () => void; order: IOrder }) => {
  const canvasSignature = useRef<SignatureCanvas>(null)
  const [text, setText] = useState<string>('')
  const [file, setFile] = useState<File>()
  const [uploadMode, setUploadMode] = useState<'CANVAS' | 'INPUT' | 'FILE_UPLOAD' | null>(null)
  const queryClient = useQueryClient()

  const orderSignatureGuaranteeTextMutation = useMutation({
    mutationKey: ['orderSignatureGuaranteeTextMutation'],
    mutationFn: ({ orderId, text }: { orderId: number; text: string }) => {
      const token = localStorage.getItem('token')
      return axios.post(
        `/api/order-signature-guarantee/${orderId}/text`,
        { text },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })

  const orderSignatureGuaranteeFileMutation = useMutation({
    mutationKey: ['orderSignatureGuaranteeTextMutation'],
    mutationFn: ({ orderId, file }: { orderId: number; file: File }) => {
      const token = localStorage.getItem('token')
      const formData = new FormData()
      formData.append('file', file)
      return axios.post(`/api/order-signature-guarantee/${orderId}/file`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const guaranteeOrder = () => {
    if (uploadMode === 'INPUT') {
      if (text.length < 0) {
        toast({
          title: 'Please enter something.',
        })
        return
      }
      orderSignatureGuaranteeTextMutation.mutate(
        { orderId: order.id, text },
        {
          onSuccess: () => {
            queryClient.invalidateQueries()
            toast({
              title: 'Guarantee Done',
            })
          },
          onError: () => {
            toast({
              title: 'Guarantee failed',
            })
          },
        },
      )
      return
    } else if (uploadMode === 'CANVAS') {
      let fileTemp: File
      if (!canvasSignature.current) {
        toast({
          title: 'Unable to render canvas. Please try again.',
        })
        return
      }
      canvasSignature.current.getCanvas().toBlob((blob) => {
        if (!blob) {
          toast({
            title: 'Something went wrong.',
          })
          return
        }
        fileTemp = new File([blob], 'fileName.jpg', { type: 'image/jpeg' })
        orderSignatureGuaranteeFileMutation.mutate(
          { file: fileTemp, orderId: order.id },
          {
            onSuccess: () => {
              queryClient.invalidateQueries()
              toast({
                title: 'Guarantee Done',
              })
            },
            onError: () => {
              toast({
                title: 'Guarantee failed',
              })
            },
          },
        )
      })
    } else if (file) {
      orderSignatureGuaranteeFileMutation.mutate(
        { file, orderId: order.id },
        {
          onSuccess: () => {
            queryClient.invalidateQueries()
            toast({
              title: 'Guarantee Done',
            })
          },
          onError: () => {
            toast({
              title: 'Guarantee failed',
            })
          },
        },
      )
    } else {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Please select an option to sign.',
      })
    }
    // goNext()
  }

  return (
    <>
      <div className="flex max-h-[calc(90vh-538px)] flex-col overflow-y-scroll px-[30px]">
        <OrderDocumentStatus order={order} />
        {order.status === 'BUYER_ORDER_GUARANTEED' ? (
          <OrderSignatureGuarantee
            canvasSignature={canvasSignature}
            text={text}
            setText={setText}
            file={file}
            setFile={setFile}
            order={order}
            uploadMode={uploadMode}
            setUploadMode={setUploadMode}
            type="BUYER"
            title="Buyer Order Guarantee Signature"
            description="Please sign or upload signature to confirm your purchase. After signing you will not be able to rescind the order."
          />
        ) : (
          <OrderSignatureGuaranteeView
            order={order}
            type="BUYER"
            title="Buyer Order Guarantee Signature"
            description="Please sign or upload signature to confirm your purchase. After signing you will not be able to rescind the order."
            orderGuaranteeText={order.buyerOrderGuaranteeText}
            orderGuaranteeMediaKey={order.buyerOrderGuaranteeMedia?.fileKey}
          />
        )}
        <OrderSignatureGuaranteeView
          order={order}
          type="SUPPLIER"
          title="Supplier Order Guarantee Signature"
          description="Once the Supplier signs, the order will be final and must go through."
          orderGuaranteeText={order.supplierOrderGuaranteeText}
          orderGuaranteeMediaKey={order.supplierOrderGuaranteeMedia?.fileKey}
        />
      </div>
      <div className="max-h-40 px-[30px]">
        <hr className=" mt-[10px] h-px border-0 bg-[#E0E0E0]" />
        <TotalPayableAmount order={order} />
        <div className="flex gap-5">
          <Button variant="secondary">Cancel</Button>
          <Button
            disabled={order.status !== 'BUYER_ORDER_GUARANTEED' || orderSignatureGuaranteeTextMutation.isLoading}
            onClick={() => guaranteeOrder()}
          >
            Guarantee Order
          </Button>
        </div>
      </div>
    </>
  )
}

export default BuyerOrderGuaranteeOrder
