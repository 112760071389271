import {useUserStore} from "src/_store/user.store";
import BuyerOrderDialog from "src/components/order/buyer/buyer-order-dialog";
import SupplierOrderDialog from "src/components/order/supplier/supplier-order-dialog";
import {useQuery} from "@tanstack/react-query";
import axios, {AxiosResponse} from "axios";
import {IBuyerOrder, ISupplierOrder} from "src/_models/order.model";
import {Skeleton} from "src/components/ui/skeleton";
import {Dispatch, SetStateAction} from "react";

const OrderDialog = ({ orderId, setShowOrderStatusModal }: { orderId: number, setShowOrderStatusModal: Dispatch<SetStateAction<boolean>> }) => {
  const { user } = useUserStore((state) => ({
    user: state.user,
  }))

  const { data } = useQuery({
    queryKey: ['orders', 'orders-' + orderId],
    cacheTime: 60,
    queryFn: async () => {
      const token = localStorage.getItem('token')
      const response = await axios.get<{ order: IBuyerOrder | ISupplierOrder }>('/api/orders/' + orderId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (user?.userType === 'BUYER') {
        return response as AxiosResponse<{ order: IBuyerOrder }>
      } else {
        return response as AxiosResponse<{ order: ISupplierOrder }>
      }
    },
  })

  if (!data) {
    return <Skeleton className="h-[200px] w-full" />
  }

  if(user?.userType === 'BUYER') {
    return <BuyerOrderDialog order={data.data.order} setShowOrderStatusModal={setShowOrderStatusModal} />
  } else {
    return <SupplierOrderDialog order={data.data.order} setShowOrderStatusModal={setShowOrderStatusModal} />
  }
}

export default OrderDialog;
