import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { redirect, useNavigate, useSearchParams } from 'react-router-dom'
import { z, ZodType } from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { InputPassword } from 'src/components/ui/password-input'
import { Button } from 'src/components/ui/button'
import { useMutation } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { toast } from 'src/components/ui/use-toast'

type IChangePasswordForm = {
  password: string
  confirmPassword: string
}

export default function ChangePassword() {
  const { t } = useTranslation()
  const [queryParameters] = useSearchParams()
  const role = queryParameters.get('role')
  const navigate = useNavigate()

  const signUpSchema: ZodType<IChangePasswordForm> = z
    .object({
      password: z
        .string({ required_error: t('error.passwordRequired') })
        .min(8, { message: t('error.passwordShort') })
        .max(64, { message: t('error.passwordLong') }),
      confirmPassword: z.string({ required_error: t('error.confirmPasswordRequired') }),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: t('error.passwordNotMatch'),
      path: ['confirmPassword'], // path of error
    })

  const { control, handleSubmit } = useForm<IChangePasswordForm>({
    resolver: zodResolver(signUpSchema), // Apply the zodResolver
  })

  const signUpMutation = useMutation({
    mutationFn: (data: IChangePasswordForm): Promise<{ success: boolean; token: string }> => {
      const role = queryParameters.get('role') === 'SUPPLIER' ? 'SUPPLIER' : 'BUYER'
      return axios.post('/api/auth/signUp', { ...data, userType: role })
    },
    // onSuccess: () => {
    //   // Invalidate and refetch
    //   queryClient.invalidateQueries({ queryKey: ['todos'] })
    // },
  })

  const signUp = (data: IChangePasswordForm) => {
    signUpMutation.mutate(data, {
      onSuccess: (resp: any) => {
        navigate(`/auth/verification?role=${role}`)
      },
      onError: (err) => {
        const error = err as AxiosError<{ message: string }>
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: t(error.response?.data?.message || 'error.somethingWrong'),
        })
      },
    })
  }

  return (
    <>
      <h1 className="pb-10 text-2xl font-bold text-txtblack">{t('')}</h1>

      <form onSubmit={handleSubmit(signUp)} noValidate={true}>
        <div className="pb-5">
          <div className="flex items-center justify-between">
            <label htmlFor="password" className="block text-sm font-bold leading-6">
              {t('signUp.newPassword')}
            </label>
          </div>
          <div className="relative mt-2">
            <Controller
              control={control}
              name="password"
              render={({ field, fieldState }) => {
                return (
                  <div className="mt-2">
                    <InputPassword id="password" error={fieldState.error} {...field} />
                  </div>
                )
              }}
            />
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between">
            <label htmlFor="confirmPassword" className="block text-sm font-bold leading-6">
              {t('signUp.confirmPassword')}
            </label>
          </div>
          <div className="relative mt-2">
            <Controller
              control={control}
              name="confirmPassword"
              render={({ field, fieldState }) => {
                return (
                  <div className="mt-2">
                    <InputPassword id="password" error={fieldState.error} {...field} />
                  </div>
                )
              }}
            />
          </div>
        </div>

        <div className="flex gap-4 pt-10">
          <Button expand="full" disabled={signUpMutation.isLoading} type="button">
            {t('cancel')}
          </Button>

          <Button type="submit" disabled={signUpMutation.isLoading} expand="full">
            {t('signUp.verify')}
          </Button>
        </div>
      </form>
    </>
  )
}
