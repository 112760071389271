import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ITransaction } from 'src/_models/order.model'
import { useUserStore } from 'src/_store/user.store'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import TransactionHistoryTable from 'src/pages/fuel-trading/transaction-history/transaction-history-table'
import { useSearchParams } from 'react-router-dom'

const FuelTradingTransactionHistoryPage = () => {
  const [transactions, setTransactions] = useState<ITransaction[]>([])
  const [searchParams, _] = useSearchParams()

  const params = searchParams.toString()

  const transactionHistoryQuery = useQuery({
    queryKey: ['transactions', params],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get<{ transactions: ITransaction[] }>(`/api/orders/transaction-history?${params}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  useEffect(() => {
    if (transactionHistoryQuery.data) {
      setTransactions(transactionHistoryQuery.data.data.transactions)
    }
  }, [transactionHistoryQuery.data])

  return (
    <>
      <section className="w-full">
        {/*
        Filters:
          Fuel Colour
          Fuel Type
          Fuel Purity
          Location: Country State. If only 1 country then don't show the country dropdown
          Qty ( min and max )
          Price range ( min and max )
          Days left
        */}
        <TransactionHistoryTable heading="Transaction History" transactions={transactions} />
      </section>
    </>
  )
}

export default FuelTradingTransactionHistoryPage
