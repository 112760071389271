import { create } from 'zustand'
import { IUser } from 'src/_models/user.model'

type IUserStore = {
  user: IUser | null
  setUser: (user: IUser | null) => void
}

export const useUserStore = create<IUserStore>((set) => ({
  user: null,
  setUser: (user) => set((state) => ({ ...state, user })),
}))
