import React from 'react'
import { useNavigate } from 'react-router-dom'
import { cn } from 'src/lib/utils'

interface CardProps {
  number: number
  text: string
  url: string
  isDotVisible: boolean
}

const StatsCard: React.FC<CardProps> = ({ number, text, url, isDotVisible }) => {
  const navigate = useNavigate()
  return (
    <div
      onClick={() => {
        navigate(url)
      }}
      className={cn(
        'relative mb-5 h-[140px] w-[208px]  rounded-[31px] border-[3.1px] border-white bg-[#ceedfd] px-10 py-[30px] 2xl:h-[159px] 2xl:w-[295px]',
        url && 'cursor-pointer',
      )}
    >
      {isDotVisible && (
        <div className="absolute right-[31px] top-[32px] h-[10.85px] w-[10.85px] rounded-full bg-[#FF3333] shadow-[0px_0px_13.95px_0px_#FF3333]"></div>
      )}
      <div className="mb-2.5 text-center font-montserrat font-bold leading-[38px] text-secoundary xl:text-3xl 2xl:text-[40px] 2xl:leading-[48.76px]">
        {number}
      </div>
      <div className="text-center font-montserrat font-semibold text-secoundary xl:text-sm 2xl:text-base">{text}</div>
    </div>
  )
}

export default StatsCard
