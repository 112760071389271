import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'

export default function ModalDocument({
  onCancel,
  onFileSelection,
}: {
  onCancel: () => void
  onFileSelection: (file: File) => void
}) {
  const { t } = useTranslation()
  const onDrop = useCallback((acceptedFiles: File[]) => {
    onFileSelection(acceptedFiles[0])
    // Do something with the files
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    noKeyboard: true,
    accept: {
      'image/jpeg': ['.jpeg', '.png'],
      'image/png': ['.jpg', '.jpeg'],
      'application/pdf': ['.pdf'],
    },
    multiple: false,
    onDrop,
  })
  // const files = acceptedFiles.map((file: File) => <li key={file.path}>{file.path}</li>)

  return (
    <>
      <div
        data-te-modal-init
        className="fixed left-0 top-0 z-[1055] h-full  w-full overflow-y-auto overflow-x-hidden bg-black/50 outline-none backdrop-blur-md"
        id="exampleModalCenter"
        aria-labelledby="exampleModalCenterTitle"
        aria-modal="true"
        role="dialog"
        // onClick={onCancel}
      >
        <div
          data-te-modal-dialog-ref
          className="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center  transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px]"
        >
          <div className="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-gray2 bg-clip-padding text-current text-grayLight text-white shadow-lg outline-none dark:bg-neutral-600">
            <div className="flex flex-shrink-0 items-center justify-between rounded-t-md p-4 ">
              <button
                type="button"
                className="-top-4.5 absolute -right-[4.5rem] box-content rounded-[8px] border-none bg-white px-4 py-2 text-xs font-medium capitalize text-gray hover:no-underline focus:opacity-100 focus:shadow-none focus:outline-none"
                data-te-modal-dismiss
                aria-label="Close"
                onClick={onCancel}
              >
                close
              </button>
            </div>
            <div className="relative p-4">
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />

                <div className="flex w-full items-center justify-center">
                  <label
                    htmlFor="dropzone-file"
                    className="border-gray-300 bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg"
                  >
                    <div className="flex flex-col items-center justify-center pb-6 pt-5">
                      <div className="mb-4 flex items-center justify-center gap-4 text-gray">
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="61" height="60" viewBox="0 0 61 60" fill="none">
                          <path
                            d="M32.75 23.75H45.125L32.75 11.375V23.75ZM17 8H35L48.5 21.5V48.5C48.5 49.6935 48.0259 50.8381 47.182 51.682C46.3381 52.5259 45.1935 53 44 53H17C14.5025 53 12.5 50.975 12.5 48.5V12.5C12.5 10.0025 14.5025 8 17 8ZM37.25 44V39.5H17V44H37.25ZM44 35V30.5H17V35H44Z"
                            fill="#BDBDBD"
                          />
                        </svg>
                        <span>OR</span> */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="61" height="60" viewBox="0 0 61 60" fill="none">
                          <path
                            d="M30.5 26.2501H33V33.7501H30.5V26.2501ZM18 28.7501H20.5V26.2501H18V28.7501ZM50.5 15.0001V45C50.5 46.3261 49.9732 47.5979 49.0355 48.5356C48.0978 49.4733 46.8261 50 45.5 50H15.5C14.1739 50 12.9021 49.4733 11.9645 48.5356C11.0268 47.5979 10.5 46.3261 10.5 45V15.0001C10.5 13.674 11.0268 12.4022 11.9645 11.4645C12.9021 10.5268 14.1739 10.0001 15.5 10.0001H45.5C46.8261 10.0001 48.0978 10.5268 49.0355 11.4645C49.9732 12.4022 50.5 13.674 50.5 15.0001ZM24.25 26.2501C24.25 25.2555 23.8549 24.3017 23.1516 23.5984C22.4484 22.8951 21.4946 22.5001 20.5 22.5001H14.25V37.5H18V32.5001H20.5C21.4946 32.5001 22.4484 32.105 23.1516 31.4017C23.8549 30.6984 24.25 29.7446 24.25 28.7501V26.2501ZM36.75 26.2501C36.75 25.2555 36.3549 24.3017 35.6516 23.5984C34.9484 22.8951 33.9946 22.5001 33 22.5001H26.75V37.5H33C33.9946 37.5 34.9484 37.105 35.6516 36.4017C36.3549 35.6984 36.75 34.7446 36.75 33.7501V26.2501ZM46.75 22.5001H39.25V37.5H43V32.5001H46.75V28.7501H43V26.2501H46.75V22.5001Z"
                            fill="#BDBDBD"
                          />
                        </svg>
                      </div>
                      <div className="mb-4 flex items-center justify-center gap-4 text-gray">
                        <svg
                          className="h-8 w-8 text-gray"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 16"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                          />
                        </svg>
                        Upload .pdf files
                      </div>
                      <p className="text-gray-500 mb-2 text-base text-gray">
                        <span>(Max File Size is 100 MB)</span>
                      </p>
                      <p className="text-gray-500 mb-2 text-base text-gray">
                        <span>Drag and Drop your</span>
                      </p>
                      <p className="text-gray-500 text-base text-gray">
                        Document here or <span className="underline">Browse File</span>
                      </p>
                    </div>
                    {/*<input id="dropzone-file" type="file" className="hidden" />*/}
                  </label>
                </div>
              </div>

              {/*<div className="text-gray-500 relative mb-2 p-4 text-base text-gray">*/}
              {/*  <ul>{files}</ul>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
