import { Fragment } from 'react'
import { getHData, IFuelGraphData } from './dummy-hydrogen-data'
import ReactApexChart, { Props } from 'react-apexcharts'

const chartData = getHData()

export const GraphFuelTrading = () => {
  // ApexChart Component
  const ApexChart = ({ data }: { data: IFuelGraphData[] }) => {
    const seriesDataGreen = data.map((item) => ({
      x: new Date(item.date).getTime(),
      y: item.green_hydrogen,
    }))

    const seriesDataBlue = data.map((item) => ({
      x: new Date(item.date).getTime(),
      y: item.blue_hydrogen,
    }))

    const seriesDataGrey = data.map((item) => ({
      x: new Date(item.date).getTime(),
      y: item.grey_hydrogen,
    }))

    const options: Props = {
      chart: {
        type: 'line',
        stacked: false,
        height: 250,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          autoSelected: 'zoom',
          download: false,
        },
      },
      dataLabels: {
        enabled: false,
        style: {
          fontFamily: 'Montserrat, sans-serif', // Set Montserrat for data labels
        },
      },
      markers: {
        size: 0,
      },
      title: {
        text: 'Hydrogen Price Movement',
        align: 'left',
        style: {
          fontFamily: 'Montserrat, sans-serif', // Set Montserrat for data labels
          fontSize: 20,
          fontWeight: 700,
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.0,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
      colors: ['#008000', '#0000FF', '#808080'],
      stroke: {
        curve: 'smooth', // You can choose 'smooth' or 'straight'
        width: 2, // Adjust this value to reduce line thickness (default is 3)
      },
      yaxis: {
        labels: {
          formatter: (val: string) => `$${val}`, // Format y-axis labels
          style: {
            fontFamily: 'Montserrat, sans-serif', // Set Montserrat for data labels
          },
        },
        title: {
          text: 'Price (USD/kg)',
          style: {
            fontFamily: 'Montserrat, sans-serif', // Set Montserrat for data labels
          },
        },
        min: 0,
        max: 40,
        tickAmount: 4,
      },
      xaxis: {
        type: 'datetime',
        labels: {
          formatter: function (val: string) {
            const date = new Date(val)
            return date.toLocaleDateString('en-US', {
              month: 'short', // Format as "MMM"
              year: 'numeric', // Format as "YYYY"
            })
          },
          style: {
            fontFamily: 'Montserrat, sans-serif', // Set Montserrat for data labels
          },
        },
      },
      tooltip: {
        style: {
          fontFamily: 'Montserrat, sans-serif', // Set Montserrat for data labels
        },
        shared: false,
        y: {
          formatter: (val: string) => `$${val}`, // Format tooltip values
        },
        x: {
          formatter: function (val: string) {
            const date = new Date(val)
            return date.toLocaleDateString('en-US', {
              day: 'numeric', // Format as "DD"
              month: 'short', // Format as "MMM"
              year: 'numeric', // Format as "YYYY"
            })
          },
        },
      },
      legend: {
        fontFamily: 'Montserrat, sans-serif',
      },
    }

    return (
      <ReactApexChart
        options={options}
        series={[
          { name: 'Green Hydrogen', data: seriesDataGreen },
          { name: 'Blue Hydrogen', data: seriesDataBlue },
          { name: 'Grey Hydrogen', data: seriesDataGrey },
        ]}
        type="area"
        height={250}
      />
    )
  }

  const apexScatterOptions: Props = {
    chart: {
      type: 'scatter',
      height: 250,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: 'zoom',
        download: false,
      },
    },
    series: [
      {
        name: 'Green Hydrogen',
        data: [
          [3, 250],
          [9, 20],
          [2, -350],
        ], // Empty data for now
      },
      {
        name: 'Blue Hydrogen',
        data: [], // Empty data for now
      },
      {
        name: 'Grey Hydrogen',
        data: [], // Empty data for now
      },
    ],
    xaxis: {
      title: {
        text: 'Price per kg',
        style: {
          fontFamily: 'Montserrat, sans-serif',
        },
      },
      min: 0,
      max: 40,
      tickAmount: 8,
      labels: {
        formatter: function (val: string) {
          return `$${val}`
        },
        style: {
          fontFamily: 'Montserrat, sans-serif',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Carbon Intensity %',
        style: {
          fontFamily: 'Montserrat, sans-serif',
        },
      },
      min: -500,
      max: 500,
      tickAmount: 4,
      labels: {
        formatter: function (val: number) {
          return `${val.toFixed(2)}%` // Add percentage symbol
        },
        style: {
          fontFamily: 'Montserrat, sans-serif',
        },
      },
    },
    legend: {
      fontFamily: 'Montserrat, sans-serif',
    },
    colors: ['#008000', '#0000FF', '#808080'],
    markers: {
      size: 5,
    },
    title: {
      text: 'Carbon Intensity vs Price/kg',
      align: 'left',
      style: {
        fontFamily: 'Montserrat, sans-serif', // Set Montserrat for data labels
        fontSize: 20,
        fontWeight: 700,
      },
    },
    tooltip: {
      style: {
        fontFamily: 'Montserrat, sans-serif', // Set Montserrat for tooltip text
      },
    },
    dataLabels: {
      style: {
        fontFamily: 'Montserrat, sans-serif', // Set Montserrat for data labels
      },
    },
  }

  return (
    <Fragment>
      <div className="">
        <div className="mt-10 bg-white rounded-2xl relative p-4 w-full">
          <div style={{ width: '100%', height: '250px' }}>
            <ApexChart data={getHData()} />
          </div>
        </div>
        <div className="mt-10 bg-white rounded-2xl relative p-4  w-full">
          <div style={{ width: '100%', height: '250px' }}>
            <ReactApexChart
              options={apexScatterOptions}
              series={apexScatterOptions.series}
              type="scatter"
              height={250}
            />
          </div>
        </div>
      </div>
    </Fragment>
  )
}
