import React from 'react'
import Marquee from 'react-fast-marquee'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { IOrder } from 'src/_models/order.model'
import { useEffect, useState } from 'react'
import CurrencyFormatter from 'src/components/ui/currency-formatter'
import { formatRecentUpdateDate } from 'src/lib/format-auction'
import { useTranslation } from 'react-i18next'

const FuelTradingTopTickerOrders = () => {
  const { t } = useTranslation('translation')
  const [tickerOrders, setOrders] = useState<IOrder[]>()

  const orderCompletedQuery = useQuery({
    queryKey: ['orders', 'orders/ticker-orders'],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get<{ orders: IOrder[] }>('/api/orders/ticker-orders', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  useEffect(() => {
    if (orderCompletedQuery.data) {
      setOrders(orderCompletedQuery.data.data.orders)
    }
  }, [orderCompletedQuery.data])

  const tickerFuelWeightUnit = (order: IOrder) => {
    const fuelWeightUnit = `${order.auction.fuelWeightUnit}`
    if (order.auction.fuelWeight > 1 && fuelWeightUnit === 'TONNE') {
      return 'TONNES'
    }

    return fuelWeightUnit
  }

  return (
    <div className="w-full">
      <div className="border-1 container mx-auto mb-5 flex pt-[19px] align-middle">
        <div className="shadow-[0px 4px 8px 0px rgba(2, 48, 71, 0.08), 0px 0px 4px 0px rgba(2, 48, 71, 0.06)] w-full items-center overflow-hidden overflow-x-hidden rounded-lg bg-white p-5 align-middle">
          <div className="overflow-x-hidden">
            <Marquee>
              <div className="flex whitespace-nowrap">
                {tickerOrders?.map((order) => (
                  <React.Fragment key={order.id}>
                    <div className="flex items-center align-middle">
                      <div className="mr-[5px] w-fit rounded-sm bg-gray2">
                        <p className="px-2 py-1 text-center text-xs font-bold 2xl:text-sm text-primary">
                          {formatRecentUpdateDate(order.createdAt)}
                        </p>
                      </div>

                      <p className="mr-[5px] text-xs font-bold 2xl:text-sm">{`${order.auction.fuelColor.name} ${order.auction.fuelType.name}`}</p>

                      <p className="mr-[5px] text-center text-xs font-bold 2xl:text-sm">
                        {order.auction.fuelPurityLevel.purity}
                      </p>

                      <p className={`text-xs font-bold 2xl:text-sm mr-[5px]`}>
                        <CurrencyFormatter
                          currency={order.auction.sellingCurrency}
                          amount={order.bidAmount}
                          cents={true}
                        />
                      </p>

                      <p className={`text-xs font-bold 2xl:text-sm mr-[5px]`}>
                        {`${order.auction.fuelWeight} ${tickerFuelWeightUnit(order)}`
                          .toLowerCase()
                          .replace(/^\w/, (c) => c.toUpperCase())}
                      </p>

                      <p className="text-xs font-bold text-primary 2xl:text-sm">
                        <p className="text-xs font-bold text-primary 2xl:text-sm">
                          {order.auction.shippingDetail?.userAddress?.state?.toUpperCase()}
                        </p>
                      </p>
                    </div>
                    <div className='before:ml-5 before:mr-5 before:content-["|"]'></div>
                  </React.Fragment>
                ))}
              </div>
            </Marquee>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FuelTradingTopTickerOrders
