import { Skeleton } from 'src/components/ui/skeleton'

export default function BuyerShippingFunded() {
  return (
    <div>
      <Skeleton className="w-full mb-2" />
      <Skeleton className="w-full mb-2" />
      <Skeleton className="w-full mb-2" />
    </div>
  )
}
