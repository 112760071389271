import * as React from 'react'
import DashboardOrdersTable from 'src/pages/fuel-trading/dashboard-orders-table'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { IOrder } from 'src/_models/order.model'
import { useEffect, useState } from 'react'

const DashboardOrdersCompleted = () => {
  const [orders, setOrders] = useState<IOrder[]>()
  const orderCompletedQuery = useQuery({
    queryKey: ['orders', 'orders/completed'],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get<{ orders: IOrder[] }>('/api/orders/completed', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  useEffect(() => {
    if (orderCompletedQuery.data) {
      setOrders(orderCompletedQuery.data.data.orders)
    }
  }, [orderCompletedQuery.data])

  return (
    <div className="w-1/2">
      {orders && (
        <DashboardOrdersTable
          heading="Completed Orders"
          url="/fuel-trading/order-completed"
          orders={orders as IOrder[]}
        />
      )}
    </div>
  )
}

export default DashboardOrdersCompleted
