import React, { useState } from 'react'
import SendResetPasswordVerification from 'src/pages/auth/forget-password/send-reset-password-verification'
import VerificationCode from 'src/pages/auth/forget-password/verification-code'
import ChangePassword from 'src/pages/auth/forget-password/change-password'
import useLocalStorageState from 'src/hooks/use-localstorage-state'

type ILoginForm = {
  email: string
  password: string
}

export default function ForgetPasswordPage() {
  const [email, setEmail] = useLocalStorageState('email', '')
  const [step, setStep] = useState<1 | 2 | 3>(1)

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center pl-[62px] pr-[83px]">
      <div className="w-full rounded-[20px] bg-white px-10 py-[60px]">
        {step === 1 && <SendResetPasswordVerification email={email} setEmail={setEmail} goForward={() => setStep(2)} />}
        {step === 2 && <VerificationCode email={email} goForward={() => setStep(3)} goBack={() => setStep(1)} />}
        {step === 3 && <ChangePassword />}
      </div>
    </div>
  )
}
