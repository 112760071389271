import { IAuction } from 'src/_models/auction.model'
import { useUserStore } from 'src/_store/user.store'
import * as React from 'react'
import AuctionCardBuyer from 'src/components/auction/auction-card-buyer'
import AuctionCardSupplier from 'src/components/auction/auction-card-supplier'

type IAuctionCardProps = {
  auction: IAuction
  className?: string
}

const AuctionCard = ({ auction, className }: IAuctionCardProps) => {
  const { user } = useUserStore((state) => {
    return {
      user: state.user,
    }
  })
  return user?.userType === 'BUYER' ? (
    <AuctionCardBuyer auction={auction} className={className} />
  ) : (
    <AuctionCardSupplier auction={auction} className={className} />
  )
}

export default AuctionCard
