import { Input } from 'src/components/ui/input'
import { useEffect, useState } from 'react'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { FieldError } from 'react-hook-form'
import { googleApiKey } from 'src/config'

interface InputGoogleAutoCompleteProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onLatLngChange: (lat: string, lng: string) => void
  onAddressComponentsChange?: (props: {
    city: string
    state: string
    country: string
    googleAutocompletePlaceId: string
    pincode: string
  }) => void
  error?: FieldError
  getAddressValue: (text: string) => void
}
const InputGoogleAutoComplete = ({
  onLatLngChange,
  onAddressComponentsChange,
  getAddressValue,
  error,
  value,
  onChange,
  ...props
}: InputGoogleAutoCompleteProps) => {
  const [addressComponents, setAddressComponents] = useState<{
    city: string
    state: string
    country: string
    googleAutocompletePlaceId: string
    pincode: string
  }>({
    city: '',
    state: '',
    country: '',
    googleAutocompletePlaceId: '',
    pincode: '',
  })
  const { placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: googleApiKey,
  })
  const [showSuggestion, setShowSuggestion] = useState<boolean>(true)

  const geocode = (place_id: string) => {
    const geocoder = new google.maps.Geocoder()
    geocoder.geocode({ placeId: place_id }, (results) => {
      if (!results) {
        return
      }
      const coresult = results[0].geometry.location
      setAddressComponents((state) => ({
        ...state,
        googleAutocompletePlaceId: place_id,
      }))
      getAddressComponents(results[0])
      console.log('addressComponents', addressComponents)
      onLatLngChange(coresult.lat().toString(), coresult.lng().toString())
    })
  }

  const handleSuggestionClick = (item: google.maps.places.AutocompletePrediction) => {
    // setValue(item)
    setShowSuggestion(false)
    // getPlacePredictions({ input: '' })
    // getPredictions(item)
    // getPredictions(placePredictions)
  }
  const hideSuggestion = () => {
    setTimeout(() => setShowSuggestion(false), 500)
  }

  const getAddressComponents = (placeResult: google.maps.GeocoderResult) => {
    console.log(' placeResult.address_components', placeResult.address_components)
    for (let i = 0; i < placeResult.address_components.length; i++) {
      const addressType = placeResult.address_components[i].types[0]
      if (addressType === 'locality' || addressType === 'political') {
        setAddressComponents((state) => ({
          ...state,
          city: placeResult.address_components[i].short_name,
        }))
      }
      if (addressType === 'administrative_area_level_1') {
        setAddressComponents((state) => ({
          ...state,
          state: placeResult.address_components[i].long_name,
        }))
      }
      if (addressType === 'country') {
        setAddressComponents((state) => ({
          ...state,
          country: placeResult.address_components[i].long_name,
        }))
      }
      if (addressType === 'postal_code') {
        setAddressComponents((state) => ({
          ...state,
          pincode: placeResult.address_components[i].long_name,
        }))
      }
    }
  }

  useEffect(() => {
    onAddressComponentsChange &&
      onAddressComponentsChange({
        city: addressComponents.city,
        state: addressComponents.state,
        country: addressComponents.country,
        googleAutocompletePlaceId: addressComponents.googleAutocompletePlaceId,
        pincode: addressComponents.pincode,
      })
  }, [addressComponents])

  return (
    <div className="relative w-full">
      <Input
        placeholder="Address"
        error={error}
        {...props}
        value={value}
        onChange={(evt) => {
          const value = evt.target.value
          onChange && onChange(evt)
          setShowSuggestion(true)
          getPlacePredictions({ input: value })

          //  checkValue(evt.target.value)
        }}
        onBlur={hideSuggestion}
      />
      {showSuggestion && (
        <div className="absolute z-10  w-full bg-white shadow-[0_10px_25px_-5px_rgba(0,0,0,0.3)] top-[full]">
          {placePredictions.map((item: google.maps.places.AutocompletePrediction) => {
            return (
              <div
                key={item.place_id}
                onClick={() => {
                  // @ts-ignore
                  onChange && onChange(item.description)
                  getAddressValue(item.description)
                  handleSuggestionClick(item)
                  // getPredictions(item)
                  geocode(item.place_id)
                }}
                className="w-full cursor-pointer rounded-md border-b-2 p-3 hover:bg-slate-100 "
              >
                {item.description}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
export default InputGoogleAutoComplete
