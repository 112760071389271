import { Input } from 'src/components/ui/input'
import { Button } from 'src/components/ui/button'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { z, ZodType } from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { InputPassword } from 'src/components/ui/password-input'
import { zodResolver } from '@hookform/resolvers/zod'
import axios, { AxiosError } from 'axios'
import { toast } from 'src/components/ui/use-toast'

type IProfilePasswordManagementForm = {
  currentPassword: string
  password: string
  confirmPassword: string
}

const ProfilePasswordManagement = () => {
  const { t } = useTranslation('translation')
  const queryClient = useQueryClient()

  const profilePasswordManagementFormZodType: ZodType<IProfilePasswordManagementForm> = z
    .object({
      currentPassword: z
        .string({ required_error: t('error.passwordRequired') })
        .min(8, { message: t('error.passwordShort') })
        .max(64, { message: t('error.passwordLong') }),
      password: z
        .string({ required_error: t('error.passwordRequired') })
        .min(8, { message: t('error.passwordShort') })
        .max(64, { message: t('error.passwordLong') }),
      confirmPassword: z.string({ required_error: t('error.confirmPasswordRequired') }),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: t('error.passwordNotMatch'),
      path: ['confirmPassword'], // path of error
    })

  const { control, handleSubmit, reset } = useForm<IProfilePasswordManagementForm>({
    defaultValues: {
      currentPassword: '',
      password: '',
      confirmPassword: '',
    },
    resolver: zodResolver(profilePasswordManagementFormZodType),
  })

  const updateProfilePasswordMutation = useMutation({
    mutationKey: ['updateProfilePasswordMutation'],
    mutationFn: (data: IProfilePasswordManagementForm) => {
      const token = localStorage.getItem('token')
      return axios.put(
        '/api/profile/change-password',
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })

  const updatePassword = (data: IProfilePasswordManagementForm) => {
    updateProfilePasswordMutation.mutate(data, {
      onSuccess: () => {
        toast({
          title: 'Updated password.',
        })
        reset()
        queryClient.invalidateQueries()
      },
      onError: (error) => {
        const err = error as AxiosError<{ message: string }>

        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: err.response ? t(err.response.data.message) : 'Failed to update password.',
        })
      },
    })
  }

  return (
    <div className="flex w-full">
      <div className="mx-10 w-full max-w-[500px] 2xl:mx-20 2xl:max-w-[600px]">
        <div className="flex flex-col gap-[30px]">
          <div className="flex w-full flex-col gap-[10px]">
            <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">Current Password</label>
            <div className="relative flex w-full items-center align-middle">
              <Controller
                control={control}
                name="currentPassword"
                render={({ field, fieldState }) => (
                  <InputPassword
                    // className="text-sm[appearance:textfield] border-1 relative flex w-full items-center border border-primary bg-white  py-[15px]  pr-20 text-left align-middle focus:outline-0 focus:ring-0"
                    {...field}
                    error={fieldState.error}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex w-full flex-col gap-[10px]">
            <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">New Password</label>
            <div className="relative flex w-full items-center align-middle">
              <Controller
                control={control}
                name="password"
                render={({ field, fieldState }) => (
                  <InputPassword
                    // className="text-sm[appearance:textfield] border-1 relative flex w-full items-center border border-primary bg-white  py-[15px]  pr-20 text-left align-middle focus:outline-0 focus:ring-0"
                    {...field}
                    error={fieldState.error}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex w-full flex-col gap-[10px]">
            <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">Confirm Password</label>
            <div className="relative flex w-full items-center align-middle">
              <Controller
                control={control}
                name="confirmPassword"
                render={({ field, fieldState }) => (
                  <InputPassword
                    // className="text-sm[appearance:textfield] border-1 relative flex w-full items-center border border-primary bg-white  py-[15px]  pr-20 text-left align-middle focus:outline-0 focus:ring-0"
                    {...field}
                    error={fieldState.error}
                  />
                )}
              />
            </div>
          </div>

          <Button className="inline-block px-5 py-[16px]" onClick={handleSubmit(updatePassword)}>
            Update Details
          </Button>
        </div>
      </div>

      <div className="w-full max-w-[417px] 2xl:max-w-[417px]"></div>
    </div>
  )
}

export default ProfilePasswordManagement
