import * as React from 'react'
import FuelTradingTopTicker2 from 'src/components/fuel-trading/top-ticker2'
import FuelTradingTopTickerOrders from 'src/components/fuel-trading/top-ticker-orders'

import FuelTradingMyWatchlist from 'src/components/fuel-trading/my-watchlist'
import FuelTradingLiveAuctions from 'src/components/fuel-trading/live-auctions'
import FuelTradingMyAuctions from 'src/pages/supplier/fuel-trading/my-auctions'
import StickyRightComponent from 'src/components/common/sticky-right'
import DashboardOrdersCompleted from 'src/pages/fuel-trading/dashboard-orders-completed'
import DashboardOrdersInProgress from 'src/pages/fuel-trading/dashboard-orders-in-progress'
import SupplierFuelTradingPastAuction from 'src/pages/supplier/fuel-trading/past-auctions-supplier'
import StatesFilter from 'src/components/states-filter'
import { GraphFuelTrading } from 'src/pages/fuel-trading/fuel-trading-graphs'

export default function SupplierFuelTradingDashboard() {
  return (
    <>
      {/* <FuelTradingTopTicker2/> */}
      <FuelTradingTopTickerOrders />

      <section className="mb-5 w-full">
        <div className="container mx-auto flex flex-row-reverse">
          <StatesFilter filterType="FUEL_TRADING" className="w-52" />
        </div>
      </section>
      <FuelTradingMyWatchlist />
      <FuelTradingMyAuctions />

      {/* Historial fuel start */}
      <section className="mt-10 w-full">
        <div className="container mx-auto">
          <GraphFuelTrading />
        </div>
      </section>
      {/* historial fuel end */}

      <FuelTradingLiveAuctions />
      <section className="mb-10 mt-10 w-full">
        <div className="container mx-auto flex gap-8">
          <DashboardOrdersInProgress />
          <DashboardOrdersCompleted />
        </div>
      </section>
      <SupplierFuelTradingPastAuction />
      <StickyRightComponent type="FUEL_TRADING" />
    </>
  )
}
