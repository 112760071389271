import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
import { toast } from 'src/components/ui/use-toast'

// todo: the toast message is behind the blur so cannot see it for error or success
export default function ModalProfilePicture({
  onCancel,
  onFileSelection,
}: {
  onCancel: () => void
  onFileSelection: (file: File) => void
}) {
  const { t } = useTranslation()
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles[0].size > 3145728) {
      toast({
        title: t('File size should be less than 3 MB'),
        variant: 'destructive',
      })
      return
    }
    onFileSelection(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    noKeyboard: true,
    accept: { 'image/jpeg': ['.jpeg', '.png'] },
    multiple: false,
    onDrop,
  })

  return (
    <>
      <div
        data-te-modal-init
        className="fixed left-0 top-0 z-[1055] h-full  w-full overflow-y-auto overflow-x-hidden bg-black/50 outline-none backdrop-blur-md"
        id="profilePictureModal"
        aria-labelledby="profilePictureModalTitle"
        aria-modal="true"
        role="dialog"
      >
        <div
          data-te-modal-dialog-ref
          className="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center  transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px]"
        >
          <div className="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-gray2 bg-clip-padding text-current text-grayLight text-grey shadow-lg outline-none dark:bg-neutral-600">
            <div className="flex flex-shrink-0 items-center justify-between rounded-t-md p-4 ">
              <button
                type="button"
                className="-top-4.5 absolute -right-[4.5rem] box-content rounded-[8px] border-none bg-white px-4 py-2 text-xs font-medium capitalize text-gray hover:no-underline focus:opacity-100 focus:shadow-none focus:outline-none"
                data-te-modal-dismiss
                aria-label="Close"
                onClick={onCancel}
              >
                {t('Close')}
              </button>
            </div>
            <div {...getRootProps()} className="flex flex-col items-center justify-center p-4">
              <input {...getInputProps({ accept: 'image/*' })} />
                <div className="mb-4 flex items-center justify-center gap-4 text-gray border-gray-300 bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 flex  w-full cursor-pointer rounded-lg">
                  Upload .jpeg, .jpg or .png files
                </div>
                <p className="text-gray-500 mb-2 text-base text-gray">
                  <span>(Max File Size is 3 MB)</span>
                </p>
                <p className="text-gray-500 mb-2 text-base text-gray">
                  <span>Drag and Drop your</span>
                </p>
                <p className="text-gray-500 text-base text-gray">
                  Image here or <span className="underline">Browse File</span>
                </p>
              </div>
            </div>
        </div>
      </div>
    </>
  )
}
