import { Controller, useForm } from 'react-hook-form'
import { Input } from 'src/components/ui/input'
import { InputPassword } from 'src/components/ui/password-input'
import { Button } from 'src/components/ui/button'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useNavigation, useSearchParams } from 'react-router-dom'
import { z, ZodType } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { toast } from 'src/components/ui/use-toast'
import { IUser } from 'src/_models/user.model'
import { useUserStore } from 'src/_store/user.store'

type ISignUpForm = {
  email: string
  password: string
  confirmPassword: string
  firstName: string
  lastName: string
}

export default function UserDetails({ goNext }: { goNext: () => void }) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [queryParameters] = useSearchParams()
  let heading = t('buyerDetails.heading')
  if (queryParameters.has('role') && queryParameters.get('role') === 'SUPPLIER') {
    heading = t('supplierDetails.heading')
  }
  const { setUser } = useUserStore((state) => {
    return {
      setUser: state.setUser,
    }
  })

  const signUpSchema: ZodType<ISignUpForm> = z
    .object({
      email: z
        .string({ required_error: t('error.emailRequired') })
        .min(1, t('error.emailRequired'))
        .email(t('error.emailInvalid')),
      password: z
        .string({ required_error: t('error.passwordRequired') })
        .min(8, { message: t('error.passwordShort') })
        .max(64, { message: t('error.passwordLong') }),
      confirmPassword: z.string({ required_error: t('error.confirmPasswordRequired') }),
      firstName: z.string({ required_error: t('error.firstNameRequired') }).min(1, t('error.firstNameRequired')),
      lastName: z.string({ required_error: t('error.lastNameRequired') }).min(1, t('error.lastNameRequired')),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: t('error.passwordNotMatch'),
      path: ['confirmPassword'], // path of error
    })

  const { control, handleSubmit, setValue } = useForm<ISignUpForm>({
    resolver: zodResolver(signUpSchema),
    // Apply the zodResolver
  })

  const signUpMutation = useMutation({
    mutationFn: (data: ISignUpForm): Promise<AxiosResponse<{ token: string; user: IUser }>> => {
      const role = queryParameters.get('role') === 'SUPPLIER' ? 'SUPPLIER' : 'BUYER'
      return axios.post('/api/auth/signUp', { ...data, userType: role })
    },
    // onSuccess: () => {
    //   // Invalidate and refetch
    //   queryClient.invalidateQueries({ queryKey: ['todos'] })
    // },
  })

  const signUp = (data: ISignUpForm) => {
    signUpMutation.mutate(data, {
      onSuccess: (resp) => {
        localStorage.setItem('token', resp.data.token)
        setUser(resp.data.user)
        toast({ title: 'Signup successful' })
        goNext()
        queryParameters.set('step', '1')
      },
      onError: (err) => {
        const error = err as AxiosError<{ message: string }>
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: t(error.response?.data?.message as string),
        })
      },
    })
  }
  return (
    <>
      <form onSubmit={handleSubmit(signUp)} noValidate={true}>
        <div>
          <h1 className="pb-10 text-2xl font-bold text-txtblack">{heading}</h1>
          <div className="pb-5">
            <label htmlFor="firstname" className="block text-sm font-bold leading-6 text-txtblack">
              {t('signUp.firstName')}
            </label>
            <div className="mt-2">
              <Controller
                control={control}
                name="firstName"
                render={({ field, fieldState }) => {
                  return (
                    <Input
                      id="firstName"
                      type="text"
                      placeholder={t('signUp.firstName')}
                      {...field}
                      error={fieldState.error}
                    />
                  )
                }}
              />
            </div>
          </div>

          <div className="pb-5">
            <label htmlFor="lastname" className="block text-sm font-bold leading-6 text-txtblack">
              {t('signUp.lastName')}
            </label>
            <div className="mt-2">
              <Controller
                control={control}
                name="lastName"
                render={({ field, fieldState }) => {
                  return (
                    <Input
                      id="lastname"
                      type="text"
                      placeholder={t('signUp.lastName')}
                      {...field}
                      error={fieldState.error}
                    />
                  )
                }}
              />
            </div>
          </div>
          <div className="pb-5">
            <label htmlFor="email" className="block text-sm font-bold leading-6 text-txtblack">
              {t('login.emailLabel')}
            </label>
            <div className="mt-2">
              <Controller
                control={control}
                name="email"
                render={({ field, fieldState }) => {
                  return (
                    <Input
                      id="email"
                      type="email"
                      autoComplete="email"
                      placeholder="test@regen.io"
                      {...field}
                      error={fieldState.error}
                    />
                  )
                }}
              />
            </div>
          </div>

          <div className="pb-5">
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-bold leading-6">
                {t('signUp.newPassword')}
              </label>
            </div>
            <div className="relative mt-2">
              <Controller
                control={control}
                name="password"
                render={({ field, fieldState }) => {
                  return (
                    <div className="mt-2">
                      <InputPassword id="password" error={fieldState.error} {...field} />
                    </div>
                  )
                }}
              />
            </div>
          </div>

          <div className="pb-5">
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-bold leading-6">
                {t('signUp.confirmPassword')}
              </label>
            </div>
            <div className="relative mt-2">
              <Controller
                control={control}
                name="confirmPassword"
                render={({ field, fieldState }) => {
                  return (
                    <div className="mt-2">
                      <InputPassword id="password" error={fieldState.error} {...field} />
                    </div>
                  )
                }}
              />
            </div>
          </div>
        </div>
      </form>
      <div className="flex gap-4 pb-5 pt-10">
        <Button expand="full" disabled={signUpMutation.isLoading} onClick={() => navigate('/auth/login')} type="button">
          {t('cancel')}
        </Button>

        <Button type="submit" onClick={handleSubmit(signUp)} disabled={signUpMutation.isLoading} expand="full">
          {t('signUp.verify')}
        </Button>
      </div>
    </>
  )
}
