import React from 'react'
import { useTranslation } from 'react-i18next'
import { z, ZodType } from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { Button } from 'src/components/ui/button'
import { toast } from 'src/components/ui/use-toast'
import OtpInput from 'src/components/ui/otp-input'
import { InputPassword } from 'src/components/ui/password-input'
import { useNavigate, useNavigation } from 'react-router-dom'

type IVerificationCodeForm = {
  verificationCode: string
  password: string
  confirmPassword: string
}

type IVerificationCodeProps = {
  email: string
  goBack: () => void
  goForward: () => void
}

export default function VerificationCode({ email, goBack, goForward }: IVerificationCodeProps) {
  const { t } = useTranslation('translation')
  const navigate = useNavigate()

  const verifyTokenSchema: ZodType<IVerificationCodeForm> = z
    .object({
      verificationCode: z.string({ required_error: t('error.emailRequired') }).min(6, t('error.emailInvalid')),
      password: z
        .string({ required_error: t('error.passwordRequired') })
        .min(8, { message: t('error.passwordShort') })
        .max(64, { message: t('error.passwordLong') }),
      confirmPassword: z.string({ required_error: t('error.confirmPasswordRequired') }),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: t('error.passwordNotMatch'),
      path: ['confirmPassword'], // path of error
    })

  const { control, handleSubmit } = useForm<IVerificationCodeForm>({
    resolver: zodResolver(verifyTokenSchema),
  })

  const verifyTokenMutation = useMutation({
    mutationFn: (data: IVerificationCodeForm): Promise<AxiosResponse<{ token: string }>> => {
      return axios.post('/api/auth/verifyForgetPasswordToken', { ...data, email: email })
    },
    // onSuccess: () => {
    //   // Invalidate and refetch
    //   queryClient.invalidateQueries({ queryKey: ['todos'] })
    // },
  })

  const verifyToken = (data: IVerificationCodeForm) => {
    verifyTokenMutation.mutate(data, {
      onSuccess: (resp) => {
        toast({ title: t('forgetPassword.updatedPassword') })
        localStorage.setItem('token', resp.data.token)
        // goForward()
        navigate('/')
      },
      onError: (err) => {
        const error = err as AxiosError<{ message: string }>
        if (error.response?.status === 401) {
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description: t('error.invalidVerificationCode'),
          })
        } else {
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description: t('error.somethingWrong'),
          })
        }
      },
    })
  }

  return (
    <>
      <h1 className="pb-10 text-2xl font-bold text-txtblack">{t('otpVerify.heading')}</h1>
      <p className="pb-4 text-sm font-[500] text-txtblack">
        {t('forgetPassword.verifyOtp')} :: <span>{email}</span>
      </p>
      <form className="" onSubmit={handleSubmit(verifyToken)} noValidate={true}>
        <div className="pb-5">
          {/*<label htmlFor="email" className="block text-sm font-bold leading-6 text-txtblack">*/}
          {/*  {t('otpVerify.emailLabel')}*/}
          {/*</label>*/}

          <Controller
            control={control}
            name="verificationCode"
            render={({ field, fieldState }) => {
              return (
                <div className="mt-2">
                  <OtpInput value={field.value || ''} valueLength={6} onChange={field.onChange} />
                  {/*<Input*/}
                  {/*  id="email"*/}
                  {/*  type="email"*/}
                  {/*  autoComplete="email"*/}
                  {/*  placeholder={t('login.emailPlaceholder')}*/}
                  {/*  error={fieldState.error}*/}
                  {/*  {...field}*/}
                  {/*/>*/}
                </div>
              )
            }}
          />
        </div>
        <div className="pb-5">
          <div className="flex items-center justify-between">
            <label htmlFor="password" className="block text-sm font-bold leading-6">
              {t('signUp.newPassword')}
            </label>
          </div>
          <div className="relative mt-2">
            <Controller
              control={control}
              name="password"
              render={({ field, fieldState }) => {
                return (
                  <div className="mt-2">
                    <InputPassword id="password" error={fieldState.error} {...field} />
                  </div>
                )
              }}
            />
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between">
            <label htmlFor="confirmPassword" className="block text-sm font-bold leading-6">
              {t('signUp.confirmPassword')}
            </label>
          </div>
          <div className="relative mt-2">
            <Controller
              control={control}
              name="confirmPassword"
              render={({ field, fieldState }) => {
                return (
                  <div className="mt-2">
                    <InputPassword id="password" error={fieldState.error} {...field} />
                  </div>
                )
              }}
            />
          </div>
        </div>

        <div className="flex gap-4 pt-10">
          <Button type="button" expand="full" onClick={() => goBack()}>
            {t('otpVerify.goBack')}
          </Button>
          <Button disabled={verifyTokenMutation.isLoading} expand="full" type="submit">
            {t('otpVerify.submitBtn')}
          </Button>
        </div>
      </form>
    </>
  )
}
