import { IAuction } from 'src/_models/auction.model'
import * as React from 'react'
import { Dispatch, SetStateAction } from 'react'
import SupplierAuctionDialogAuction from 'src/components/auction/supplier-auction-dialog-auction'
import SupplierAuctionDialogPurchaseNow from 'src/components/auction/supplier-auction-dialog-purchase-now'

const SupplierAuctionDialog = ({
  auction,
  setShowDialog,
}: {
  auction: IAuction
  setShowDialog: Dispatch<SetStateAction<boolean>>
}) => {
  return auction.auctionType === 'AUCTION' ? (
    <SupplierAuctionDialogAuction auction={auction} setShowDialog={setShowDialog} />
  ) : (
    <SupplierAuctionDialogPurchaseNow auction={auction} setShowDialog={setShowDialog} />
  )
}

export default SupplierAuctionDialog
