import { Select, SelectItem, SelectTrigger, SelectValue } from 'src/components/ui/select'
import { SelectContent } from '@radix-ui/react-select'
import { Input } from 'src/components/ui/input'
import { Button } from 'src/components/ui/button'
import * as React from 'react'
import InputGoogleAutoComplete from 'src/components/ui/input-google-auto-complete'
import { IUser } from 'src/_models/user.model'
import { useEffect } from 'react'
import { ZodType, z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { useQuery, useMutation, QueryClient, useQueryClient } from '@tanstack/react-query'
import { Controller, useForm } from 'react-hook-form'
import { IUserAddress } from 'src/_models/shipping-detail.model'
import { toast } from 'src/components/ui/use-toast'
import { useUserStore } from 'src/_store/user.store'
import { t } from 'i18next'
import { Skeleton } from 'src/components/ui/skeleton'

type IAddressForm = {
  label: string
  streetAddress: string
  googleAutocompleteAddress: string
  googleAutocompletePlaceId: string
  latitude: string
  longitude: string
  city: string
  state: string
  country: string
}

const ProfileSavedAddresses = ({ user }: { user: IUser }) => {
  const [address, setAddress] = React.useState<string>()
  const [showAddAddressForm, setShowAddAddressForm] = React.useState<boolean>(false)
  const queryClient = useQueryClient()

  const { setUser } = useUserStore((state) => {
    return {
      setUser: state.setUser,
    }
  })

  const { isLoading, isError, data } = useQuery({
    queryKey: ['user-addresses'],
    queryFn: (): Promise<AxiosResponse<{ userAddresses: IUserAddress[] }>> => {
      const token = localStorage.getItem('token')
      return axios.get('/api/user-addresses', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const userAddressesSchema: ZodType<IAddressForm> = z.object({
    label: z.string({ required_error: 'required' }).min(1, { message: 'required' }),
    streetAddress: z.string({ required_error: 'required' }),
    googleAutocompleteAddress: z.string({ required_error: 'required' }),
    googleAutocompletePlaceId: z.string({ required_error: 'required' }),
    latitude: z.string({ required_error: 'required' }),
    longitude: z.string({ required_error: 'required' }),
    city: z.string({ required_error: 'required' }),
    state: z.string({ required_error: 'required' }),
    country: z.string({ required_error: 'required' }),
  })

  const { control, handleSubmit, setValue, setError } = useForm<IAddressForm>({
    defaultValues: {
      label: '',
      streetAddress: '',
      googleAutocompleteAddress: '',
      googleAutocompletePlaceId: '',
      latitude: '',
      longitude: '',
      city: '',
      state: '',
      country: '',
    },
    resolver: zodResolver(userAddressesSchema), // Apply the zodResolver
  })

  const userAddressesMutation = useMutation({
    mutationFn: (formData: IAddressForm): Promise<AxiosResponse<{ token: string; user: IUser }>> => {
      const token = localStorage.getItem('token')
      return axios.post('/api/user-addresses', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const resetFormFields = () => {
    setValue('label', '')
    setValue('streetAddress', '')
    setValue('googleAutocompleteAddress', '')
    setValue('googleAutocompletePlaceId', '')
    setValue('latitude', '')
    setValue('longitude', '')
    setValue('city', '')
    setValue('state', '')
    setValue('country', '')
  }

  const saveUserAddress = (data: IAddressForm) => {
    if (
      address !== data.googleAutocompleteAddress &&
      data.googleAutocompleteAddress !== data?.googleAutocompleteAddress
    ) {
      setError('googleAutocompleteAddress', { message: 'Please select from autocomplete.' })
      return
    }
    userAddressesMutation.mutate(data, {
      onSuccess: (resp) => {
        toast({
          title: 'Address saved successfully.',
          description: 'Your address has been saved successfully.',
        })
        resetFormFields()
        setShowAddAddressForm(false)
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['user-addresses'] })
      },
      onError: (err) => {
        const error = err as AxiosError<{ message: string }>
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: t(error.response?.data?.message as string),
        })
      },
    })
  }

  const deleteUserAddressMutation = useMutation({
    mutationFn: (id: number): Promise<AxiosResponse<{ token: string; user: IUser }>> => {
      const token = localStorage.getItem('token')
      return axios.delete(`/api/user-addresses/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const deleteUserAddress = (id: number) => {
    const confirmed = confirm('Are you sure you want to delete ?')
    if (!confirmed) {
      return
    }
    deleteUserAddressMutation.mutate(id, {
      onSuccess: (resp) => {
        toast({
          title: 'Address removed successfully.',
          description: 'Your address has been removed successfully.',
        })
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['user-addresses'] })
      },
      onError: (err) => {
        const error = err as AxiosError<{ message: string }>
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: t(error.response?.data?.message as string),
        })
      },
    })
  }

  const onLatLngChange = (lat: string, lng: string) => {
    setValue('latitude', lat)
    setValue('longitude', lng)
  }

  const onAddressComponentsChange = ({
    city,
    state,
    country,
    googleAutocompletePlaceId,
    pincode,
  }: {
    city: string
    state: string
    country: string
    googleAutocompletePlaceId: string
    pincode: string
  }) => {
    setValue('city', city)
    setValue('state', state)
    setValue('country', country)
    setValue('googleAutocompletePlaceId', googleAutocompletePlaceId)
  }

  if (isLoading) {
    // do skeletons here
    return (
      <div className="mx-10 w-full max-w-[500px] 2xl:mx-20 2xl:max-w-[600px]">
        <div className="flex flex-col gap-[10px]">
          <Skeleton className="h-5 w-1/3" />
          <Skeleton className="h-14" />
          <Skeleton className="h-10" />
        </div>
      </div>
    )
  }

  if (isError) {
    return <div>Error...</div>
  }

  if (data) {
    return (
      <div className="flex w-full">
        <div className="mx-10 w-full max-w-[500px] 2xl:mx-20 2xl:max-w-[600px]">
          <div className="flex w-full flex-col gap-[30px]">
            {data.data.userAddresses.map((address, index) => (
              <div className="flex flex-col gap-[10px]" key={address.id}>
                <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">{address.label}</label>
                <div className="relative flex w-full items-center align-middle">
                  <Input
                    className="text-sm[appearance:textfield] border-1 relative flex w-full items-center border border-primary bg-white  py-[15px]  pr-20 text-left align-middle focus:outline-0 focus:ring-0"
                    value={`${address.streetAddress}${address.streetAddress ? ',' : ''} ${
                      address.googleAutocompleteAddress
                    }`}
                  />
                  <div className="absolute right-3 w-auto px-5 py-[10px]">
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault()
                        deleteUserAddress(address.id)
                      }}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17" fill="none">
                        <path
                          d="M1.83312 15.1111C1.83312 15.6121 2.03212 16.0925 2.38636 16.4468C2.74059 16.801 3.22104 17 3.72201 17H11.2776C11.7785 17 12.259 16.801 12.6132 16.4468C12.9674 16.0925 13.1665 15.6121 13.1665 15.1111V3.77778H1.83312V15.1111ZM3.72201 5.66667H11.2776V15.1111H3.72201V5.66667ZM10.8053 0.944444L9.86089 0H5.13867L4.19423 0.944444H0.888672V2.83333H14.1109V0.944444H10.8053Z"
                          fill="#333333"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            ))}
            <div className="flex justify-center gap-5">
              <Button
                className=""
                onClick={() => {
                  setShowAddAddressForm(true)
                }}
              >
                Add New Address
              </Button>
              {/* <Button className="">Add Address</Button> */}
            </div>
          </div>
        </div>

        {showAddAddressForm && ( // Remove unnecessary parentheses here
          <div className="w-full max-w-[417px] 2xl:max-w-[417px]">
            <form
              onSubmit={handleSubmit(saveUserAddress, (err) => {
                toast({
                  variant: 'destructive',
                  title: 'Please check the form and try again.',
                })
              })}
              noValidate={true}
            >
              <div className="rounded-5 flex flex-col gap-[30px] bg-white p-[30px]">
                <div className="flex flex-col gap-[10px]">
                  <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">Label</label>
                  <div className="relative flex flex-col w-full ">
                    <Controller
                      control={control}
                      name="label"
                      render={({ field, fieldState }) => {
                        return (
                          <>
                            <Input
                              type="text"
                              id="label"
                              placeholder="Home, Office, etc."
                              {...field}
                              error={fieldState.error}
                            />
                          </>
                        )
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-[10px]">
                  <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">Address</label>
                  <div className="relative flex w-full items-center align-middle">
                    <Controller
                      control={control}
                      name="googleAutocompleteAddress"
                      render={({ field, fieldState }) => {
                        return (
                          <>
                            <InputGoogleAutoComplete
                              onLatLngChange={onLatLngChange}
                              onAddressComponentsChange={onAddressComponentsChange}
                              getAddressValue={setAddress}
                              id="shippingAddress"
                              {...field}
                              error={fieldState.error}
                            />
                          </>
                        )
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-[10px]">
                  <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">
                    Unit No. / Office No. / Suite No.
                  </label>
                  <div className="relative flex w-full items-center align-middle">
                    <Controller
                      control={control}
                      name="streetAddress"
                      render={({ field, fieldState }) => {
                        return (
                          <>
                            <Input
                              type="text"
                              id="label"
                              placeholder="XYZ Street, 123, ABC"
                              {...field}
                              error={fieldState.error}
                            />
                          </>
                        )
                      }}
                    />
                  </div>
                </div>

                <div className="flex justify-center gap-1">
                  <Button
                    onClick={() => {
                      setShowAddAddressForm(false)
                      resetFormFields()
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" className="">
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    )
  } else {
    return <Skeleton />
  }
}

export default ProfileSavedAddresses
