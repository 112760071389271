import React, { useEffect, useMemo, useState } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { RouterProvider } from 'react-router-dom'
import { router } from './router'
import axios from 'axios'
import { Toaster } from 'src/components/ui/toaster'
// import { socket } from './web-sockets/socket'
import { useUserStore } from 'src/_store/user.store'
import mixpanel from 'mixpanel-browser'
// import { CometChat } from '@cometchat/chat-sdk-javascript'
import { UIKitSettingsBuilder } from '@cometchat/uikit-shared'
import { CometChatUIKit } from '@cometchat/chat-uikit-react'

mixpanel.init('4d07cdb862030bf38de0f7a1076784d9')

// axios.defaults.baseURL = 'http://localhost:3000'
axios.defaults.baseURL = 'https://api-dev.regenenergy.io'
const COMETCHAT_CONSTANTS = {
  APP_ID: '26657637f479e244', //Replace with your App ID
  REGION: 'us', //Replace with your App Region
}
//create the builder
const UIKitSettings = new UIKitSettingsBuilder()
  .setAppId(COMETCHAT_CONSTANTS.APP_ID)
  .setRegion(COMETCHAT_CONSTANTS.REGION)
  // .setAuthKey(COMETCHAT_CONSTANTS.AUTH_KEY)
  .subscribePresenceForAllUsers()
  .build()

//Initialize CometChat UI Kit
CometChatUIKit.init(UIKitSettings)
  ?.then(() => {
    console.log('Initialization completed successfully')
    // You can now call login function.
  })
  .catch(console.log)

export default function App() {
  const { user } = useUserStore((state) => ({
    user: state.user,
  }))
  const [isConnected, setIsConnected] = useState(false)
  const queryClient = useMemo(() => new QueryClient({}), [])

  // const onConnect = () => {
  //   console.log('connected websocket')
  //   setIsConnected(true)
  // }
  //
  // const onDisconnect = () => {
  //   setIsConnected(false)
  // }
  //
  // const onPlatformUpdates = () => {
  //   // TODO:
  // }
  //
  // const onRecentUpdates = () => {
  //   // TODO:
  // }

  useEffect(() => {
    if (!user || !user.id) return

    // Mixpanel Tracking
    mixpanel.identify(user.id.toString())

    // CometChat Login
    CometChatUIKit.getLoggedinUser().then(async (cometUser) => {
      if (!cometUser) {
        //Login user
        const token = localStorage.getItem('token')
        if (!token) return
        const resp = await axios.post<{ cometChatAuthToken: string }>(
          '/api/comet-chat/getToken',
          { email: user.email },
          { headers: { Authorization: `Bearer ${token}` } },
        )
        console.log('resp', resp)
        CometChatUIKit.loginWithAuthToken(resp.data.cometChatAuthToken)
          .then((user) => {
            console.log('Login Successful:', { user })
            //mount your app
          })
          .catch((error) => {
            console.log('Login failed with error:', error)
          })
      } else {
        console.log('cometUser', cometUser)
        //mount your app
      }
    })

    // function onFooEvent(value) {
    //   setFooEvents(previous => [...previous, value]);
    // }

    // socket.on('connect', onConnect)
    // socket.on('disconnect', onDisconnect)
    // socket.on('platform-updates', onPlatformUpdates)
    // socket.on(`recent-updates:${user.id}`, onRecentUpdates)
    //
    // return () => {
    //   socket.off('connect', onConnect)
    //   socket.off('disconnect', onDisconnect)
    //   // socket.off('foo', onFooEvent);
    // }
  }, [user])

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <Toaster />
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}
