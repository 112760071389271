export type IFuelGraphData = {
  date: Date
  green_hydrogen: number
  blue_hydrogen: number
  grey_hydrogen: number
}

export function getHData() {
  return [
    {
      date: new Date('2023-10-10'),
      green_hydrogen: 6.64,
      blue_hydrogen: 3.14,
      grey_hydrogen: 1.77,
    },
    {
      date: new Date('2023-10-11'),
      green_hydrogen: 6.75,
      blue_hydrogen: 3.13,
      grey_hydrogen: 1.62,
    },
    {
      date: new Date('2023-10-12'),
      green_hydrogen: 6.68,
      blue_hydrogen: 3.27,
      grey_hydrogen: 1.75,
    },
    {
      date: new Date('2023-10-13'),
      green_hydrogen: 6.4,
      blue_hydrogen: 3.22,
      grey_hydrogen: 2.0,
    },
    {
      date: new Date('2023-10-14'),
      green_hydrogen: 6.63,
      blue_hydrogen: 3.1,
      grey_hydrogen: 1.98,
    },
    {
      date: new Date('2023-10-15'),
      green_hydrogen: 6.64,
      blue_hydrogen: 3.22,
      grey_hydrogen: 1.93,
    },
    {
      date: new Date('2023-10-16'),
      green_hydrogen: 6.58,
      blue_hydrogen: 3.11,
      grey_hydrogen: 1.7,
    },
    {
      date: new Date('2023-10-17'),
      green_hydrogen: 6.43,
      blue_hydrogen: 3.28,
      grey_hydrogen: 1.85,
    },
    {
      date: new Date('2023-10-18'),
      green_hydrogen: 6.62,
      blue_hydrogen: 3.33,
      grey_hydrogen: 1.91,
    },
    {
      date: new Date('2023-10-19'),
      green_hydrogen: 6.64,
      blue_hydrogen: 3.3,
      grey_hydrogen: 1.99,
    },
    {
      date: new Date('2023-10-20'),
      green_hydrogen: 6.45,
      blue_hydrogen: 3.35,
      grey_hydrogen: 1.84,
    },
    {
      date: new Date('2023-10-21'),
      green_hydrogen: 6.41,
      blue_hydrogen: 3.11,
      grey_hydrogen: 1.81,
    },
    {
      date: new Date('2023-10-22'),
      green_hydrogen: 6.69,
      blue_hydrogen: 3.12,
      grey_hydrogen: 1.61,
    },
    {
      date: new Date('2023-10-23'),
      green_hydrogen: 6.52,
      blue_hydrogen: 3.05,
      grey_hydrogen: 1.87,
    },
    {
      date: new Date('2023-10-24'),
      green_hydrogen: 6.62,
      blue_hydrogen: 3.2,
      grey_hydrogen: 1.88,
    },
    {
      date: new Date('2023-10-25'),
      green_hydrogen: 6.27,
      blue_hydrogen: 3.07,
      grey_hydrogen: 1.92,
    },
    {
      date: new Date('2023-10-26'),
      green_hydrogen: 6.52,
      blue_hydrogen: 3.26,
      grey_hydrogen: 1.88,
    },
    {
      date: new Date('2023-10-27'),
      green_hydrogen: 6.28,
      blue_hydrogen: 3.09,
      grey_hydrogen: 1.7,
    },
    {
      date: new Date('2023-10-28'),
      green_hydrogen: 6.56,
      blue_hydrogen: 3.24,
      grey_hydrogen: 1.77,
    },
    {
      date: new Date('2023-10-29'),
      green_hydrogen: 6.6,
      blue_hydrogen: 3.01,
      grey_hydrogen: 1.72,
    },
    {
      date: new Date('2023-10-30'),
      green_hydrogen: 6.21,
      blue_hydrogen: 3.34,
      grey_hydrogen: 1.81,
    },
    {
      date: new Date('2023-10-31'),
      green_hydrogen: 6.78,
      blue_hydrogen: 3.1,
      grey_hydrogen: 1.63,
    },
    {
      date: new Date('2023-11-01'),
      green_hydrogen: 6.27,
      blue_hydrogen: 3.26,
      grey_hydrogen: 1.64,
    },
    {
      date: new Date('2023-11-02'),
      green_hydrogen: 6.27,
      blue_hydrogen: 3.13,
      grey_hydrogen: 1.94,
    },
    {
      date: new Date('2023-11-03'),
      green_hydrogen: 6.25,
      blue_hydrogen: 3.32,
      grey_hydrogen: 1.99,
    },
    {
      date: new Date('2023-11-04'),
      green_hydrogen: 6.62,
      blue_hydrogen: 3.21,
      grey_hydrogen: 1.94,
    },
    {
      date: new Date('2023-11-05'),
      green_hydrogen: 6.4,
      blue_hydrogen: 3.05,
      grey_hydrogen: 1.69,
    },
    {
      date: new Date('2023-11-06'),
      green_hydrogen: 6.32,
      blue_hydrogen: 3.22,
      grey_hydrogen: 1.92,
    },
    {
      date: new Date('2023-11-07'),
      green_hydrogen: 6.51,
      blue_hydrogen: 3.04,
      grey_hydrogen: 1.98,
    },
    {
      date: new Date('2023-11-08'),
      green_hydrogen: 6.46,
      blue_hydrogen: 3.15,
      grey_hydrogen: 1.91,
    },
    {
      date: new Date('2023-11-09'),
      green_hydrogen: 6.29,
      blue_hydrogen: 3.2,
      grey_hydrogen: 1.6,
    },
    {
      date: new Date('2023-11-10'),
      green_hydrogen: 6.24,
      blue_hydrogen: 3.28,
      grey_hydrogen: 1.62,
    },
    {
      date: new Date('2023-11-11'),
      green_hydrogen: 6.28,
      blue_hydrogen: 3.3,
      grey_hydrogen: 1.82,
    },
    {
      date: new Date('2023-11-12'),
      green_hydrogen: 6.49,
      blue_hydrogen: 3.35,
      grey_hydrogen: 1.82,
    },
    {
      date: new Date('2023-11-13'),
      green_hydrogen: 6.64,
      blue_hydrogen: 3.05,
      grey_hydrogen: 1.71,
    },
    {
      date: new Date('2023-11-14'),
      green_hydrogen: 6.44,
      blue_hydrogen: 3.06,
      grey_hydrogen: 1.86,
    },
    {
      date: new Date('2023-11-15'),
      green_hydrogen: 6.62,
      blue_hydrogen: 3.31,
      grey_hydrogen: 1.78,
    },
    {
      date: new Date('2023-11-16'),
      green_hydrogen: 6.41,
      blue_hydrogen: 3.01,
      grey_hydrogen: 1.9,
    },
    {
      date: new Date('2023-11-17'),
      green_hydrogen: 6.54,
      blue_hydrogen: 3.39,
      grey_hydrogen: 1.84,
    },
    {
      date: new Date('2023-11-18'),
      green_hydrogen: 6.76,
      blue_hydrogen: 3.39,
      grey_hydrogen: 1.8,
    },
    {
      date: new Date('2023-11-19'),
      green_hydrogen: 6.6,
      blue_hydrogen: 3.24,
      grey_hydrogen: 1.71,
    },
    {
      date: new Date('2023-11-20'),
      green_hydrogen: 6.68,
      blue_hydrogen: 3.22,
      grey_hydrogen: 1.77,
    },
    {
      date: new Date('2023-11-21'),
      green_hydrogen: 6.65,
      blue_hydrogen: 3.4,
      grey_hydrogen: 1.65,
    },
    {
      date: new Date('2023-11-22'),
      green_hydrogen: 6.29,
      blue_hydrogen: 3.02,
      grey_hydrogen: 1.97,
    },
    {
      date: new Date('2023-11-23'),
      green_hydrogen: 6.24,
      blue_hydrogen: 3.05,
      grey_hydrogen: 1.97,
    },
    {
      date: new Date('2023-11-24'),
      green_hydrogen: 6.73,
      blue_hydrogen: 3.23,
      grey_hydrogen: 1.8,
    },
    {
      date: new Date('2023-11-25'),
      green_hydrogen: 6.51,
      blue_hydrogen: 3.26,
      grey_hydrogen: 1.81,
    },
    {
      date: new Date('2023-11-26'),
      green_hydrogen: 6.3,
      blue_hydrogen: 3.25,
      grey_hydrogen: 1.73,
    },
    {
      date: new Date('2023-11-27'),
      green_hydrogen: 6.6,
      blue_hydrogen: 3.02,
      grey_hydrogen: 1.61,
    },
    {
      date: new Date('2023-11-28'),
      green_hydrogen: 6.35,
      blue_hydrogen: 3.3,
      grey_hydrogen: 1.68,
    },
    {
      date: new Date('2023-11-29'),
      green_hydrogen: 6.47,
      blue_hydrogen: 3.12,
      grey_hydrogen: 1.69,
    },
    {
      date: new Date('2023-11-30'),
      green_hydrogen: 6.79,
      blue_hydrogen: 3.03,
      grey_hydrogen: 1.69,
    },
    {
      date: new Date('2023-12-01'),
      green_hydrogen: 6.3,
      blue_hydrogen: 3.03,
      grey_hydrogen: 1.72,
    },
    {
      date: new Date('2023-12-02'),
      green_hydrogen: 6.21,
      blue_hydrogen: 3.4,
      grey_hydrogen: 1.78,
    },
    {
      date: new Date('2023-12-03'),
      green_hydrogen: 6.29,
      blue_hydrogen: 3.07,
      grey_hydrogen: 1.86,
    },
    {
      date: new Date('2023-12-04'),
      green_hydrogen: 6.53,
      blue_hydrogen: 3.1,
      grey_hydrogen: 1.99,
    },
    {
      date: new Date('2023-12-05'),
      green_hydrogen: 6.68,
      blue_hydrogen: 3.18,
      grey_hydrogen: 1.65,
    },
    {
      date: new Date('2023-12-06'),
      green_hydrogen: 6.51,
      blue_hydrogen: 3.18,
      grey_hydrogen: 1.67,
    },
    {
      date: new Date('2023-12-07'),
      green_hydrogen: 6.33,
      blue_hydrogen: 3.29,
      grey_hydrogen: 1.67,
    },
    {
      date: new Date('2023-12-08'),
      green_hydrogen: 6.7,
      blue_hydrogen: 3.06,
      grey_hydrogen: 1.84,
    },
    {
      date: new Date('2023-12-09'),
      green_hydrogen: 6.41,
      blue_hydrogen: 3.15,
      grey_hydrogen: 1.63,
    },
    {
      date: new Date('2023-12-10'),
      green_hydrogen: 6.64,
      blue_hydrogen: 3.12,
      grey_hydrogen: 1.96,
    },
    {
      date: new Date('2023-12-11'),
      green_hydrogen: 6.67,
      blue_hydrogen: 3.34,
      grey_hydrogen: 1.81,
    },
    {
      date: new Date('2023-12-12'),
      green_hydrogen: 6.79,
      blue_hydrogen: 3.01,
      grey_hydrogen: 1.81,
    },
    {
      date: new Date('2023-12-13'),
      green_hydrogen: 6.43,
      blue_hydrogen: 3.29,
      grey_hydrogen: 1.94,
    },
    {
      date: new Date('2023-12-14'),
      green_hydrogen: 6.76,
      blue_hydrogen: 3.08,
      grey_hydrogen: 1.92,
    },
    {
      date: new Date('2023-12-15'),
      green_hydrogen: 6.47,
      blue_hydrogen: 3.32,
      grey_hydrogen: 1.89,
    },
    {
      date: new Date('2023-12-16'),
      green_hydrogen: 6.68,
      blue_hydrogen: 3.2,
      grey_hydrogen: 1.72,
    },
    {
      date: new Date('2023-12-17'),
      green_hydrogen: 6.39,
      blue_hydrogen: 3.06,
      grey_hydrogen: 1.96,
    },
    {
      date: new Date('2023-12-18'),
      green_hydrogen: 6.46,
      blue_hydrogen: 3.08,
      grey_hydrogen: 1.64,
    },
    {
      date: new Date('2023-12-19'),
      green_hydrogen: 6.31,
      blue_hydrogen: 3.36,
      grey_hydrogen: 1.61,
    },
    {
      date: new Date('2023-12-20'),
      green_hydrogen: 6.72,
      blue_hydrogen: 3.21,
      grey_hydrogen: 1.71,
    },
    {
      date: new Date('2023-12-21'),
      green_hydrogen: 6.28,
      blue_hydrogen: 3.0,
      grey_hydrogen: 1.86,
    },
    {
      date: new Date('2023-12-22'),
      green_hydrogen: 6.6,
      blue_hydrogen: 3.34,
      grey_hydrogen: 1.82,
    },
    {
      date: new Date('2023-12-23'),
      green_hydrogen: 6.23,
      blue_hydrogen: 3.06,
      grey_hydrogen: 1.62,
    },
    {
      date: new Date('2023-12-24'),
      green_hydrogen: 6.71,
      blue_hydrogen: 3.1,
      grey_hydrogen: 1.79,
    },
    {
      date: new Date('2023-12-25'),
      green_hydrogen: 6.31,
      blue_hydrogen: 3.01,
      grey_hydrogen: 1.88,
    },
    {
      date: new Date('2023-12-26'),
      green_hydrogen: 6.25,
      blue_hydrogen: 3.11,
      grey_hydrogen: 1.62,
    },
    {
      date: new Date('2023-12-27'),
      green_hydrogen: 6.22,
      blue_hydrogen: 3.24,
      grey_hydrogen: 1.93,
    },
    {
      date: new Date('2023-12-28'),
      green_hydrogen: 6.33,
      blue_hydrogen: 3.03,
      grey_hydrogen: 1.71,
    },
    {
      date: new Date('2023-12-29'),
      green_hydrogen: 6.31,
      blue_hydrogen: 3.04,
      grey_hydrogen: 1.72,
    },
    {
      date: new Date('2023-12-30'),
      green_hydrogen: 6.76,
      blue_hydrogen: 3.03,
      grey_hydrogen: 1.76,
    },
    {
      date: new Date('2023-12-31'),
      green_hydrogen: 6.52,
      blue_hydrogen: 3.07,
      grey_hydrogen: 1.74,
    },
    {
      date: new Date('2024-01-01'),
      green_hydrogen: 6.72,
      blue_hydrogen: 3.09,
      grey_hydrogen: 1.68,
    },
    {
      date: new Date('2024-01-02'),
      green_hydrogen: 6.36,
      blue_hydrogen: 3.01,
      grey_hydrogen: 1.83,
    },
    {
      date: new Date('2024-01-03'),
      green_hydrogen: 6.58,
      blue_hydrogen: 3.17,
      grey_hydrogen: 1.65,
    },
    {
      date: new Date('2024-01-04'),
      green_hydrogen: 6.53,
      blue_hydrogen: 3.11,
      grey_hydrogen: 1.89,
    },
    {
      date: new Date('2024-01-05'),
      green_hydrogen: 6.3,
      blue_hydrogen: 3.17,
      grey_hydrogen: 1.87,
    },
    {
      date: new Date('2024-01-06'),
      green_hydrogen: 6.36,
      blue_hydrogen: 3.31,
      grey_hydrogen: 1.95,
    },
    {
      date: new Date('2024-01-07'),
      green_hydrogen: 6.48,
      blue_hydrogen: 3.38,
      grey_hydrogen: 1.97,
    },
    {
      date: new Date('2024-01-08'),
      green_hydrogen: 6.26,
      blue_hydrogen: 3.3,
      grey_hydrogen: 1.79,
    },
    {
      date: new Date('2024-01-09'),
      green_hydrogen: 6.34,
      blue_hydrogen: 3.12,
      grey_hydrogen: 1.71,
    },
    {
      date: new Date('2024-01-10'),
      green_hydrogen: 6.59,
      blue_hydrogen: 3.38,
      grey_hydrogen: 1.87,
    },
    {
      date: new Date('2024-01-11'),
      green_hydrogen: 6.48,
      blue_hydrogen: 3.06,
      grey_hydrogen: 1.84,
    },
    {
      date: new Date('2024-01-12'),
      green_hydrogen: 6.49,
      blue_hydrogen: 3.23,
      grey_hydrogen: 1.93,
    },
    {
      date: new Date('2024-01-13'),
      green_hydrogen: 6.29,
      blue_hydrogen: 3.33,
      grey_hydrogen: 1.88,
    },
    {
      date: new Date('2024-01-14'),
      green_hydrogen: 6.33,
      blue_hydrogen: 3.24,
      grey_hydrogen: 1.67,
    },
    {
      date: new Date('2024-01-15'),
      green_hydrogen: 6.39,
      blue_hydrogen: 3.05,
      grey_hydrogen: 1.83,
    },
    {
      date: new Date('2024-01-16'),
      green_hydrogen: 6.24,
      blue_hydrogen: 3.27,
      grey_hydrogen: 1.61,
    },
    {
      date: new Date('2024-01-17'),
      green_hydrogen: 6.26,
      blue_hydrogen: 3.28,
      grey_hydrogen: 1.8,
    },
    {
      date: new Date('2024-01-18'),
      green_hydrogen: 6.21,
      blue_hydrogen: 3.16,
      grey_hydrogen: 1.83,
    },
    {
      date: new Date('2024-01-19'),
      green_hydrogen: 6.63,
      blue_hydrogen: 3.11,
      grey_hydrogen: 1.68,
    },
    {
      date: new Date('2024-01-20'),
      green_hydrogen: 6.34,
      blue_hydrogen: 3.38,
      grey_hydrogen: 1.67,
    },
    {
      date: new Date('2024-01-21'),
      green_hydrogen: 6.25,
      blue_hydrogen: 3.26,
      grey_hydrogen: 1.99,
    },
    {
      date: new Date('2024-01-22'),
      green_hydrogen: 6.65,
      blue_hydrogen: 3.34,
      grey_hydrogen: 1.73,
    },
    {
      date: new Date('2024-01-23'),
      green_hydrogen: 6.63,
      blue_hydrogen: 3.27,
      grey_hydrogen: 1.91,
    },
    {
      date: new Date('2024-01-24'),
      green_hydrogen: 6.23,
      blue_hydrogen: 3.13,
      grey_hydrogen: 1.95,
    },
    {
      date: new Date('2024-01-25'),
      green_hydrogen: 6.27,
      blue_hydrogen: 3.2,
      grey_hydrogen: 1.68,
    },
    {
      date: new Date('2024-01-26'),
      green_hydrogen: 6.61,
      blue_hydrogen: 3.39,
      grey_hydrogen: 1.88,
    },
    {
      date: new Date('2024-01-27'),
      green_hydrogen: 6.67,
      blue_hydrogen: 3.05,
      grey_hydrogen: 1.91,
    },
    {
      date: new Date('2024-01-28'),
      green_hydrogen: 6.36,
      blue_hydrogen: 3.24,
      grey_hydrogen: 1.63,
    },
    {
      date: new Date('2024-01-29'),
      green_hydrogen: 6.27,
      blue_hydrogen: 3.19,
      grey_hydrogen: 1.79,
    },
    {
      date: new Date('2024-01-30'),
      green_hydrogen: 6.55,
      blue_hydrogen: 3.15,
      grey_hydrogen: 1.89,
    },
    {
      date: new Date('2024-01-31'),
      green_hydrogen: 6.64,
      blue_hydrogen: 3.25,
      grey_hydrogen: 1.92,
    },
    {
      date: new Date('2024-02-01'),
      green_hydrogen: 6.43,
      blue_hydrogen: 3.22,
      grey_hydrogen: 1.95,
    },
    {
      date: new Date('2024-02-02'),
      green_hydrogen: 6.49,
      blue_hydrogen: 3.34,
      grey_hydrogen: 1.63,
    },
    {
      date: new Date('2024-02-03'),
      green_hydrogen: 6.58,
      blue_hydrogen: 3.27,
      grey_hydrogen: 1.9,
    },
    {
      date: new Date('2024-02-04'),
      green_hydrogen: 6.36,
      blue_hydrogen: 3.08,
      grey_hydrogen: 1.62,
    },
    {
      date: new Date('2024-02-05'),
      green_hydrogen: 6.73,
      blue_hydrogen: 3.3,
      grey_hydrogen: 1.96,
    },
    {
      date: new Date('2024-02-06'),
      green_hydrogen: 6.34,
      blue_hydrogen: 3.36,
      grey_hydrogen: 1.68,
    },
    {
      date: new Date('2024-02-07'),
      green_hydrogen: 6.69,
      blue_hydrogen: 3.3,
      grey_hydrogen: 1.88,
    },
    {
      date: new Date('2024-02-08'),
      green_hydrogen: 6.51,
      blue_hydrogen: 3.21,
      grey_hydrogen: 1.87,
    },
    {
      date: new Date('2024-02-09'),
      green_hydrogen: 6.53,
      blue_hydrogen: 3.3,
      grey_hydrogen: 1.75,
    },
    {
      date: new Date('2024-02-10'),
      green_hydrogen: 6.34,
      blue_hydrogen: 3.02,
      grey_hydrogen: 1.8,
    },
    {
      date: new Date('2024-02-11'),
      green_hydrogen: 6.24,
      blue_hydrogen: 3.01,
      grey_hydrogen: 1.92,
    },
    {
      date: new Date('2024-02-12'),
      green_hydrogen: 6.23,
      blue_hydrogen: 3.14,
      grey_hydrogen: 1.71,
    },
    {
      date: new Date('2024-02-13'),
      green_hydrogen: 6.29,
      blue_hydrogen: 3.12,
      grey_hydrogen: 1.91,
    },
    {
      date: new Date('2024-02-14'),
      green_hydrogen: 6.28,
      blue_hydrogen: 3.1,
      grey_hydrogen: 1.98,
    },
    {
      date: new Date('2024-02-15'),
      green_hydrogen: 6.49,
      blue_hydrogen: 3.13,
      grey_hydrogen: 1.67,
    },
    {
      date: new Date('2024-02-16'),
      green_hydrogen: 6.33,
      blue_hydrogen: 3.07,
      grey_hydrogen: 1.76,
    },
    {
      date: new Date('2024-02-17'),
      green_hydrogen: 6.35,
      blue_hydrogen: 3.16,
      grey_hydrogen: 1.86,
    },
    {
      date: new Date('2024-02-18'),
      green_hydrogen: 6.59,
      blue_hydrogen: 3.12,
      grey_hydrogen: 1.73,
    },
    {
      date: new Date('2024-02-19'),
      green_hydrogen: 6.54,
      blue_hydrogen: 3.36,
      grey_hydrogen: 1.87,
    },
    {
      date: new Date('2024-02-20'),
      green_hydrogen: 6.57,
      blue_hydrogen: 3.13,
      grey_hydrogen: 1.94,
    },
    {
      date: new Date('2024-02-21'),
      green_hydrogen: 6.55,
      blue_hydrogen: 3.19,
      grey_hydrogen: 1.87,
    },
    {
      date: new Date('2024-02-22'),
      green_hydrogen: 6.34,
      blue_hydrogen: 3.02,
      grey_hydrogen: 1.65,
    },
    {
      date: new Date('2024-02-23'),
      green_hydrogen: 6.61,
      blue_hydrogen: 3.22,
      grey_hydrogen: 1.63,
    },
    {
      date: new Date('2024-02-24'),
      green_hydrogen: 6.36,
      blue_hydrogen: 3.04,
      grey_hydrogen: 1.89,
    },
    {
      date: new Date('2024-02-25'),
      green_hydrogen: 6.57,
      blue_hydrogen: 3.01,
      grey_hydrogen: 1.76,
    },
    {
      date: new Date('2024-02-26'),
      green_hydrogen: 6.21,
      blue_hydrogen: 3.14,
      grey_hydrogen: 1.79,
    },
    {
      date: new Date('2024-02-27'),
      green_hydrogen: 6.36,
      blue_hydrogen: 3.14,
      grey_hydrogen: 1.88,
    },
    {
      date: new Date('2024-02-28'),
      green_hydrogen: 6.69,
      blue_hydrogen: 3.38,
      grey_hydrogen: 1.94,
    },
    {
      date: new Date('2024-02-29'),
      green_hydrogen: 6.37,
      blue_hydrogen: 3.29,
      grey_hydrogen: 1.93,
    },
    {
      date: new Date('2024-03-01'),
      green_hydrogen: 6.42,
      blue_hydrogen: 3.08,
      grey_hydrogen: 1.89,
    },
    {
      date: new Date('2024-03-02'),
      green_hydrogen: 6.7,
      blue_hydrogen: 3.35,
      grey_hydrogen: 1.66,
    },
    {
      date: new Date('2024-03-03'),
      green_hydrogen: 6.61,
      blue_hydrogen: 3.1,
      grey_hydrogen: 1.78,
    },
    {
      date: new Date('2024-03-04'),
      green_hydrogen: 6.57,
      blue_hydrogen: 3.25,
      grey_hydrogen: 1.62,
    },
    {
      date: new Date('2024-03-05'),
      green_hydrogen: 6.77,
      blue_hydrogen: 3.33,
      grey_hydrogen: 1.72,
    },
    {
      date: new Date('2024-03-06'),
      green_hydrogen: 6.31,
      blue_hydrogen: 3.02,
      grey_hydrogen: 1.75,
    },
    {
      date: new Date('2024-03-07'),
      green_hydrogen: 6.59,
      blue_hydrogen: 3.03,
      grey_hydrogen: 1.8,
    },
    {
      date: new Date('2024-03-08'),
      green_hydrogen: 6.33,
      blue_hydrogen: 3.27,
      grey_hydrogen: 1.73,
    },
    {
      date: new Date('2024-03-09'),
      green_hydrogen: 6.23,
      blue_hydrogen: 3.39,
      grey_hydrogen: 1.89,
    },
    {
      date: new Date('2024-03-10'),
      green_hydrogen: 6.74,
      blue_hydrogen: 3.15,
      grey_hydrogen: 1.61,
    },
    {
      date: new Date('2024-03-11'),
      green_hydrogen: 6.21,
      blue_hydrogen: 3.17,
      grey_hydrogen: 1.82,
    },
    {
      date: new Date('2024-03-12'),
      green_hydrogen: 6.38,
      blue_hydrogen: 3.22,
      grey_hydrogen: 1.78,
    },
    {
      date: new Date('2024-03-13'),
      green_hydrogen: 6.3,
      blue_hydrogen: 3.36,
      grey_hydrogen: 1.64,
    },
    {
      date: new Date('2024-03-14'),
      green_hydrogen: 6.79,
      blue_hydrogen: 3.4,
      grey_hydrogen: 1.73,
    },
    {
      date: new Date('2024-03-15'),
      green_hydrogen: 6.78,
      blue_hydrogen: 3.14,
      grey_hydrogen: 1.8,
    },
    {
      date: new Date('2024-03-16'),
      green_hydrogen: 6.68,
      blue_hydrogen: 3.02,
      grey_hydrogen: 1.97,
    },
    {
      date: new Date('2024-03-17'),
      green_hydrogen: 6.2,
      blue_hydrogen: 3.32,
      grey_hydrogen: 1.95,
    },
    {
      date: new Date('2024-03-18'),
      green_hydrogen: 6.63,
      blue_hydrogen: 3.11,
      grey_hydrogen: 1.79,
    },
    {
      date: new Date('2024-03-19'),
      green_hydrogen: 6.5,
      blue_hydrogen: 3.22,
      grey_hydrogen: 1.79,
    },
    {
      date: new Date('2024-03-20'),
      green_hydrogen: 6.5,
      blue_hydrogen: 3.19,
      grey_hydrogen: 1.73,
    },
    {
      date: new Date('2024-03-21'),
      green_hydrogen: 6.63,
      blue_hydrogen: 3.37,
      grey_hydrogen: 1.63,
    },
    {
      date: new Date('2024-03-22'),
      green_hydrogen: 6.5,
      blue_hydrogen: 3.2,
      grey_hydrogen: 1.71,
    },
    {
      date: new Date('2024-03-23'),
      green_hydrogen: 6.73,
      blue_hydrogen: 3.4,
      grey_hydrogen: 1.72,
    },
    {
      date: new Date('2024-03-24'),
      green_hydrogen: 6.59,
      blue_hydrogen: 3.13,
      grey_hydrogen: 1.79,
    },
    {
      date: new Date('2024-03-25'),
      green_hydrogen: 6.38,
      blue_hydrogen: 3.06,
      grey_hydrogen: 1.88,
    },
    {
      date: new Date('2024-03-26'),
      green_hydrogen: 6.44,
      blue_hydrogen: 3.16,
      grey_hydrogen: 1.67,
    },
    {
      date: new Date('2024-03-27'),
      green_hydrogen: 6.72,
      blue_hydrogen: 3.06,
      grey_hydrogen: 1.79,
    },
    {
      date: new Date('2024-03-28'),
      green_hydrogen: 6.64,
      blue_hydrogen: 3.21,
      grey_hydrogen: 1.62,
    },
    {
      date: new Date('2024-03-29'),
      green_hydrogen: 6.3,
      blue_hydrogen: 3.11,
      grey_hydrogen: 1.7,
    },
    {
      date: new Date('2024-03-30'),
      green_hydrogen: 6.6,
      blue_hydrogen: 3.04,
      grey_hydrogen: 1.8,
    },
    {
      date: new Date('2024-03-31'),
      green_hydrogen: 6.4,
      blue_hydrogen: 3.08,
      grey_hydrogen: 1.73,
    },
    {
      date: new Date('2024-04-01'),
      green_hydrogen: 6.64,
      blue_hydrogen: 3.25,
      grey_hydrogen: 1.8,
    },
    {
      date: new Date('2024-04-02'),
      green_hydrogen: 6.26,
      blue_hydrogen: 3.24,
      grey_hydrogen: 1.98,
    },
    {
      date: new Date('2024-04-03'),
      green_hydrogen: 6.67,
      blue_hydrogen: 3.39,
      grey_hydrogen: 1.67,
    },
    {
      date: new Date('2024-04-04'),
      green_hydrogen: 6.62,
      blue_hydrogen: 3.28,
      grey_hydrogen: 1.92,
    },
    {
      date: new Date('2024-04-05'),
      green_hydrogen: 6.64,
      blue_hydrogen: 3.01,
      grey_hydrogen: 1.98,
    },
    {
      date: new Date('2024-04-06'),
      green_hydrogen: 6.38,
      blue_hydrogen: 3.09,
      grey_hydrogen: 1.68,
    },
    {
      date: new Date('2024-04-07'),
      green_hydrogen: 6.22,
      blue_hydrogen: 3.26,
      grey_hydrogen: 1.81,
    },
    {
      date: new Date('2024-04-08'),
      green_hydrogen: 6.73,
      blue_hydrogen: 3.11,
      grey_hydrogen: 1.89,
    },
    {
      date: new Date('2024-04-09'),
      green_hydrogen: 6.72,
      blue_hydrogen: 3.05,
      grey_hydrogen: 1.98,
    },
    {
      date: new Date('2024-04-10'),
      green_hydrogen: 6.74,
      blue_hydrogen: 3.03,
      grey_hydrogen: 1.68,
    },
    {
      date: new Date('2024-04-11'),
      green_hydrogen: 6.34,
      blue_hydrogen: 3.07,
      grey_hydrogen: 1.99,
    },
    {
      date: new Date('2024-04-12'),
      green_hydrogen: 6.41,
      blue_hydrogen: 3.1,
      grey_hydrogen: 1.78,
    },
    {
      date: new Date('2024-04-13'),
      green_hydrogen: 6.67,
      blue_hydrogen: 3.15,
      grey_hydrogen: 1.61,
    },
    {
      date: new Date('2024-04-14'),
      green_hydrogen: 6.77,
      blue_hydrogen: 3.21,
      grey_hydrogen: 1.6,
    },
    {
      date: new Date('2024-04-15'),
      green_hydrogen: 6.38,
      blue_hydrogen: 3.14,
      grey_hydrogen: 1.95,
    },
    {
      date: new Date('2024-04-16'),
      green_hydrogen: 6.21,
      blue_hydrogen: 3.38,
      grey_hydrogen: 1.92,
    },
    {
      date: new Date('2024-04-17'),
      green_hydrogen: 6.72,
      blue_hydrogen: 3.19,
      grey_hydrogen: 1.62,
    },
    {
      date: new Date('2024-04-18'),
      green_hydrogen: 6.68,
      blue_hydrogen: 3.3,
      grey_hydrogen: 1.97,
    },
    {
      date: new Date('2024-04-19'),
      green_hydrogen: 6.75,
      blue_hydrogen: 3.38,
      grey_hydrogen: 1.85,
    },
    {
      date: new Date('2024-04-20'),
      green_hydrogen: 6.24,
      blue_hydrogen: 3.33,
      grey_hydrogen: 1.86,
    },
    {
      date: new Date('2024-04-21'),
      green_hydrogen: 6.67,
      blue_hydrogen: 3.15,
      grey_hydrogen: 1.92,
    },
    {
      date: new Date('2024-04-22'),
      green_hydrogen: 6.27,
      blue_hydrogen: 3.25,
      grey_hydrogen: 1.85,
    },
    {
      date: new Date('2024-04-23'),
      green_hydrogen: 6.57,
      blue_hydrogen: 3.3,
      grey_hydrogen: 1.97,
    },
    {
      date: new Date('2024-04-24'),
      green_hydrogen: 6.72,
      blue_hydrogen: 3.16,
      grey_hydrogen: 1.65,
    },
    {
      date: new Date('2024-04-25'),
      green_hydrogen: 6.25,
      blue_hydrogen: 3.33,
      grey_hydrogen: 1.66,
    },
    {
      date: new Date('2024-04-26'),
      green_hydrogen: 6.68,
      blue_hydrogen: 3.31,
      grey_hydrogen: 1.73,
    },
    {
      date: new Date('2024-04-27'),
      green_hydrogen: 6.21,
      blue_hydrogen: 3.05,
      grey_hydrogen: 1.85,
    },
    {
      date: new Date('2024-04-28'),
      green_hydrogen: 6.57,
      blue_hydrogen: 3.39,
      grey_hydrogen: 1.83,
    },
    {
      date: new Date('2024-04-29'),
      green_hydrogen: 6.32,
      blue_hydrogen: 3.01,
      grey_hydrogen: 1.91,
    },
    {
      date: new Date('2024-04-30'),
      green_hydrogen: 6.59,
      blue_hydrogen: 3.29,
      grey_hydrogen: 1.92,
    },
    {
      date: new Date('2024-05-01'),
      green_hydrogen: 6.51,
      blue_hydrogen: 3.24,
      grey_hydrogen: 1.68,
    },
    {
      date: new Date('2024-05-02'),
      green_hydrogen: 6.63,
      blue_hydrogen: 3.23,
      grey_hydrogen: 1.97,
    },
    {
      date: new Date('2024-05-03'),
      green_hydrogen: 6.28,
      blue_hydrogen: 3.09,
      grey_hydrogen: 1.74,
    },
    {
      date: new Date('2024-05-04'),
      green_hydrogen: 6.55,
      blue_hydrogen: 3.15,
      grey_hydrogen: 1.83,
    },
    {
      date: new Date('2024-05-05'),
      green_hydrogen: 6.57,
      blue_hydrogen: 3.34,
      grey_hydrogen: 1.91,
    },
    {
      date: new Date('2024-05-06'),
      green_hydrogen: 6.61,
      blue_hydrogen: 3.38,
      grey_hydrogen: 1.89,
    },
    {
      date: new Date('2024-05-07'),
      green_hydrogen: 6.44,
      blue_hydrogen: 3.26,
      grey_hydrogen: 1.99,
    },
    {
      date: new Date('2024-05-08'),
      green_hydrogen: 6.46,
      blue_hydrogen: 3.35,
      grey_hydrogen: 1.75,
    },
    {
      date: new Date('2024-05-09'),
      green_hydrogen: 6.8,
      blue_hydrogen: 3.14,
      grey_hydrogen: 1.92,
    },
    {
      date: new Date('2024-05-10'),
      green_hydrogen: 6.6,
      blue_hydrogen: 3.33,
      grey_hydrogen: 1.94,
    },
    {
      date: new Date('2024-05-11'),
      green_hydrogen: 6.46,
      blue_hydrogen: 3.22,
      grey_hydrogen: 1.73,
    },
    {
      date: new Date('2024-05-12'),
      green_hydrogen: 6.27,
      blue_hydrogen: 3.36,
      grey_hydrogen: 1.69,
    },
    {
      date: new Date('2024-05-13'),
      green_hydrogen: 6.41,
      blue_hydrogen: 3.03,
      grey_hydrogen: 1.62,
    },
    {
      date: new Date('2024-05-14'),
      green_hydrogen: 6.77,
      blue_hydrogen: 3.38,
      grey_hydrogen: 1.82,
    },
    {
      date: new Date('2024-05-15'),
      green_hydrogen: 6.64,
      blue_hydrogen: 3.08,
      grey_hydrogen: 1.82,
    },
    {
      date: new Date('2024-05-16'),
      green_hydrogen: 6.69,
      blue_hydrogen: 3.21,
      grey_hydrogen: 1.7,
    },
    {
      date: new Date('2024-05-17'),
      green_hydrogen: 6.38,
      blue_hydrogen: 3.34,
      grey_hydrogen: 1.8,
    },
    {
      date: new Date('2024-05-18'),
      green_hydrogen: 6.63,
      blue_hydrogen: 3.27,
      grey_hydrogen: 1.7,
    },
    {
      date: new Date('2024-05-19'),
      green_hydrogen: 6.8,
      blue_hydrogen: 3.07,
      grey_hydrogen: 1.84,
    },
    {
      date: new Date('2024-05-20'),
      green_hydrogen: 6.39,
      blue_hydrogen: 3.14,
      grey_hydrogen: 1.67,
    },
    {
      date: new Date('2024-05-21'),
      green_hydrogen: 6.72,
      blue_hydrogen: 3.37,
      grey_hydrogen: 1.86,
    },
    {
      date: new Date('2024-05-22'),
      green_hydrogen: 6.73,
      blue_hydrogen: 3.38,
      grey_hydrogen: 1.63,
    },
    {
      date: new Date('2024-05-23'),
      green_hydrogen: 6.26,
      blue_hydrogen: 3.37,
      grey_hydrogen: 1.7,
    },
    {
      date: new Date('2024-05-24'),
      green_hydrogen: 6.73,
      blue_hydrogen: 3.14,
      grey_hydrogen: 1.84,
    },
    {
      date: new Date('2024-05-25'),
      green_hydrogen: 6.71,
      blue_hydrogen: 3.05,
      grey_hydrogen: 1.74,
    },
    {
      date: new Date('2024-05-26'),
      green_hydrogen: 6.52,
      blue_hydrogen: 3.06,
      grey_hydrogen: 1.86,
    },
    {
      date: new Date('2024-05-27'),
      green_hydrogen: 6.34,
      blue_hydrogen: 3.1,
      grey_hydrogen: 1.76,
    },
    {
      date: new Date('2024-05-28'),
      green_hydrogen: 6.63,
      blue_hydrogen: 3.3,
      grey_hydrogen: 1.9,
    },
    {
      date: new Date('2024-05-29'),
      green_hydrogen: 6.5,
      blue_hydrogen: 3.1,
      grey_hydrogen: 1.68,
    },
    {
      date: new Date('2024-05-30'),
      green_hydrogen: 6.4,
      blue_hydrogen: 3.02,
      grey_hydrogen: 1.87,
    },
    {
      date: new Date('2024-05-31'),
      green_hydrogen: 6.53,
      blue_hydrogen: 3.1,
      grey_hydrogen: 1.66,
    },
    {
      date: new Date('2024-06-01'),
      green_hydrogen: 6.46,
      blue_hydrogen: 3.28,
      grey_hydrogen: 1.89,
    },
    {
      date: new Date('2024-06-02'),
      green_hydrogen: 6.77,
      blue_hydrogen: 3.17,
      grey_hydrogen: 1.92,
    },
    {
      date: new Date('2024-06-03'),
      green_hydrogen: 6.47,
      blue_hydrogen: 3.02,
      grey_hydrogen: 1.62,
    },
    {
      date: new Date('2024-06-04'),
      green_hydrogen: 6.26,
      blue_hydrogen: 3.07,
      grey_hydrogen: 1.8,
    },
    {
      date: new Date('2024-06-05'),
      green_hydrogen: 6.68,
      blue_hydrogen: 3.03,
      grey_hydrogen: 1.69,
    },
    {
      date: new Date('2024-06-06'),
      green_hydrogen: 6.72,
      blue_hydrogen: 3.31,
      grey_hydrogen: 1.6,
    },
    {
      date: new Date('2024-06-07'),
      green_hydrogen: 6.64,
      blue_hydrogen: 3.08,
      grey_hydrogen: 1.88,
    },
    {
      date: new Date('2024-06-08'),
      green_hydrogen: 6.79,
      blue_hydrogen: 3.3,
      grey_hydrogen: 1.85,
    },
    {
      date: new Date('2024-06-09'),
      green_hydrogen: 6.71,
      blue_hydrogen: 3.23,
      grey_hydrogen: 1.78,
    },
    {
      date: new Date('2024-06-10'),
      green_hydrogen: 6.61,
      blue_hydrogen: 3.31,
      grey_hydrogen: 1.71,
    },
    {
      date: new Date('2024-06-11'),
      green_hydrogen: 6.27,
      blue_hydrogen: 3.13,
      grey_hydrogen: 1.93,
    },
    {
      date: new Date('2024-06-12'),
      green_hydrogen: 6.26,
      blue_hydrogen: 3.02,
      grey_hydrogen: 1.65,
    },
    {
      date: new Date('2024-06-13'),
      green_hydrogen: 6.39,
      blue_hydrogen: 3.05,
      grey_hydrogen: 1.84,
    },
    {
      date: new Date('2024-06-14'),
      green_hydrogen: 6.64,
      blue_hydrogen: 3.18,
      grey_hydrogen: 1.84,
    },
    {
      date: new Date('2024-06-15'),
      green_hydrogen: 6.5,
      blue_hydrogen: 3.11,
      grey_hydrogen: 1.99,
    },
    {
      date: new Date('2024-06-16'),
      green_hydrogen: 6.31,
      blue_hydrogen: 3.18,
      grey_hydrogen: 1.76,
    },
    {
      date: new Date('2024-06-17'),
      green_hydrogen: 6.73,
      blue_hydrogen: 3.15,
      grey_hydrogen: 1.6,
    },
    {
      date: new Date('2024-06-18'),
      green_hydrogen: 6.64,
      blue_hydrogen: 3.16,
      grey_hydrogen: 1.69,
    },
    {
      date: new Date('2024-06-19'),
      green_hydrogen: 6.72,
      blue_hydrogen: 3.26,
      grey_hydrogen: 1.89,
    },
    {
      date: new Date('2024-06-20'),
      green_hydrogen: 6.51,
      blue_hydrogen: 3.26,
      grey_hydrogen: 1.96,
    },
    {
      date: new Date('2024-06-21'),
      green_hydrogen: 6.67,
      blue_hydrogen: 3.33,
      grey_hydrogen: 1.96,
    },
    {
      date: new Date('2024-06-22'),
      green_hydrogen: 6.47,
      blue_hydrogen: 3.28,
      grey_hydrogen: 1.8,
    },
    {
      date: new Date('2024-06-23'),
      green_hydrogen: 6.36,
      blue_hydrogen: 3.01,
      grey_hydrogen: 1.73,
    },
    {
      date: new Date('2024-06-24'),
      green_hydrogen: 6.49,
      blue_hydrogen: 3.0,
      grey_hydrogen: 1.62,
    },
    {
      date: new Date('2024-06-25'),
      green_hydrogen: 6.64,
      blue_hydrogen: 3.35,
      grey_hydrogen: 1.86,
    },
    {
      date: new Date('2024-06-26'),
      green_hydrogen: 6.5,
      blue_hydrogen: 3.33,
      grey_hydrogen: 1.82,
    },
    {
      date: new Date('2024-06-27'),
      green_hydrogen: 6.43,
      blue_hydrogen: 3.03,
      grey_hydrogen: 1.64,
    },
    {
      date: new Date('2024-06-28'),
      green_hydrogen: 6.26,
      blue_hydrogen: 3.35,
      grey_hydrogen: 1.67,
    },
    {
      date: new Date('2024-06-29'),
      green_hydrogen: 6.22,
      blue_hydrogen: 3.04,
      grey_hydrogen: 1.89,
    },
    {
      date: new Date('2024-06-30'),
      green_hydrogen: 6.39,
      blue_hydrogen: 3.25,
      grey_hydrogen: 1.64,
    },
    {
      date: new Date('2024-07-01'),
      green_hydrogen: 6.45,
      blue_hydrogen: 3.26,
      grey_hydrogen: 1.7,
    },
    {
      date: new Date('2024-07-02'),
      green_hydrogen: 6.35,
      blue_hydrogen: 3.13,
      grey_hydrogen: 1.87,
    },
    {
      date: new Date('2024-07-03'),
      green_hydrogen: 6.21,
      blue_hydrogen: 3.24,
      grey_hydrogen: 1.7,
    },
    {
      date: new Date('2024-07-04'),
      green_hydrogen: 6.79,
      blue_hydrogen: 3.04,
      grey_hydrogen: 1.99,
    },
    {
      date: new Date('2024-07-05'),
      green_hydrogen: 6.65,
      blue_hydrogen: 3.33,
      grey_hydrogen: 1.66,
    },
    {
      date: new Date('2024-07-06'),
      green_hydrogen: 6.62,
      blue_hydrogen: 3.37,
      grey_hydrogen: 1.61,
    },
    {
      date: new Date('2024-07-07'),
      green_hydrogen: 6.2,
      blue_hydrogen: 3.33,
      grey_hydrogen: 1.8,
    },
    {
      date: new Date('2024-07-08'),
      green_hydrogen: 6.42,
      blue_hydrogen: 3.02,
      grey_hydrogen: 1.85,
    },
    {
      date: new Date('2024-07-09'),
      green_hydrogen: 6.27,
      blue_hydrogen: 3.15,
      grey_hydrogen: 1.74,
    },
    {
      date: new Date('2024-07-10'),
      green_hydrogen: 6.64,
      blue_hydrogen: 3.19,
      grey_hydrogen: 1.94,
    },
    {
      date: new Date('2024-07-11'),
      green_hydrogen: 6.37,
      blue_hydrogen: 3.16,
      grey_hydrogen: 1.75,
    },
    {
      date: new Date('2024-07-12'),
      green_hydrogen: 6.25,
      blue_hydrogen: 3.11,
      grey_hydrogen: 1.87,
    },
    {
      date: new Date('2024-07-13'),
      green_hydrogen: 6.33,
      blue_hydrogen: 3.26,
      grey_hydrogen: 1.65,
    },
    {
      date: new Date('2024-07-14'),
      green_hydrogen: 6.59,
      blue_hydrogen: 3.05,
      grey_hydrogen: 1.82,
    },
    {
      date: new Date('2024-07-15'),
      green_hydrogen: 6.43,
      blue_hydrogen: 3.28,
      grey_hydrogen: 1.74,
    },
    {
      date: new Date('2024-07-16'),
      green_hydrogen: 6.3,
      blue_hydrogen: 3.02,
      grey_hydrogen: 1.7,
    },
    {
      date: new Date('2024-07-17'),
      green_hydrogen: 6.46,
      blue_hydrogen: 3.35,
      grey_hydrogen: 1.77,
    },
    {
      date: new Date('2024-07-18'),
      green_hydrogen: 6.2,
      blue_hydrogen: 3.36,
      grey_hydrogen: 1.96,
    },
    {
      date: new Date('2024-07-19'),
      green_hydrogen: 6.27,
      blue_hydrogen: 3.09,
      grey_hydrogen: 1.77,
    },
    {
      date: new Date('2024-07-20'),
      green_hydrogen: 6.4,
      blue_hydrogen: 3.34,
      grey_hydrogen: 1.8,
    },
    {
      date: new Date('2024-07-21'),
      green_hydrogen: 6.68,
      blue_hydrogen: 3.14,
      grey_hydrogen: 1.65,
    },
    {
      date: new Date('2024-07-22'),
      green_hydrogen: 6.21,
      blue_hydrogen: 3.23,
      grey_hydrogen: 1.86,
    },
    {
      date: new Date('2024-07-23'),
      green_hydrogen: 6.26,
      blue_hydrogen: 3.36,
      grey_hydrogen: 1.72,
    },
    {
      date: new Date('2024-07-24'),
      green_hydrogen: 6.36,
      blue_hydrogen: 3.1,
      grey_hydrogen: 1.63,
    },
    {
      date: new Date('2024-07-25'),
      green_hydrogen: 6.29,
      blue_hydrogen: 3.1,
      grey_hydrogen: 1.82,
    },
    {
      date: new Date('2024-07-26'),
      green_hydrogen: 6.39,
      blue_hydrogen: 3.29,
      grey_hydrogen: 1.62,
    },
    {
      date: new Date('2024-07-27'),
      green_hydrogen: 6.39,
      blue_hydrogen: 3.37,
      grey_hydrogen: 1.98,
    },
    {
      date: new Date('2024-07-28'),
      green_hydrogen: 6.39,
      blue_hydrogen: 3.18,
      grey_hydrogen: 1.65,
    },
    {
      date: new Date('2024-07-29'),
      green_hydrogen: 6.35,
      blue_hydrogen: 3.08,
      grey_hydrogen: 1.68,
    },
    {
      date: new Date('2024-07-30'),
      green_hydrogen: 6.6,
      blue_hydrogen: 3.13,
      grey_hydrogen: 1.84,
    },
    {
      date: new Date('2024-07-31'),
      green_hydrogen: 6.46,
      blue_hydrogen: 3.05,
      grey_hydrogen: 1.88,
    },
    {
      date: new Date('2024-08-01'),
      green_hydrogen: 6.29,
      blue_hydrogen: 3.27,
      grey_hydrogen: 1.92,
    },
    {
      date: new Date('2024-08-02'),
      green_hydrogen: 6.27,
      blue_hydrogen: 3.29,
      grey_hydrogen: 1.9,
    },
    {
      date: new Date('2024-08-03'),
      green_hydrogen: 6.53,
      blue_hydrogen: 3.07,
      grey_hydrogen: 1.62,
    },
    {
      date: new Date('2024-08-04'),
      green_hydrogen: 6.48,
      blue_hydrogen: 3.26,
      grey_hydrogen: 1.85,
    },
    {
      date: new Date('2024-08-05'),
      green_hydrogen: 6.65,
      blue_hydrogen: 3.19,
      grey_hydrogen: 1.88,
    },
    {
      date: new Date('2024-08-06'),
      green_hydrogen: 6.38,
      blue_hydrogen: 3.35,
      grey_hydrogen: 1.83,
    },
    {
      date: new Date('2024-08-07'),
      green_hydrogen: 6.24,
      blue_hydrogen: 3.07,
      grey_hydrogen: 2.0,
    },
    {
      date: new Date('2024-08-08'),
      green_hydrogen: 6.7,
      blue_hydrogen: 3.27,
      grey_hydrogen: 1.69,
    },
    {
      date: new Date('2024-08-09'),
      green_hydrogen: 6.39,
      blue_hydrogen: 3.12,
      grey_hydrogen: 1.86,
    },
    {
      date: new Date('2024-08-10'),
      green_hydrogen: 6.63,
      blue_hydrogen: 3.39,
      grey_hydrogen: 1.84,
    },
    {
      date: new Date('2024-08-11'),
      green_hydrogen: 6.56,
      blue_hydrogen: 3.04,
      grey_hydrogen: 1.84,
    },
    {
      date: new Date('2024-08-12'),
      green_hydrogen: 6.73,
      blue_hydrogen: 3.32,
      grey_hydrogen: 1.92,
    },
    {
      date: new Date('2024-08-13'),
      green_hydrogen: 6.49,
      blue_hydrogen: 3.07,
      grey_hydrogen: 1.63,
    },
    {
      date: new Date('2024-08-14'),
      green_hydrogen: 6.63,
      blue_hydrogen: 3.34,
      grey_hydrogen: 1.94,
    },
    {
      date: new Date('2024-08-15'),
      green_hydrogen: 6.2,
      blue_hydrogen: 3.14,
      grey_hydrogen: 1.82,
    },
    {
      date: new Date('2024-08-16'),
      green_hydrogen: 6.29,
      blue_hydrogen: 3.37,
      grey_hydrogen: 1.88,
    },
    {
      date: new Date('2024-08-17'),
      green_hydrogen: 6.6,
      blue_hydrogen: 3.06,
      grey_hydrogen: 1.95,
    },
    {
      date: new Date('2024-08-18'),
      green_hydrogen: 6.72,
      blue_hydrogen: 3.33,
      grey_hydrogen: 1.6,
    },
    {
      date: new Date('2024-08-19'),
      green_hydrogen: 6.24,
      blue_hydrogen: 3.06,
      grey_hydrogen: 1.67,
    },
    {
      date: new Date('2024-08-20'),
      green_hydrogen: 6.8,
      blue_hydrogen: 3.36,
      grey_hydrogen: 1.77,
    },
    {
      date: new Date('2024-08-21'),
      green_hydrogen: 6.48,
      blue_hydrogen: 3.29,
      grey_hydrogen: 1.67,
    },
    {
      date: new Date('2024-08-22'),
      green_hydrogen: 6.31,
      blue_hydrogen: 3.28,
      grey_hydrogen: 1.7,
    },
    {
      date: new Date('2024-08-23'),
      green_hydrogen: 6.57,
      blue_hydrogen: 3.3,
      grey_hydrogen: 1.94,
    },
    {
      date: new Date('2024-08-24'),
      green_hydrogen: 6.25,
      blue_hydrogen: 3.05,
      grey_hydrogen: 1.96,
    },
    {
      date: new Date('2024-08-25'),
      green_hydrogen: 6.67,
      blue_hydrogen: 3.01,
      grey_hydrogen: 1.62,
    },
    {
      date: new Date('2024-08-26'),
      green_hydrogen: 6.62,
      blue_hydrogen: 3.07,
      grey_hydrogen: 1.9,
    },
    {
      date: new Date('2024-08-27'),
      green_hydrogen: 6.38,
      blue_hydrogen: 3.2,
      grey_hydrogen: 1.8,
    },
    {
      date: new Date('2024-08-28'),
      green_hydrogen: 6.49,
      blue_hydrogen: 3.15,
      grey_hydrogen: 1.92,
    },
    {
      date: new Date('2024-08-29'),
      green_hydrogen: 6.78,
      blue_hydrogen: 3.14,
      grey_hydrogen: 1.68,
    },
    {
      date: new Date('2024-08-30'),
      green_hydrogen: 6.34,
      blue_hydrogen: 3.21,
      grey_hydrogen: 1.68,
    },
    {
      date: new Date('2024-08-31'),
      green_hydrogen: 6.73,
      blue_hydrogen: 3.28,
      grey_hydrogen: 1.97,
    },
    {
      date: new Date('2024-09-01'),
      green_hydrogen: 6.55,
      blue_hydrogen: 3.08,
      grey_hydrogen: 1.8,
    },
    {
      date: new Date('2024-09-02'),
      green_hydrogen: 6.35,
      blue_hydrogen: 3.25,
      grey_hydrogen: 1.71,
    },
    {
      date: new Date('2024-09-03'),
      green_hydrogen: 6.68,
      blue_hydrogen: 3.22,
      grey_hydrogen: 1.66,
    },
    {
      date: new Date('2024-09-04'),
      green_hydrogen: 6.39,
      blue_hydrogen: 3.24,
      grey_hydrogen: 1.77,
    },
    {
      date: new Date('2024-09-05'),
      green_hydrogen: 6.51,
      blue_hydrogen: 3.16,
      grey_hydrogen: 1.76,
    },
    {
      date: new Date('2024-09-06'),
      green_hydrogen: 6.36,
      blue_hydrogen: 3.26,
      grey_hydrogen: 1.81,
    },
    {
      date: new Date('2024-09-07'),
      green_hydrogen: 6.56,
      blue_hydrogen: 3.28,
      grey_hydrogen: 1.84,
    },
    {
      date: new Date('2024-09-08'),
      green_hydrogen: 6.27,
      blue_hydrogen: 3.2,
      grey_hydrogen: 1.96,
    },
    {
      date: new Date('2024-09-09'),
      green_hydrogen: 6.56,
      blue_hydrogen: 3.05,
      grey_hydrogen: 1.62,
    },
    {
      date: new Date('2024-09-10'),
      green_hydrogen: 6.41,
      blue_hydrogen: 3.38,
      grey_hydrogen: 1.99,
    },
    {
      date: new Date('2024-09-11'),
      green_hydrogen: 6.39,
      blue_hydrogen: 3.02,
      grey_hydrogen: 1.71,
    },
    {
      date: new Date('2024-09-12'),
      green_hydrogen: 6.27,
      blue_hydrogen: 3.02,
      grey_hydrogen: 1.77,
    },
    {
      date: new Date('2024-09-13'),
      green_hydrogen: 6.45,
      blue_hydrogen: 3.37,
      grey_hydrogen: 1.92,
    },
    {
      date: new Date('2024-09-14'),
      green_hydrogen: 6.36,
      blue_hydrogen: 3.12,
      grey_hydrogen: 1.91,
    },
    {
      date: new Date('2024-09-15'),
      green_hydrogen: 6.76,
      blue_hydrogen: 3.36,
      grey_hydrogen: 1.69,
    },
    {
      date: new Date('2024-09-16'),
      green_hydrogen: 6.3,
      blue_hydrogen: 3.3,
      grey_hydrogen: 1.78,
    },
    {
      date: new Date('2024-09-17'),
      green_hydrogen: 6.72,
      blue_hydrogen: 3.04,
      grey_hydrogen: 1.94,
    },
    {
      date: new Date('2024-09-18'),
      green_hydrogen: 6.64,
      blue_hydrogen: 3.06,
      grey_hydrogen: 1.9,
    },
    {
      date: new Date('2024-09-19'),
      green_hydrogen: 6.39,
      blue_hydrogen: 3.09,
      grey_hydrogen: 1.83,
    },
    {
      date: new Date('2024-09-20'),
      green_hydrogen: 6.28,
      blue_hydrogen: 3.22,
      grey_hydrogen: 1.82,
    },
    {
      date: new Date('2024-09-21'),
      green_hydrogen: 6.7,
      blue_hydrogen: 3.25,
      grey_hydrogen: 1.71,
    },
    {
      date: new Date('2024-09-22'),
      green_hydrogen: 6.56,
      blue_hydrogen: 3.11,
      grey_hydrogen: 1.91,
    },
    {
      date: new Date('2024-09-23'),
      green_hydrogen: 6.43,
      blue_hydrogen: 3.34,
      grey_hydrogen: 1.82,
    },
    {
      date: new Date('2024-09-24'),
      green_hydrogen: 6.57,
      blue_hydrogen: 3.18,
      grey_hydrogen: 1.84,
    },
    {
      date: new Date('2024-09-25'),
      green_hydrogen: 6.41,
      blue_hydrogen: 3.28,
      grey_hydrogen: 1.68,
    },
    {
      date: new Date('2024-09-26'),
      green_hydrogen: 6.74,
      blue_hydrogen: 3.32,
      grey_hydrogen: 1.63,
    },
    {
      date: new Date('2024-09-27'),
      green_hydrogen: 6.44,
      blue_hydrogen: 3.25,
      grey_hydrogen: 1.84,
    },
    {
      date: new Date('2024-09-28'),
      green_hydrogen: 6.61,
      blue_hydrogen: 3.37,
      grey_hydrogen: 1.99,
    },
    {
      date: new Date('2024-09-29'),
      green_hydrogen: 6.32,
      blue_hydrogen: 3.31,
      grey_hydrogen: 1.94,
    },
    {
      date: new Date('2024-09-30'),
      green_hydrogen: 6.8,
      blue_hydrogen: 3.03,
      grey_hydrogen: 1.79,
    },
    {
      date: new Date('2024-10-01'),
      green_hydrogen: 6.27,
      blue_hydrogen: 3.11,
      grey_hydrogen: 1.9,
    },
    {
      date: new Date('2024-10-02'),
      green_hydrogen: 6.41,
      blue_hydrogen: 3.09,
      grey_hydrogen: 1.93,
    },
    {
      date: new Date('2024-10-03'),
      green_hydrogen: 6.4,
      blue_hydrogen: 3.08,
      grey_hydrogen: 1.87,
    },
  ]
}
