import { Input } from 'src/components/ui/input'
import { Button } from 'src/components/ui/button'
import * as React from 'react'
import { useUserStore } from 'src/_store/user.store'
import { Controller, useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { z, ZodType } from 'zod'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import axios, { AxiosError } from 'axios'
import { toast } from 'src/components/ui/use-toast'
import { IUser } from 'src/_models/user.model'
import ModalProfilePicture from 'src/components/common/modalProfilePicture'
import { set } from 'date-fns'

type IProfilePersonalInformationForm = {
  firstName: string
  lastName: string
  email: string
  // companyName: string
  // companyNumber: string
}
// todo: ratnam, isloading of mutation function not used since there are two mutations but only one isloading
const ProfilePersonalInformation = ({ user }: { user: IUser }) => {
  const [showUploadPicture, setShowUploadPicture] = React.useState<boolean>(false)
  const { t } = useTranslation('translation')

  const queryClient = useQueryClient()
  console.log(user)

  const uploadProfilePictureMutation = useMutation({
    mutationKey: ['uploadProfilePictureMutation'],
    mutationFn: (data: { file: File }) => {
      const formData = new FormData()
      formData.append('file', data.file)
      const token = localStorage.getItem('token')
      return axios.put('/api/profile/update-profile-pic', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const onFileSelection = (file: File) => {
    setShowUploadPicture(false)

    uploadProfilePictureMutation.mutate(
      { file: file },
      {
        onSuccess: (resp) => {
          toast({
            title: 'Image uploaded.',
          })
          queryClient.invalidateQueries()
        },
        onError: (err) => {
          const error = err as AxiosError<{ message: string }>
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description: t(error.response?.data?.message || error.message || 'err.somethingWrong'),
          })
        },
      },
    )
  }

  const removeProfilePictureMutation = useMutation(
    () => {
      const token = localStorage.getItem('token')
      return axios.delete('/api/profile/remove-profile-pic', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
    {
      onSuccess: () => {
        toast({
          title: 'Profile picture removed.',
        })
        queryClient.invalidateQueries()
      },
      onError: (err: AxiosError<{ message: string }, any>) => {
        const error = err as AxiosError<{ message: string }>
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: error.response?.data?.message || error.message || 'err.somethingWrong',
        })
      },
    },
  )

  const removeProfilePicture = () => {
    removeProfilePictureMutation.mutate()
  }

  const profilePersonalInformationFormZodType: ZodType<IProfilePersonalInformationForm> = z.object({
    firstName: z.string({ required_error: t('error.firstNameRequired') }).min(1, t('error.firstNameRequired')),
    lastName: z.string({ required_error: t('error.lastNameRequired') }).min(1, t('error.lastNameRequired')),
    email: z
      .string({ required_error: t('error.emailRequired') })
      .min(1, t('error.emailRequired'))
      .email(t('error.emailInvalid')),
    // companyName: z.string({ required_error: t('error.required') }).min(1, t('error.required')),
    // companyNumber: z.string({ required_error: t('error.required') }).min(1, t('error.required')),
  })

  const { control, reset, handleSubmit } = useForm<IProfilePersonalInformationForm>({
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      // companyName: user?.companyName || '',
      // companyNumber: user?.companyNumber || '',
    },
    resolver: zodResolver(profilePersonalInformationFormZodType),
  })

  useEffect(() => {
    if (user) {
      reset({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        // companyName: user.companyName || '',
        // companyNumber: user.companyNumber || '',
      })
    }
  }, [user])

  const updateProfileInformationMutation = useMutation({
    mutationKey: ['updateProfileInformationMutation'],
    mutationFn: (data: IProfilePersonalInformationForm) => {
      const token = localStorage.getItem('token')
      return axios.put(
        '/api/profile/personal-information',
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })

  const updateProfileInformation = (data: IProfilePersonalInformationForm) => {
    updateProfileInformationMutation.mutate(data, {
      onSuccess: () => {
        toast({
          title: 'Updated personal information.',
        })
        queryClient.invalidateQueries()
      },
      onError: (e) => {
        const err = e as AxiosError<{ message: string }>
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: err.response ? t(err.response.data.message) : 'Failed to update Personal Information.',
        })
      },
    })
  }

  return (
    <>
      <div className="flex w-full">
        <div className="mx-10 w-full max-w-[500px] 2xl:mx-20 2xl:max-w-[600px]">
          <div className="flex w-full flex-col gap-[30px]">
            <div className="flex flex-col gap-[10px]">
              <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">First Name</label>
              <Controller
                name="firstName"
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    type="text"
                    // className="text-sm[appearance:textfield] border-1 border border-primary px-4 py-[15px] text-left focus:outline-0 focus:ring-0"
                    {...field}
                    error={fieldState.error}
                  />
                )}
              />
            </div>
            <div className="flex flex-col gap-[10px]">
              <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">Last Name</label>
              <Controller
                name="lastName"
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    type="text"
                    // className="text-sm[appearance:textfield] border-1 border border-primary px-4 py-[15px] text-left focus:outline-0 focus:ring-0"
                    {...field}
                    error={fieldState.error}
                  />
                )}
              />
            </div>

            <div className="flex flex-col gap-[10px]">
              <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">Email ID</label>
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    readOnly={true}
                    type="email"
                    // className="text-sm[appearance:textfield] border-1 border border-primary px-4 py-[15px] text-left focus:outline-0 focus:ring-0"
                    {...field}
                    error={fieldState.error}
                  />
                )}
              />
            </div>

            <Button className="w-fit px-[82px] py-[16px]" onClick={handleSubmit(updateProfileInformation)}>
              Update Details
            </Button>
          </div>
        </div>

        <div className="w-full max-w-[417px] 2xl:max-w-[417px]">
          <div className="flex flex-col rounded-[20px] bg-white p-[30px] ">
            <div className="mb-5 text-sm font-bold 2xl:mb-[30px] 2xl:text-base">Edit Profile Picture</div>
            <div className="mb-5 rounded-[20px] border-[3px] border-[#F2F2F2] p-10 shadow-[0px_4px_8px_0px_rgba(0,0,0,0.06),0px_0px_4px_0px_rgba(0,0,0,0.04)]">
              <div className="relative mx-auto aspect-square text-center xl:w-[140px] 2xl:w-[160px]">
                {/* todo: get actual profile image */}
                {user && user.profilePicUrl ? (
                  <img src={user.profilePicUrl} className="block h-auto object-cover" />
                ) : (
                  <div className="flex items-center justify-center bg-gray-200 font-bold text-xl rounded-full text-center w-full h-full">
                    {user?.firstName?.substring(0, 1).toUpperCase()} {user?.lastName?.substring(0, 1).toUpperCase()}
                  </div>
                )}
              </div>
            </div>

            <div className="flex xl:mb-10 xl:gap-[10px] 2xl:mb-[30px]">
              <Button
                onClick={() => {
                  user?.profilePicUrl
                    ? removeProfilePicture()
                    : toast({
                        variant: 'destructive',
                        title: 'Invalid Action!',
                        description: 'No profile picture to remove.',
                      })
                }}
                disabled={uploadProfilePictureMutation.isLoading || removeProfilePictureMutation.isLoading}
                className="w-full bg-white text-center  text-secoundary  ring-2 ring-primary hover:text-white focus:outline-none focus:ring-orange active:bg-[#cce4f0] active:text-white active:ring-2 active:ring-orange 2xl:py-4"
              >
                Remove Image
              </Button>
              <Button
                className="w-full text-center focus:bg-primary 2xl:py-4"
                onClick={() => setShowUploadPicture(true)}
                disabled={uploadProfilePictureMutation.isLoading || removeProfilePictureMutation.isLoading}
              >
                Upload New Profile Picture
              </Button>
            </div>
          </div>
        </div>
      </div>
      {showUploadPicture && (
        <ModalProfilePicture
          onCancel={() => setShowUploadPicture(false)}
          onFileSelection={(file) => {
            onFileSelection(file)
            setShowUploadPicture(false)
          }}
        ></ModalProfilePicture>
      )}
    </>
  )
}

export default ProfilePersonalInformation
