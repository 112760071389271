import { Dispatch, SetStateAction } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { Button } from 'src/components/ui/button'
import { DialogClose } from 'src/components/ui/dialog'
import { IOrder } from 'src/_models/order.model'
import OrderDocumentStatus from 'src/components/order/document-status'
import OrderSignatureGuaranteeView from 'src/components/order/signature-guarantee-view'
import TotalPayableAmount from 'src/components/order/total-payable-amount'
import OrderSignatureCompletedView from 'src/components/order/signature-completed-view'

const BuyerOrderCompleted = ({ goNext, order }: { goNext?: () => void; order: IOrder }) => {
  return (
    <>
      <div className="flex max-h-[calc(90vh-538px)] flex-col  overflow-y-scroll px-[30px] ">
        <OrderDocumentStatus order={order} />
        <div className="mt-5 flex flex-row justify-between">
          <OrderSignatureCompletedView
            order={order}
            // shouldnt this be BUYER_DELIVERY_CONFIRMATION?
            type="SUPPLIER_DELIVERY_CONFIRMATION"
            title="Delivery Confirmation Sign."
            orderGuaranteeText={order.supplierDeliveryConfirmationText}
            orderGuaranteeMediaKey={order.supplierDeliveryConfirmationMedia?.fileKey}
          />
          <OrderSignatureCompletedView
            order={order}
            // shouldnt this be BUYER_PRODUCT_CONFIRMATION?
            type="SUPPLIER_PRODUCT_CONFIRMATION"
            title="Product Confirmation Sign."
            orderGuaranteeText={order.supplierProductConfirmationText}
            orderGuaranteeMediaKey={order.supplierProductConfirmationMedia?.fileKey}
          />
        </div>
      </div>
      <div className="max-h-40 px-[30px]">
        <hr className=" mt-[10px] h-px border-0 bg-[#E0E0E0]" />
        <TotalPayableAmount order={order} />
        <div className="flex gap-5">
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <Button disabled>Order Completed</Button>
        </div>
      </div>
    </>
  )
}

export default BuyerOrderCompleted
