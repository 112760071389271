import { Controller, useForm } from 'react-hook-form'
import { Input } from 'src/components/ui/input'
import { InputPassword } from 'src/components/ui/password-input'
import { Button } from 'src/components/ui/button'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { z, ZodType } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { toast } from 'src/components/ui/use-toast'
import { IUser } from 'src/_models/user.model'
import { useUserStore } from 'src/_store/user.store'
import { IUserAddress } from 'src/_models/shipping-detail.model'
import InputGoogleAutoComplete from 'src/components/ui/input-google-auto-complete'

type IAddressForm = {
  label: string
  streetAddress: string
  googleAutocompleteAddress: string
  googleAutocompletePlaceId: string
  latitude: string
  longitude: string
  state: string
  country: string
  city: string
}

export default function ShippingDetails({ goNext, goPrev }: { goNext: () => void; goPrev: () => void }) {
  const { t } = useTranslation('translation')
  const [queryParameters] = useSearchParams()

  const { setUser } = useUserStore((state) => {
    return {
      setUser: state.setUser,
    }
  })

  const [address, setAddress] = useState<string>()

  const { isLoading, isError, data } = useQuery({
    queryKey: ['user-addresses'],
    queryFn: (): Promise<AxiosResponse<{ userAddresses: IUserAddress[] }>> => {
      const token = localStorage.getItem('token')
      return axios.get('/api/user-addresses', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
    retryOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    // onSuccess: () => {
    //   // Invalidate and refetch
    //   queryClient.invalidateQueries({ queryKey: ['todos'] })
    // },
  })

  const userAddressesSchema: ZodType<IAddressForm> = z.object({
    label: z.string({ required_error: 'required' }).min(1, { message: 'required' }),
    streetAddress: z.string({ required_error: 'required' }),
    googleAutocompleteAddress: z.string({ required_error: 'required' }),
    googleAutocompletePlaceId: z.string({ required_error: 'required' }),
    latitude: z.string({ required_error: 'required' }),
    longitude: z.string({ required_error: 'required' }),
    state: z.string({ required_error: 'required' }),
    country: z.string({ required_error: 'required' }),
    city: z.string({ required_error: 'required' }),
  })

  const { control, handleSubmit, setValue, setError } = useForm<IAddressForm>({
    resolver: zodResolver(userAddressesSchema), // Apply the zodResolver
  })

  useEffect(() => {
    if (data?.data && data?.data.userAddresses.length > 0) {
      const userAddress = data.data.userAddresses[0]
      setValue('label', userAddress.label)
      setValue('streetAddress', userAddress.streetAddress)
      setValue('googleAutocompleteAddress', userAddress.googleAutocompleteAddress)
      setValue('googleAutocompletePlaceId', userAddress.googleAutocompletePlaceId)
      setValue('latitude', userAddress.latitude)
      setValue('longitude', userAddress.longitude)
      setValue('state', userAddress.state)
      setValue('country', userAddress.country)
      setValue('city', userAddress.city ? userAddress.city : '')
    }
  }, [data])

  const userAddressesMutation = useMutation({
    mutationFn: (formData: IAddressForm): Promise<AxiosResponse<{ token: string; user: IUser }>> => {
      const token = localStorage.getItem('token')
      if (data?.data && data?.data.userAddresses.length > 0) {
        return axios.patch(
          `/api/user-addresses/${data?.data.userAddresses[0].id}`,
          { ...formData, step: 'DOCUMENT_UPLOAD' },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
      } else {
        return axios.post(
          '/api/user-addresses',
          { ...formData, step: 'DOCUMENT_UPLOAD' },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
      }
    },
    onSuccess: () => {
      goNext()
    },
  })

  const saveShippingDetailInformation = (data: IAddressForm) => {
    if (
      address !== data.googleAutocompleteAddress &&
      data.googleAutocompleteAddress !== data?.googleAutocompleteAddress
    ) {
      setError('googleAutocompleteAddress', { message: 'Please select from autocomplete.' })
      return
    }
    userAddressesMutation.mutate(data, {
      onSuccess: (resp) => {
        setUser({ ...resp.data.user })
        goNext()
        queryParameters.set('step', '3')
      },
      onError: (err) => {
        const error = err as AxiosError<{ message: string }>
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: t(error.response?.data?.message as string),
        })
      },
    })
  }
  const onLatLngChange = (lat: string, lng: string) => {
    setValue('latitude', lat)
    setValue('longitude', lng)
  }

  const onAddressComponentsChange = ({
    state,
    country,
    city,
    googleAutocompletePlaceId,
    pincode,
  }: {
    state: string
    country: string
    city: string
    googleAutocompletePlaceId: string
    pincode: string
  }) => {
    setValue('state', state)
    setValue('country', country)
    setValue('city', city)
    setValue('googleAutocompletePlaceId', googleAutocompletePlaceId)
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(saveShippingDetailInformation, (err) => {
          toast({
            variant: 'destructive',
            title: 'Please check the form and try again.',
          })
        })}
        noValidate={true}
      >
        <div>
          <h1 className="pb-10 text-2xl font-bold text-txtblack ">Address Details</h1>
          <div className="relative pb-5">
            <label htmlFor="shippingAddress" className="block text-sm font-bold leading-6 text-txtblack">
              Label
            </label>
            <div className="mt-2">
              <Controller
                control={control}
                name="label"
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <Input
                        type="text"
                        id="label"
                        placeholder="Home, Office, etc."
                        {...field}
                        error={fieldState.error}
                      />
                    </>
                  )
                }}
              />
            </div>
          </div>
          <div className="relative pb-5">
            <label htmlFor="shippingAddress" className="block text-sm font-bold leading-6 text-txtblack">
              {t('supplierShippingDetails.address')}
            </label>
            <div className="mt-2">
              <Controller
                control={control}
                name="googleAutocompleteAddress"
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <InputGoogleAutoComplete
                        onLatLngChange={onLatLngChange}
                        onAddressComponentsChange={onAddressComponentsChange}
                        getAddressValue={setAddress}
                        id="shippingAddress"
                        // placeholder="test@regen.io"
                        {...field}
                        error={fieldState.error}
                      />
                    </>
                  )
                }}
              />
            </div>
          </div>
          <div className="relative pb-5">
            <label htmlFor="shippingAddress" className="block text-sm font-bold leading-6 text-txtblack">
              Unit No. / Office No. / Suite No.
            </label>
            <div className="mt-2">
              <Controller
                control={control}
                name="streetAddress"
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <Input
                        type="text"
                        id="label"
                        placeholder="XYZ Street, 123, ABC"
                        {...field}
                        error={fieldState.error}
                      />
                    </>
                  )
                }}
              />
            </div>
          </div>
        </div>
      </form>
      <div className="flex gap-4 pb-5 pt-10">
        <Button
          expand="full"
          disabled={userAddressesMutation.isLoading}
          type="button"
          variant="secondary"
          onClick={goPrev}
        >
          {t('back')}
        </Button>

        <Button
          type="submit"
          onClick={handleSubmit(saveShippingDetailInformation, (err) => {
            toast({
              variant: 'destructive',
              title: 'Please check the form and try again.',
            })
          })}
          disabled={userAddressesMutation.isLoading}
          expand="full"
        >
          {t('next')}
        </Button>
      </div>
    </>
  )
}
