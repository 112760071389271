import { IOrder } from 'src/_models/order.model'
import { useUserStore } from 'src/_store/user.store'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useEffect, useState } from 'react'
import OrderSignatureGuaranteeFileView from 'src/components/order/order-signature-guarantee-file-view'

const OrderSignatureCompletedView = ({
  title,
  order,
  type,
  orderGuaranteeText,
  orderGuaranteeMediaKey,
}: {
  title: string
  order: IOrder
  type: 'BUYER' | 'SUPPLIER' | 'SUPPLIER_DELIVERY_CONFIRMATION' | 'SUPPLIER_PRODUCT_CONFIRMATION'
  orderGuaranteeText: string | null
  orderGuaranteeMediaKey?: string
}) => {
  const valueExists = orderGuaranteeText || orderGuaranteeMediaKey

  if (valueExists) {
    return (
      <div className="mb-[10px] flex flex-col items-center justify-between pl-[0px] align-middle">
        <div className="mb-[10px]">
          <div className="text-xl font-bold 2xl:text-xl">{title}</div>
        </div>
        <div className="rounded-[10px] bg-[#F2F2F2] p-[15px]">
          {orderGuaranteeText && (
            <div className="flex h-[100px] w-[260px] items-center justify-center">{orderGuaranteeText}</div>
          )}
          {orderGuaranteeMediaKey && (
            <div className="relative h-[100px] w-[260px]">
              <OrderSignatureGuaranteeFileView fileType={type} orderId={order.id} />
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="mb-[10px] flex flex-col items-center justify-between pl-[0px] align-middle">
      <div className="mb-[10px]">
        <div className="text-xl font-bold 2xl:text-xl">{title}</div>
      </div>
      <div className="rounded-[10px] bg-[#F2F2F2] p-[15px]">
        <div className="flex h-[100px] w-[260px] gap-[10px] rounded-[10px] bg-[#F2F2F2]"></div>
      </div>
    </div>
  )
}

export default OrderSignatureCompletedView
