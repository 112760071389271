import React, { ReactNode, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useUserStore } from 'src/_store/user.store'
import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { IUser } from 'src/_models/user.model'
import { Skeleton } from 'src/components/ui/skeleton'
const NotProtected = ({ children, loadingComponent }: { children: ReactNode; loadingComponent?: ReactNode }) => {
  const navigate = useNavigate()
  const { user, setUser } = useUserStore((state) => {
    return {
      user: state.user,
      setUser: state.setUser,
    }
  })

  // const { isLoading, isError, data } = useQuery({
  //   queryKey: ['profile'],
  //   queryFn: (): Promise<AxiosResponse<IUser>> => {
  //     const token = localStorage.getItem('token')
  //     return axios.get('/api/auth/profile', {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //   },
  //   refetchOnMount: false,
  //   retry: false,
  //   refetchOnWindowFocus: false,
  //   refetchOnReconnect: false,
  // })
  //
  // useEffect(() => {
  //   if (!data) {
  //     return
  //   }
  //   const user = data.data
  //   if (data.data.step === 'OTP_VERIFICATION') {
  //     navigate(`/auth/signup?role=${user.userType}&step=${1}`)
  //   } else if (user.step === 'ACCOUNT_SETUP') {
  //     navigate(`/auth/signup?role=${user.userType}&step=${2}`)
  //   } else if (user.step === 'DOCUMENT_UPLOAD') {
  //     navigate(`/auth/signup?role=${user.userType}&step=${3}`)
  //   } else {
  //     navigate('/')
  //   }
  // }, [data])

  // if (isLoading) {
  //   return <>{loadingComponent ? loadingComponent : <Skeleton className="h-20 w-full rounded-full" />}</>
  // }

  return children
}
export default NotProtected
