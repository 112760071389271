import { useUserStore } from 'src/_store/user.store'
import SupplierAuctionDialogPurchaseNow from 'src/components/auction/supplier-auction-dialog-purchase-now'
import { Dispatch, SetStateAction } from 'react'
import SupplierAuctionDialog from 'src/components/auction/supplier-auction-dialog'
import BuyerAuctionDialog from 'src/components/auction/buyer-auction-dialog'
import { IRecentUpdateAuction } from 'src/_models/recent-update.model'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { IAuction } from 'src/_models/auction.model'
import { Skeleton } from 'src/components/ui/skeleton'

const AuctionDialog = ({
  auction,
  setShowDialog,
}: {
  auction: IRecentUpdateAuction
  setShowDialog: Dispatch<SetStateAction<boolean>>
}) => {
  const { user } = useUserStore((state) => ({
    user: state.user,
  }))

  const isBuyer = user?.userType === 'BUYER'

  const buyerAuctionQuery = useQuery({
    queryKey: [`buyerAuction-${auction.id}-${new Date()}`],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get<{ auction: IAuction }>(`/api/buyer/auctions/${auction.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
    enabled: isBuyer,
  })

  const supplierAuctionQuery = useQuery({
    queryKey: [`supplierAuction-${auction.id}-${new Date()}`],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get<{ auction: IAuction }>(`/api/supplier/auctions/${auction.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
    enabled: !isBuyer,
  })

  console.log('isBuyer', isBuyer)

  if (isBuyer && buyerAuctionQuery.data?.data.auction) {
    return <BuyerAuctionDialog auction={buyerAuctionQuery.data?.data.auction} setShowDialog={setShowDialog} />
  } else if (!isBuyer && supplierAuctionQuery.data?.data.auction) {
    return <SupplierAuctionDialog auction={supplierAuctionQuery.data?.data.auction} setShowDialog={setShowDialog} />
  } else {
    return (
      <>
        <Skeleton className="h-[200px] w-full" />
      </>
    )
  }
}

export default AuctionDialog
