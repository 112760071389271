import { DataTable } from 'src/components/data-table'
import * as React from 'react'
import { IOrder, ISupplierOrder } from 'src/_models/order.model'
import { ColumnDef } from '@tanstack/react-table'
import { formatAuctionDate } from 'src/lib/format-auction'
import currencyFormatter from 'src/components/ui/currency-formatter'
import { useTranslation } from 'react-i18next'

const DashboardOrdersTable = ({ heading, url, orders }: { heading: string; url: string; orders: IOrder[] }) => {
  const { t } = useTranslation('translation')
  const getBidAmount = (order: ISupplierOrder) => {
    return order.auction.auctionType === 'AUCTION'
      ? order.winningBid?.bidAmount || 0
      : order.auction.fuelWeight * order.auction.sellingPricePerUnit
  }

  const columns: ColumnDef<IOrder>[] = [
    {
      accessorKey: 'date',
      header: 'Date',
      cell: ({ row }) => {
        return <div className="text-center">{formatAuctionDate(row.original.createdAt)}</div>
      },
    },
    {
      accessorKey: 'headline',
      header: 'Headline',
      cell: ({ row }) => {
        return (
          <div className="text-center">
            {row.original.auction.fuelColor.name} {row.original.auction.fuelType.name} -{' '}
            {row.original.auction.fuelPurityLevel.purity}
          </div>
        )
      },
    },
    {
      accessorKey: 'weight',
      header: 'Weight',
      cell: ({ row }) => {
        return (
          <div className="whitespace-nowrap text-center">
            {row.original.auction.fuelWeight} {t('fuelWeightUnit.' + row.original.auction.fuelWeightUnit)}
          </div>
        )
      },
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row }) => {
        return <div className="text-center">{t('fuelTradingStatus.' + row.original.status)}</div>
      },
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      cell: ({ row }) => {
        return (
          <div className="whitespace-nowrap text-center">
            {currencyFormatter({
              currency: row.original.auction.sellingCurrency,
              amount: getBidAmount(row.original),
              cents: true,
            })}
          </div>
        )
      },
    },
  ]

  return (
    <>
      <div className="mb-[15px] flex w-full items-center justify-between">
        <h2 className="text-lg font-bold text-secoundary 2xl:text-2xl">{heading}</h2>
        <a href={url} className="text-sm font-bold text-primary">
          View All
        </a>
      </div>
      <DataTable columns={columns} data={orders.splice(0, 6)} headerHidden={true} />
    </>
  )
}

export default DashboardOrdersTable
