import { Select, SelectContent, SelectItem, SelectTrigger } from 'src/components/ui/select'
import {useMutation, useQuery} from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { cn } from 'src/lib/utils'
import { useUserStore } from 'src/_store/user.store'
import {useEffect, useState} from "react";
import {Popover, PopoverContent, PopoverTrigger} from "src/components/ui/popover";
import {Button} from "src/components/ui/button";
import {Checkbox} from "src/components/ui/checkbox";

const StatesFilter = ({ className, filterType }: { className?: string; filterType: 'FUEL_TRADING' | 'REC' | 'GREEN_TRACING' }) => {
  const [statesFilter, setStatesFilter] = useState<string[]>([]);
  const { user, setUser } = useUserStore((state) => ({
    user: state.user,
    setUser: state.setUser,
  }))

  const getValue = (): string[] => {
    if(!user) {
      return [];
    }
    switch (filterType) {
      case 'FUEL_TRADING':
        return user.fuelTradingStatePreference
      case 'REC':
        return user?.recStatePreference
      case 'GREEN_TRACING':
        return user?.greenTracingStatePreference
    }
  }

  useEffect(() => {
    if(!user) return
    setStatesFilter(getValue())
    stateMutation.mutate({ fuelTradingStatePreference: user.fuelTradingStatePreference, recStatePreference: user.recStatePreference, greenTracingStatePreference: user.greenTracingStatePreference }, {
      onSuccess: () => {
        // TODO: if need to do anything
      },
      onError: () => {
        // TODO: if need to do anything
      }
    })
  }, [user?.fuelTradingStatePreference, user?.recStatePreference, user?.greenTracingStatePreference]);

  const { isLoading, isError, data } = useQuery({
    queryKey: ['states-filter'],
    queryFn: (): Promise<AxiosResponse<{ states: string[] }>> => {
      const token = localStorage.getItem('token')
      return axios.get('/api/states-filter', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const stateMutation = useMutation({
    mutationFn: ({recStatePreference, fuelTradingStatePreference, greenTracingStatePreference}: { recStatePreference: string[]; fuelTradingStatePreference: string[]; greenTracingStatePreference: string[]}) => {
      const token = localStorage.getItem('token')
      return axios.post('/api/states-filter', {
        recStatePreference,
        fuelTradingStatePreference,
        greenTracingStatePreference,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    }
  })

  const onFilterChange = (value: string) => {
    if(!user) return
    const isChecked = statesFilter.indexOf(value) !== -1
    const newStatesFilter = isChecked ? [...statesFilter.filter((s) => s !== value)] : [...statesFilter, value]
    setStatesFilter(newStatesFilter)
    switch (filterType) {
      case 'FUEL_TRADING':
        setUser( {
          ...user,
          fuelTradingStatePreference: newStatesFilter,
        })
        break;
      case 'REC':
        setUser({
          ...user,
          recStatePreference: newStatesFilter,
        })
        break;
      case 'GREEN_TRACING':
        setUser({
          ...user,
          greenTracingStatePreference: newStatesFilter,
        })
        break;
    }
  }

  const resetFilter = () => {
    if(!user) {
      return;
    }
    setStatesFilter([])
    switch (filterType) {
      case 'FUEL_TRADING':
        setUser( {
          ...user,
          fuelTradingStatePreference: [],
        })
        break;
      case 'REC':
        setUser({
          ...user,
          recStatePreference: [],
        })
        break;
      case 'GREEN_TRACING':
        setUser({
          ...user,
          greenTracingStatePreference: [],
        })
        break;
    }
  }

  return (
    <>
      <Popover>
        <PopoverTrigger asChild>
          <Button variant="secondary" className="w-28">
            Filter&nbsp;By
          </Button>
        </PopoverTrigger>
        <PopoverContent align="end" className="w-[800px] rounded-[20px]">
          <label className="text-sm font-bold">States</label>
          <div className="grid grid-cols-3 gap-2.5 max-h-80 overflow-y-scroll p-2">
            {data?.data && data.data.states.map((state) => (
              <label key={state} className="flex items-center gap-2">
                <Checkbox className="w-6 h-6" checked={statesFilter.indexOf(state) !== -1} onCheckedChange={() => onFilterChange(state)}
                          value={state}/> <span className="w-52">{state}</span>
              </label>
            ))}
          </div>
          <Button variant="secondary" className="mt-2" onClick={resetFilter}>Reset Filter</Button>
        </PopoverContent>
      </Popover>
    </>
  )
}

export default StatesFilter
