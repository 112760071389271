import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button } from '../ui/button'

export default function ModalBox({ onCancel }: { onCancel: () => void }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <>
      <div
        data-te-modal-init
        className="fixed left-0  top-0 z-[1055] h-full w-full overflow-y-auto overflow-x-hidden bg-black/50 outline-none backdrop-blur-md"
        id="exampleModalCenter"
        aria-labelledby="exampleModalCenterTitle"
        aria-modal="true"
        role="dialog"
      >
        <div
          data-te-modal-dialog-ref
          className="pointer-events-none  relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px]"
        >
          <div className="pointer-events-auto relative flex w-full flex-col rounded-[20px] border-none bg-white bg-clip-padding text-current shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] outline-none">
            <div className="flex flex-shrink-0 items-center justify-between rounded-t-md p-4 ">
              <button
                type="button"
                className=" -top-4.5 absolute -right-[4.5rem] box-content rounded-[8px] border-none bg-white px-4 py-2 text-xs capitalize hover:no-underline focus:opacity-100 focus:shadow-none focus:outline-none"
                data-te-modal-dismiss
                aria-label="Close"
                onClick={onCancel}
              >
                close
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  className="h-8 w-8"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg> */}
              </button>
            </div>
            <div className="w-full px-10 pb-[60px] pt-5">
              <h2 className="pb-10 text-2xl font-bold text-txtblack">Signup as</h2>
              <div className="flex gap-4">
                <Button onClick={() => navigate(`/auth/signup?role=BUYER`)} type="button">
                  Buyer
                </Button>
                <Button onClick={() => navigate(`/auth/signup?role=SUPPLIER`)} type="button">
                  Supplier
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
