import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useUserStore } from 'src/_store/user.store'
import { IOrder, ISupplierOrder } from 'src/_models/order.model'
import { useTranslation } from 'react-i18next'
import OrderDetailTables from 'src/pages/fuel-trading/order-detail-tables'
import { useSearchParams } from 'react-router-dom'

const FuelTradingOrdersInProgressPage = () => {
  const [orders, setOrders] = useState<IOrder[]>([])
  const [searchParams, _] = useSearchParams()

  const params = searchParams.toString()

  const orderInProgressQuery = useQuery({
    queryKey: ['orders', 'orders/in-progress', params],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get<{ orders: ISupplierOrder[] }>(`/api/orders/in-progress?${params}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  useEffect(() => {
    if (orderInProgressQuery.data) {
      setOrders(orderInProgressQuery.data.data.orders)
    }
  }, [orderInProgressQuery.data])

  return (
    <>
      <section className="w-full">
        <OrderDetailTables heading="Orders in Progress" orders={orders} />
      </section>
    </>
  )
}

export default FuelTradingOrdersInProgressPage
