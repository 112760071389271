import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      header: {
        myDashboard: 'My Dashboard',
        fuelTrading: 'Fuel Trading',
        myWatchlist: 'My Watchlist',
        liveAuctions: 'Live Auctions',
        myAuctions: 'My Auctions',
        ordersInProgress: 'Orders In Progress',
        completedOrders: 'Completed Orders',
        transactionHistory: 'Transaction History',
        pastAuctions: 'Past Auctions',
        greenTracing: 'Green Tracing',
        recTrading: 'REC Trading',
        searchPlaceholder: 'Search',
        myDashboardRec: 'My Dashboard',
        myWatchlistRec: 'My Watchlist',
        liveRecProjectsRec: 'Live REC Projects',
        projectPreviousRec: 'Project Previously Purchased From',
        allProjects: 'All Projects',
      },
      login: {
        loginNow: 'Login Now',
        emailLabel: 'Email Id',
        emailPlaceholder: 'test@regen.io',
        passwordLabel: 'Password',
        passwordPlaceholder: '********',
        loginBtn: 'Login',
        signUpBtn: 'Sign up',
        incorrectCredentialError: 'Email or password is incorrect*',
      },
      signUp: {
        verify: 'Verify Now',
        newPassword: 'New Password',
        confirmPassword: 'Confirm Password',
        firstName: 'First Name',
        lastName: 'Last Name',
      },
      signUpSteps: {},
      forgetPassword: {
        heading: 'Forget Password',
        sendVerificationCode: 'Send Forget password code',
        verifyOtp: 'You will receive a mail with verification code to change password',
        updatedPassword: 'Password updated.',
      },
      buyerDetails: {
        heading: 'Buyer Details',
      },
      supplierDetails: {
        heading: 'Supplier Details',
      },
      supplierShippingDetails: {
        heading: 'Shipping Details',
        address: 'Address',
        shippingMethods: 'Shipping Methods',
        shippingProvider: 'Shipping Provider',
        shippingRadius: 'Shipping Radius',
        shippingRadiusUnit: 'Shipping Radius Unit',
        shippingCurrency: 'Shipping Currency',
      },
      otpVerify: {
        heading: 'OTP Verification',
        goBack: 'Go Back',
        submitBtn: 'Submit',
      },
      accountSetup: {
        heading: 'Account Setup',
        companyNameLabel: 'Company Name',
        companyNamePlaceholder: 'Regen',
        companyNoLabel: 'EIN / Company Tax ID',
        companyNoPlaceholder: 'Regen',
      },
      documentUpload: {
        heading: 'Company Registration Documents',
        subHeading: 'These are the documents you have upload and/or need to upload.',
        countryLabel: 'Country',
        countryPlaceholder: '',
        stateLabel: 'State',
        statePlaceholder: '',
        cityLabel: 'City',
        cityPlaceholder: '',
      },
      auction: {
        newAuction: 'Create New Auction',
        newAuctionTitle: 'Create Auction',
        auctionNameLabel: 'Auction Name',
        fuelTypeLabel: 'Select Fuel type',
        auctionTypeLabel: 'Select Auction type',
        tradeTypeLabel: 'Select Trade type',
        purityLevelLabel: 'Purity Level',
        fuelColourLabel: 'Fuel Colour',
        fuelWeightLabel: 'Weight',
        fuelWeightUnitLabel: 'Weight Unit',
        sellingCurrencyLabel: 'Currency',
        sellingPricePerUnitLabel: 'Per unit Price',
        auctionEndDateLabel: 'Auction End Date',
        auctionEndTimeLabel: 'Auction End Time',
        insuranceProviderIdLabel: 'Insurance Provider',
        shippingRadiusLabel: 'Shipping Radius',
        userAddressIdLabel: 'Shipping address',
        confirmAuction: 'Preview and Confirmation',
        stateTaxPercentageLabel: 'State Tax Percentage',
        cityTaxPercentageLabel: 'City Tax Percentage',
        fuelFeesFixedLabel: 'Fuel Fees Fixed',
        fuelFeesPercentageLabel: 'Fuel Fees Percentage',
      },
      shippingProvider: {
        nameLabel: 'Shipping Provider Name',
        shippingModeLabel: 'Shipping Mode',
        tradeCurrencyLabel: 'Shipping currency',
        distanceUnitLabel: 'Unit distance',
        shippingCostPerUnitDistanceLabel: 'Shipping cost per unit distance',
      },
      error: {
        emailRequired: 'Email is required*',
        emailInvalid: 'Email is invalid*',
        passwordRequired: 'Password is required*',
        passwordShort: 'Password should at least be 8 characters*',
        passwordLong: 'Password should at max be 64 characters*',
        confirmPasswordRequired: 'Confirm Password is required*',
        passwordNotMatch: 'Passwords do not match*',
        somethingWrong: 'Something went wrong*',
        existingUser: 'Already account setup. Please login.',
        invalidVerificationCode: 'Invalid verification code.',
        userNotFound: 'User not found.',
        countryIdMissing: 'Country is required',
        stateIdMissing: 'State is required',
        companyNameRequired: 'Company Name is required',
        companyNumberRequired: 'EIN / Company Tax ID is required',
        companyAddressRequired: 'Company Address is required',
        companyContactDetailsRequired: 'Company Contact Details is required',
        companyAccreditationsRequired: 'Company Accreditations is required',
        required: 'Required*',
        invalid: 'Invalid data*',
        shouldBePositive: 'Should be greater than 0*',
        auctionEndDate: 'Auction end date should be greater than 5 days ahead',
        unauthorised: 'Unauthorised for request.',
        companyDocNotFound: 'Company Doc not found',
        firstNameRequired: 'First Name is required',
        lastNameRequired: 'Last Name is required',
        fuelTradingOrderNotFound: 'Order not found',
      },
      buyerSteppers: {
        step1: 'Document Upload',
        step2: 'Order Guarantee',
        step3: 'Buyer Payment',
        step4: 'Shipping Info',
        step5: 'Buyer Confirmation',
        step6: 'Final Payment',
      },
      supplierOrderSteps: {
        step1: 'Document Verification',
        step2: 'Order Guarantee',
        step3: 'Buyer Payment',
        step4: 'Shipping Info',
        step5: 'Buyer Confirmation',
        step6: 'Final Payment',
      },
      currency: {
        USD: '$',
        CAD: 'CA$',
        EUR: '€',
        GBP: '£',
        SGD: 'S$',
        MYR: 'MYR',
        AUD: 'AU$',
        SAR: 'SAR',
        INR: '₹',
        AED: 'AED',
        // USD: '$',
        // CAD: '$',
        // EUR: '$',
        // GBP: '$',
        // SGD: '$',
        // MYR: '$',
        // AUD: '$',
        // SAR: '$',
        // INR: '$',
        // AED: '$',
      },
      shippingMode: {
        SEA: 'Sea',
        LAND: 'Land',
      },
      shippingRadiusUnit: {
        KM: 'Km',
        MILE: 'Mile',
      },
      auctionType: {
        AUCTION: 'Auction',
        PURCHASE_NOW: 'Purchase Now',
      },
      tradeType: {
        SPOT: 'Spot',
        FUTURES: 'Futures',
      },
      fuelType: {
        HYDROGEN: 'Hydrogen',
        AMMONIA: 'Ammonia',
        METHANOL: 'Methanol',
      },
      fuelColor: {
        YELLOW: 'Yellow',
        GREEN: 'Green',
        BLUE: 'Blue',
        TURQUOISE: 'Turquoise',
        GREY: 'Grey',
        BROWN: 'Brown',
        BLACK: 'Black',
        WHITE: 'White',
        GOLD: 'Gold',
        PINK: 'Pink',
      },
      hydrogenFuelColour: {
        YELLOW: 'Yellow',
        GREEN: 'Green',
        BLUE: 'Blue',
        TURQUOISE: 'Turquoise',
        GREY: 'Grey',
        BROWN: 'Brown',
        BLACK: 'Black',
        WHITE: 'White',
        GOLD: 'Gold',
        PINK: 'Pink',
      },
      lineItemType: {
        BidAmount: 'Bid Amount',
        TaxAmount: 'Tax Amount',
        StateTaxAmount: 'State Tax Amount',
        CityTaxAmount: 'City Tax Amount',
        FuelFeesAmount: 'Fuel Fees Amount',
        BuyerFeesAmount: 'Buyer Fees Amount',
        ShippingCost: 'Shipping Cost',
        PartialBidAmount: '20% Bid Amount',
        PendingBidAmount: 'Final 80% Bid Amount',
        SupplierFeesAmount: 'Supplier Fees Amount',
      },
      fuelWeightUnit: {
        KG: 'Kg',
        TONNE: 't',
      },
      fuelTradingStatusold: {
        DOCUMENT_UPLOAD: 'DOCUMENT_UPLOAD',
        BUYER_ORDER_GUARANTEED: 'BUYER_ORDER_GUARANTEED',
        SELLER_ORDER_GUARANTEED: 'SELLER_ORDER_GUARANTEED',
        BUYER_PAYMENT_PENDING: 'BUYER_PAYMENT_PENDING',
        BUYER_PAYMENT_COMPLETED: 'BUYER_PAYMENT_COMPLETED',
        SHIPPING_CONTRACT_UPLOAD: 'SHIPPING_CONTRACT_UPLOAD',
        PRODUCT_SHIPPED: 'PRODUCT_SHIPPED',
        SELLER_PARTIAL_PAYMENT: 'SELLER_PARTIAL_PAYMENT',
        PRODUCT_DELIVERED: 'PRODUCT_DELIVERED',
        DELIVERY_CONFIRMATION: 'DELIVERY_CONFIRMATION',
        PRODUCT_CONFIRMATION: 'PRODUCT_CONFIRMATION',
        SELLER_REMAINDER_PAYMENT_PENDING: 'SELLER_REMAINDER_PAYMENT_PENDING',
        COMPLETED: 'COMPLETED',
      },
      // may have to split for buyer and seller
      fuelTradingStatus: {
        DOCUMENT_UPLOAD: 'Upload Purchase Contracts',
        BUYER_ORDER_GUARANTEED: 'Buyer Order Guaranteed',
        SELLER_ORDER_GUARANTEED: 'Seller Order Guaranteed',
        BUYER_PAYMENT_PENDING: 'Buyer Payment Pending',
        BUYER_PAYMENT_COMPLETED: 'Buyer Payment Completed',
        SHIPPING_CONTRACT_UPLOAD: 'Upload Shipping Contract',
        SHIPPING_PAID: 'Shipping Amount Paid',
        PRODUCT_SHIPPED: 'Product Shipped',
        SELLER_PARTIAL_PAYMENT: 'Partial Payment Sent',
        PRODUCT_DELIVERED: 'Product Delivered',
        DELIVERY_CONFIRMATION: 'Delivery Received',
        PRODUCT_CONFIRMATION: 'Product Verified',
        SELLER_REMAINDER_PAYMENT_PENDING: 'Final Payment Pending',
        COMPLETED: 'Completed',
      },
      userTransactionType: {
        FUEL_TRADING_BUYER_PAYMENT: 'Fuel Order Payment',
        FUEL_TRADING_BUYER_PAYMENT_FAILED: 'Fuel Order Payment Failed',
        FUEL_TRADING_BUYER_REFUND: 'Refund',
        FUEL_TRADING_BUYER_REFUND_FAILED: 'Refund Failed',
        FUEL_TRADING_SUPPLIER_PARTIAL_PAYOUT: 'First Tranche Payout (20%)',
        FUEL_TRADING_SUPPLIER_PARTIAL_PAYOUT_FAILED: 'First Tranche Payout (20%) Failed',
        FUEL_TRADING_BUYER_SHIPPING_PAYMENT: 'Shipping Payment',
        FUEL_TRADING_BUYER_SHIPPING_PAYMENT_FAILED: 'Shipping Payment Failed',
        FUEL_TRADING_SUPPLIER_SHIPPING_PAYOUT: 'Shipping Payment Received',
        FUEL_TRADING_SUPPLIER_FULL_PAYOUT: 'Final Tranche Payout (80%)',
        FUEL_TRADING_SUPPLIER_FULL_PAYOUT_FAILED: 'Final Tranche (80%) Failed',
      },
      required: 'Required*',
      back: 'Back',
      next: 'Next',
      cancel: 'Cancel',
      signUpAsBuyer: 'Signup as Buyer',
      signUpAsSeller: 'Signup as Seller',
    },
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
