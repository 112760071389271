import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useUserStore } from 'src/_store/user.store'
import { ISupplierOrder } from 'src/_models/order.model'
import OrderDetailTables from 'src/pages/fuel-trading/order-detail-tables'
import { useSearchParams } from 'react-router-dom'

const FuelTradingCompletedOrdersPage = () => {
  const [orders, setOrders] = useState<ISupplierOrder[]>([])
  const [searchParams, _] = useSearchParams()

  const params = searchParams.toString()

  const orderCompletedQuery = useQuery({
    queryKey: ['orders', 'orders/completed', params],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get<{ orders: ISupplierOrder[] }>(`/api/orders/completed?${params}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  useEffect(() => {
    if (orderCompletedQuery.data) {
      setOrders(orderCompletedQuery.data.data.orders)
    }
  }, [orderCompletedQuery.data])

  return (
    <>
      <section className="w-full">
        <OrderDetailTables heading="Orders Completed" orders={orders} />
      </section>
    </>
  )
}

export default FuelTradingCompletedOrdersPage
