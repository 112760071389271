import { IOrder } from 'src/_models/order.model'
import SignatureCanvas from 'react-signature-canvas'
import { Dispatch, RefObject, SetStateAction } from 'react'
import { Dialog, DialogContent, DialogTrigger } from 'src/components/ui/dialog'
import { Button } from 'src/components/ui/button'
import { useUserStore } from 'src/_store/user.store'
import { Input } from 'src/components/ui/input'
import ModalDocument from 'src/components/common/modalDocument'

const OrderSignatureGuarantee = ({
  canvasSignature,
  uploadMode,
  setUploadMode,
  text,
  setText,
  file,
  setFile,
  title,
  description,
  order,
  type,
}: {
  canvasSignature: RefObject<SignatureCanvas>
  text: string
  setText: Dispatch<SetStateAction<string>>
  file: File | undefined
  setFile: Dispatch<SetStateAction<File | undefined>>
  uploadMode: 'FILE_UPLOAD' | 'CANVAS' | 'INPUT' | null
  setUploadMode: Dispatch<SetStateAction<'FILE_UPLOAD' | 'CANVAS' | 'INPUT' | null>>
  title: string
  description: string
  order: IOrder
  type: 'BUYER' | 'SUPPLIER'
}) => {
  const { user } = useUserStore((state) => ({ user: state.user! }))

  const isUploadPossible =
    user.userType === type &&
    // Checking if on relevent step
    ((user.userType === 'BUYER' &&
      (order.status === 'BUYER_ORDER_GUARANTEED' ||
        order.status === 'PRODUCT_SHIPPED' ||
        order.status === 'DELIVERY_CONFIRMATION' ||
        order.status === 'PRODUCT_CONFIRMATION')) ||
      (user.userType === 'SUPPLIER' && order.status === 'SELLER_ORDER_GUARANTEED'))

  const textForWaitingForOtherUser = () => {
    if (user.userType === type) {
      return ''
    }
    if (
      user.userType === 'BUYER' &&
      (order.status === 'BUYER_ORDER_GUARANTEED' ||
        order.status === 'PRODUCT_SHIPPED' ||
        order.status === 'DELIVERY_CONFIRMATION' ||
        order.status === 'PRODUCT_CONFIRMATION')
    ) {
      return 'Waiting for Supplier to upload the signature'
    }

    return 'Waiting for Buyer to upload the signature'
  }

  return (
    <div className="mb-[10px] flex items-center justify-between pl-[0px] align-middle">
      <div className="mb-[10px] w-[400px]">
        <div className="text-sm font-bold 2xl:text-lg">{title}</div>
        <div className="text-sm 2xl:text-base">{description}</div>
      </div>
      {isUploadPossible && uploadMode && (
        <div className="flex h-[100px] w-[260px] gap-[10px] rounded-[10px] bg-[#F2F2F2] relative">
          {uploadMode === 'CANVAS' && (
            <SignatureCanvas
              ref={canvasSignature}
              penColor="#0077B6"
              canvasProps={{ width: 260, height: 100, className: 'sigCanvas' }}
            />
          )}
          {uploadMode === 'INPUT' && (
            <Input value={text} onChange={(e) => setText(e.target.value)} className="w-full" />
          )}
          <div
            onClick={() => setUploadMode(null)}
            className="absolute bg-white top-2 right-3 p-2.5 rounded-sm border w-12 h-6 flex items-center justify-center cursor-pointer text-[14px]"
          >
            Close
          </div>
        </div>
      )}
      {isUploadPossible && !uploadMode && (
        <Dialog>
          <DialogTrigger asChild>
            {file ? (
              <div className="flex h-[100px] w-[260px] items-center justify-center gap-[10px] rounded-[10px] bg-[#F2F2F2]">
                <img src={URL.createObjectURL(file)} className="w-full" />
              </div>
            ) : (
              <div className="flex h-[100px] w-[260px] cursor-pointer items-center justify-center gap-[10px] rounded-[10px] bg-[#F2F2F2]">
                Click to add Signature
              </div>
            )}
          </DialogTrigger>
          <DialogContent className="w-[337px] rounded-[20px]">
            <div className="flex flex-col gap-5">
              <div>
                <div className="text-sm font-bold 2xl:text-lg">{title}</div>
                <div className="text-sm 2xl:text-base">{description}</div>
              </div>
              <div className="flex flex-col gap-2.5">
                <Button tabindex="-1" variant="secondary" onClick={() => setUploadMode('INPUT')}>
                  Text
                </Button>
                <Button tabindex="-1" variant="secondary" onClick={() => setUploadMode('CANVAS')}>
                  Draw
                </Button>
                <Button tabindex="-1" variant="secondary" onClick={() => setUploadMode('FILE_UPLOAD')}>
                  Upload
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
      {!isUploadPossible && (
        <div className="flex h-[100px] w-[260px] gap-[10px] rounded-[10px] bg-[#F2F2F2]">
          {textForWaitingForOtherUser()}
        </div>
      )}
      {uploadMode === 'FILE_UPLOAD' && (
        <ModalDocument
          onCancel={() => setUploadMode(null)}
          onFileSelection={(file) => {
            setFile(file)
            setUploadMode(null)
          }}
        ></ModalDocument>
      )}
    </div>
  )
}

export default OrderSignatureGuarantee
