import { Button } from 'src/components/ui/button'
import * as React from 'react'
import { Control, Controller, useFieldArray } from 'react-hook-form'
import { IAuctionForm } from 'src/pages/supplier/fuel-trading/create-auction'
import { Input } from 'src/components/ui/input'
import { useTranslation } from 'react-i18next'
import { Checkbox } from 'src/components/ui/checkbox'
import { TrashIcon } from 'lucide-react'

const CreateAuctionOtherFees = ({ control, disabled }: { control: Control<IAuctionForm, any>; disabled: boolean }) => {
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({ control, name: 'auctionOtherFees' })
  const { t } = useTranslation('translations')

  return (
    <div className="mt-5 flex flex-col">
      <div className="mt-5 flex flex-row justify-between items-center">
        <div className="text-xl font-bold text-secoundary 2xl:text-2xl">Other Fees</div>
        <Button
          disabled={disabled}
          size="sm"
          className="w-20"
          onClick={() => append({ label: '', isPercentage: false, value: 0 })}
        >
          Add +
        </Button>
      </div>
      <hr className="mb-5 mt-5 h-px border-0 bg-[#E0E0E0]" />
      {fields.map((field, index) => {
        return (
          <div key={index}>
            <div key={index} className="mt-5 flex justify-between items-center gap-10">
              <div className="flex w-full flex-col">
                <label
                  htmlFor="cityTaxPercentage"
                  className="block text-sm font-medium leading-6 text-secoundary 2xl:text-base"
                >
                  Label
                </label>
                <Controller
                  control={control}
                  name={`auctionOtherFees.${index}.label`}
                  render={({ field, fieldState }) => {
                    return (
                      <div className="mt-2">
                        <Input
                          disabled={disabled}
                          placeholder="label"
                          {...field}
                          onChange={field.onChange}
                          error={fieldState.error}
                        />
                      </div>
                    )
                  }}
                />
              </div>
              <div className="flex w-full flex-col">
                <label
                  htmlFor="fuelFeesPercentage"
                  className="block text-sm font-medium leading-6 text-secoundary 2xl:text-base"
                >
                  Value
                </label>
                <Controller
                  control={control}
                  name={`auctionOtherFees.${index}.value`}
                  render={({ field, fieldState }) => {
                    return (
                      <div className="mt-2">
                        <Input
                          disabled={disabled}
                          type="number"
                          id="fuelFeesPercentageLabel"
                          placeholder="Value"
                          {...field}
                          onChange={(e) =>
                            e.target.value ? field.onChange(parseFloat(e.target.value)) : field.onChange(null)
                          }
                          error={fieldState.error}
                        />
                      </div>
                    )
                  }}
                />
              </div>
              <Controller
                control={control}
                name={`auctionOtherFees.${index}.isPercentage`}
                render={({ field, fieldState }) => {
                  return (
                    <div className="mt-2">
                      <label
                        htmlFor="fuelFeesFixed"
                        className="text-sm font-medium leading-6 text-secoundary 2xl:text-base  flex items-center gap-2"
                      >
                        <Checkbox
                          disabled={disabled}
                          checked={field.value}
                          onCheckedChange={(e) => field.onChange(!!e)}
                        />{' '}
                        Percentage
                      </label>
                    </div>
                  )
                }}
              />
              <div className="w-20 ">
                <Button size="icon" variant="destructive" className="h-10 p-0" onClick={() => remove(index)}>
                  <TrashIcon />
                </Button>
              </div>
            </div>
            <hr className="mb-2.5 mt-2.5 h-px border-0 bg-[#E0E0E0]" />
          </div>
        )
      })}
    </div>
  )
}

export default CreateAuctionOtherFees
