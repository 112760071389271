import { useTranslation } from 'react-i18next'

const CurrencyFormatter: React.FC<{
  currency: string
  amount: number | bigint | null | undefined
  cents?: boolean
}> = ({ currency, amount, cents }) => {
  const { t } = useTranslation('translation')
  const currencyFormatted = t('currency.' + currency)

  if (!amount) {
    return <>-</>
  }

  if (cents) {
    if (typeof amount === 'bigint') {
      return (
        <>
          {currencyFormatted} {new Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount / BigInt(100))}
        </>
      )
    } else {
      return (
        <>
          {currencyFormatted} {new Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount / 100)}
        </>
      )
    }
  }

  return (
    <>
      {currencyFormatted} {new Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount)}
    </>
  )
}

export default CurrencyFormatter
