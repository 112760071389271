import { cn } from 'src/lib/utils'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { IAuction } from 'src/_models/auction.model'
import { differenceInDays } from 'date-fns'
import { isPastAuction } from 'src/lib/auction.util'

const AuctionDialogHeader = ({ auction }: { auction: IAuction }) => {
  const { t } = useTranslation('translation')
  const days = differenceInDays(auction.auctionEndDate, new Date())

  return (
    <div className="flex items-center justify-between">
      <h2 className="text-base font-bold text-secoundary 2xl:text-lg">{t('auctionType.' + auction.auctionType)}</h2>
      <div className="rounded-[9.46px]  px-[10px] py-[5px] text-base font-bold leading-[17px] text-primary 2xl:text-base 2xl:leading-[19.5px]">
        ID# {auction.generatedId}
      </div>
      <span className={cn('text-sm font-bold 2xl:text-base', days < 5 && 'text-[#F33]')}>
        {!isPastAuction(auction) && `Ends in ${days} Days`}
      </span>
    </div>
  )
}

export default AuctionDialogHeader
