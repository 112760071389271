import { IFuelTradingOrderDocuments } from 'src/_models/order.model'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { toast } from 'src/components/ui/use-toast'
import { Dialog, DialogClose, DialogContent, DialogTrigger } from 'src/components/ui/dialog'
import { Button } from 'src/components/ui/button'
import { DownloadIcon } from 'lucide-react'
import { formatAuctionDate } from 'src/lib/format-auction'

const OrderDocumentsDownload = ({
  docType,
  disabled,
  orderDocuments,
}: {
  disabled: boolean
  docType: string
  orderDocuments: IFuelTradingOrderDocuments[]
}) => {
  const { t } = useTranslation()
  const downloadUploadedFileMutation = useMutation({
    mutationFn: (data: { id: number }): Promise<AxiosResponse<Blob>> => {
      const token = localStorage.getItem('token')
      return axios.get('/api/order-document-upload/' + data.id, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const downloadUploadedFile = (docId: number, fileName: string, fileKey: string) => {
    toast({
      title: 'File download starting now.',
    })
    downloadUploadedFileMutation.mutate(
      { id: docId },
      {
        onSuccess: (resp) => {
          // saveAs(resp.data)
          const url = window.URL.createObjectURL(new Blob([resp.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName || fileKey || 'file') //or any other extension
          document.body.appendChild(link)
          link.click()
          toast({
            title: 'File downloaded',
          })
        },
        onError: (err) => {
          const error = err as AxiosError<{ message: string }>
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description: t(error.response?.data?.message || error.message || 'err.somethingWrong'),
          })
        },
      },
    )
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button disabled={disabled} size="icon" variant="secondary" className="rounded-[10px]">
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 0 10 13" fill="none">
            <path
              d="M0.5 12.5645H10V11.1527H0.5M10 4.79975H7.28572V0.564453H3.21429V4.79975H0.5L5.25 9.74092L10 4.79975Z"
              fill="#333333"
            />
          </svg>
        </Button>
      </DialogTrigger>
      <DialogContent className="w-[448px] rounded-[40px] p-[30px]">
        <div>Download {docType}</div>
        {orderDocuments.length === 0 && <label>No Documents Yet</label>}
        {orderDocuments.map((orderDocument) => (
          <div key={orderDocument.id} className="flex items-center justify-between gap-2">
            <div>
              <div>{orderDocument.media.fileName || orderDocument.media.fileKey.split('/').pop()}</div>
              <div className="text-sm text-grey">{formatAuctionDate(orderDocument.createdAt)}</div>
            </div>
            <Button
              onClick={() => downloadUploadedFile(orderDocument.id, orderDocument.media.fileName, orderDocument.media.fileKey)}
              size="icon"
              expand="normal"
            >
              <DownloadIcon />
            </Button>
          </div>
        ))}
        <div className="flex gap-2">
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default OrderDocumentsDownload
