import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { IAuction } from 'src/_models/auction.model'
import AuctionCard from 'src/components/auction/auction-card'
import * as React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/autoplay'
import { Autoplay } from 'swiper'
import {useUserStore} from "src/_store/user.store";
const FuelTradingBuyerMyAuctions = () => {
  const { user } = useUserStore((state) => ({
    user: state.user,
  }))

  const myAuctionQuery = useQuery({
    queryKey: ['buyer-my-auctions', 'auctions', user?.fuelTradingStatePreference],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get('/api/buyer/auctions?state=' + user?.fuelTradingStatePreference, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }) as Promise<AxiosResponse<{ auctions: IAuction[] }>>
    },
    enabled: !!user
  })

  return (
    <>
      <section className="w-full">
        <div className="container mx-auto bg-transparent ">
          <div className="mb-[15px] flex w-full items-center justify-between pt-10 align-middle ">
            <h2 className="text-lg font-bold text-secoundary 2xl:text-2xl">My Auctions</h2>
            <a href="/buyer/my-auctions" className="text-sm font-bold text-primary">
              View All
            </a>
          </div>
          {myAuctionQuery.data && myAuctionQuery.data.data.auctions.length === 1 && (
            <div className="flex flex-row gap-5 pt-[15px]">
              {myAuctionQuery.data.data.auctions.map((auction) => {
                return <AuctionCard key={`my-auction-${auction.id}`} auction={auction} />
              })}
            </div>
          )}
          {myAuctionQuery.data && myAuctionQuery.data.data.auctions.length > 1 && (
            <div className="flex flex-row gap-5">
              <Swiper
                // modules={[Pagination]}
                modules={[Autoplay]}
                loop={myAuctionQuery.data.data.auctions.length > 4}
                spaceBetween={20}
                slidesPerView={4}
                grabCursor={true}
                autoplay={{
                  delay: 2000,
                  stopOnLastSlide: false,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                breakpoints={{
                  // when window width is <= 320px
                  320: {
                    slidesPerView: 2,
                    spaceBetween: 5,
                  },
                  // when window width is <= 480px
                  425: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  // when window width is <= 640px
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  1440: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                  },
                  1920: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                  },
                }}
                className="carousel relative grid grid-cols-4 gap-5 2xl:grid-cols-5"
              >
                {myAuctionQuery.data.data.auctions.map((auction) => {
                  return (
                    <SwiperSlide key={`my-auction-${auction.id}`} className="relative mt-[1.5rem]">
                      <AuctionCard auction={auction} />
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
          )}
        </div>
      </section>
    </>
  )
}

export default FuelTradingBuyerMyAuctions
