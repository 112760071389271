import { Rating, ThinStar } from '@smastrom/react-rating'
import '@smastrom/react-rating/style.css'

const myStyles = {
  itemShapes: ThinStar,
  radius: 'small',
  activeFillColor: '#ffb700',
  inactiveFillColor: '#ffffff',
}

const AuctionRating = ({ rating }: { rating: number }) => {
  return <Rating style={{ maxWidth: 100 }} value={rating} readOnly={true} itemStyles={myStyles} />
}

export default AuctionRating
