export const CONSTANT_A = 'CONSTANT_A'

// taxes will be mapped to country, state and city { apparently city has some seperate taxes }
export const TAXES_CALC = 0.1
// there is another fees for the fuel itself which is 7%
// export const FUEL_FEES_CALC = 0.07

// 7% fuel fees + 5% platform fees --> 12%
export const FUEL_FEES_CALC = 0.07
export const BUYER_FEES_CALC = 0.025
export const SHIPPING_COST = 50
