import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useUserStore } from 'src/_store/user.store'
import axios, { AxiosResponse } from 'axios'
import * as React from 'react'
import { toast } from 'src/components/ui/use-toast'

const AuctionCardWishlistIcon = ({ auctionId }: { auctionId: number }) => {
  const queryClient = useQueryClient()
  const { user } = useUserStore((state) => {
    return {
      user: state.user,
    }
  })

  const wishlistMutation = useMutation({
    mutationFn: ({ auctionId }: { auctionId: number }) => {
      const token = localStorage.getItem('token')
      return axios.post(
        '/api/wishlist',
        { auctionId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ) as Promise<AxiosResponse<{ message: string }>>
    },
  })

  const performWishlistMutation = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()

    if (wishlistMutation.isLoading) {
      return
    }
    wishlistMutation.mutate(
      { auctionId },
      {
        onSuccess: async (resp) => {
          toast({
            title: resp.data.message,
          })
          queryClient.invalidateQueries()
          // window.location.reload()
        },
      },
    )
  }

  if (!user) {
    return
  }

  const wishlist = user.wishlistedAuctions.map((auction) => auction.id).indexOf(auctionId) !== -1

  return (
    <div
      onClick={(e) => performWishlistMutation(e)}
      className="flex h-[24px] w-[24px] cursor-pointer items-center rounded-sm bg-black align-middle leading-[24px] text-white 2xl:h-[31px] 2xl:w-[31px] 2xl:leading-[31px] "
    >
      {wishlist ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          viewBox="0 0 23 18"
          fill="none"
          className="mx-auto"
        >
          <path
            d="M2 10.7995L7.40885 16.2083L20.7292 2"
            stroke="white"
            strokeWidth="2.58333"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          className="mx-auto"
        >
          <path d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z" fill="white" />
        </svg>
      )}
    </div>
  )
}

export default AuctionCardWishlistIcon
