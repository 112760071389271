import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { toast } from 'src/components/ui/use-toast'
import OtpInput from 'src/components/ui/otp-input'
import { Button } from 'src/components/ui/button'
import { z, ZodType } from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useUserStore } from 'src/_store/user.store'
import { useNavigate } from 'react-router-dom'
import BackLogoutBtn from 'src/pages/auth/signup/back-logout-btn'

type IVerificationCodeForm = {
  verificationCode: string
}

export default function VerificationSignUp({ goNext }: { goNext: () => void }) {
  const { t } = useTranslation('translation')
  const { user, setUser } = useUserStore((state) => {
    return {
      user: state.user,
      setUser: state.setUser,
    }
  })

  const verifyTokenSchema: ZodType<IVerificationCodeForm> = z.object({
    verificationCode: z.string({ required_error: t('error.emailRequired') }).min(6, t('error.emailInvalid')),
  })

  const { control, handleSubmit } = useForm<IVerificationCodeForm>({
    resolver: zodResolver(verifyTokenSchema),
  })

  const verifyTokenMutation = useMutation({
    mutationFn: (data: IVerificationCodeForm): Promise<AxiosResponse<{ token: string }>> => {
      const token = localStorage.getItem('token')
      return axios.post('/api/auth/verifyUser', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const resendVerificationTokenMutation = useMutation({
    mutationFn: (data: IVerificationCodeForm): Promise<AxiosResponse<{ token: string }>> => {
      const token = localStorage.getItem('token')
      return axios.post(
        '/api/auth/resendVerificationToken',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })

  const handleUserVerification = (data: IVerificationCodeForm) => {
    try {
      verifyTokenMutation.mutate(data, {
        onSuccess: () => {
          goNext()
        },
        onError: (err) => {
          const error = err as AxiosError<{ message: string }>
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description: t(error.response?.data?.message || 'error.somethingWrong'),
          })
        },
      })
    } catch (error: any) {
      // Handle errors
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: error.response?.data?.message,
      })
    }
  }

  const handleResverifyUser = async () => {
    try {
      resendVerificationTokenMutation.mutate(
        { verificationCode: '' },
        {
          onSuccess: () => {
            toast({ title: t('verification.resent') })
          },
          onError: (err) => {
            const error = err as AxiosError<{ message: string }>
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: t(error.response?.data?.message || 'error.somethingWrong'),
            })
          },
        },
      )
    } catch (error: any) {
      // Handle errors
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: error.response?.data?.message,
      })
    }
  }

  return (
    <>
      {/*<h2 className="pb-[10px] text-sm font-bold text-txtblack">New Password</h2>*/}

      <form>
        <h1 className="pb-[10px] text-2xl font-bold text-txtblack">{t('otpVerify.heading')}</h1>
        <p className="pb-4 text-sm font-[500] text-txtblack">
          You will receive a mail with otp to verify your email address :: <span>{user?.email}</span>
        </p>

        <p className="pb-4 pt-2 text-sm font-semibold text-txtblack">
          <a className="cursor-pointer text-txtblack hover:underline" onClick={handleResverifyUser}>
            Resend OTP
          </a>
        </p>
        <div className="pt-8">
          <Controller
            control={control}
            name="verificationCode"
            render={({ field, fieldState }) => {
              return <OtpInput value={field.value || ''} valueLength={6} onChange={field.onChange} />
            }}
          />
        </div>
      </form>
      <div className="flex gap-4 pb-5 pt-10">
        <BackLogoutBtn />
        {/* history.go(-1) */}
        <Button type="submit" onClick={handleSubmit(handleUserVerification)}>
          Submit
        </Button>
      </div>
    </>
  )
}
