import { useUserStore } from 'src/_store/user.store'
import BuyerDashboard from 'src/pages/dashboard/buyer-dashboard'
import SupplierDashboard from 'src/pages/dashboard/supplier-dashboard'

const DashboardPage = () => {
  const { user } = useUserStore((state) => {
    return {
      user: state.user,
    }
  })

  return user?.userType === 'BUYER' ? <BuyerDashboard /> : <SupplierDashboard />
}

export default DashboardPage
