import * as React from 'react'
import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { IAuction } from 'src/_models/auction.model'
import AuctionCard from 'src/components/auction/auction-card'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/autoplay'
import { Autoplay } from 'swiper'
import { useUserStore } from 'src/_store/user.store'
export default function FuelTradingLiveAuctions() {
  const { user } = useUserStore((state) => ({
    user: state.user,
  }))
  const liveAuctionQuery = useQuery({
    queryKey: [`live-auctions`, user?.fuelTradingStatePreference],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get('/api/live-auctions?state=' + user?.fuelTradingStatePreference, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }) as Promise<AxiosResponse<{ auctions: IAuction[]; totalPages: number }>>
    },
    cacheTime: 60,
    enabled: !!user,
  })

  return (
    <section className="w-full">
      <div className="container mx-auto bg-transparent ">
        <div className="mb-[15px] flex w-full justify-between pt-[38px]">
          <h2 className="text-lg font-bold text-secoundary 2xl:text-2xl">Live Auctions</h2>
          <a href="/live-auctions" className="text-sm font-bold text-primary">
            View All
          </a>
        </div>
        {liveAuctionQuery.data && liveAuctionQuery.data.data.auctions.length === 1 && (
          <div className="flex flex-row gap-5 pt-5">
            {liveAuctionQuery.data.data.auctions.map((auction) => {
              return <AuctionCard key={`my-auction-${auction.id}`} auction={auction} />
            })}
          </div>
        )}
        {liveAuctionQuery.data?.data.auctions && liveAuctionQuery.data.data.auctions.length > 1 && (
          <div className="flex w-full flex-row gap-5">
            <Swiper
              // modules={[Pagination]}
              modules={[Autoplay]}
              loop={liveAuctionQuery.data.data.auctions.length > 4}
              spaceBetween={20}
              slidesPerView={4}
              pagination={{
                clickable: false,
              }}
              autoplay={{
                delay: 2000,
                stopOnLastSlide: false,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              grabCursor={true}
              breakpoints={{
                // when window width is <= 320px
                320: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                // when window width is <= 480px
                425: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                // when window width is <= 640px
                768: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                1440: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                1920: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
              }}
              className="carousel relative grid grid-cols-4 gap-5"
            >
              {liveAuctionQuery.data?.data.auctions.map((auction) => {
                return (
                  <SwiperSlide key={`live-auction-${auction.id}`} className="relative mt-[1.5rem]  ">
                    <AuctionCard auction={auction} className="w-[305px] 2xl:w-[336px]" />
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        )}
      </div>
    </section>
  )
}
