import { cn } from 'src/lib/utils'

type IStepperProps = {
  steps: { title: string }[]
  currentStep: number
}

const Stepper = ({ steps, currentStep }: IStepperProps) => {
  return (
    <>
      <ul className="flex w-full items-start justify-center px-2 pb-5">
        {steps.map((step, index) => {
          return index < steps.length - 1 ? (
            <li
              key={index}
              className={cn(
                "relative flex w-full after:absolute after:left-[38px] after:top-5 after:h-1 after:w-full  after:content-['']",
                index < currentStep ? 'after:bg-primary' : 'after:bg-stepcolor',
              )}
            >
              <div className="z-10 flex flex-col items-center">
                <span
                  className={cn(
                    'flex h-10 w-10 shrink-0 items-center justify-center rounded-full ',
                    index < currentStep ? 'bg-primary text-white' : 'border-4 border-stepcolor bg-white',
                    index === currentStep && 'border-primary',
                  )}
                >
                  {index < currentStep && (
                    <svg
                      className="text-midblue h-4 w-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 16 12"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="4"
                        d="M1 5.917 5.724 10.5 15 1.5"
                      />
                    </svg>
                  )}
                </span>
                <span className="absolute top-[36px] flex w-[90px] pt-2 text-center text-sm after:flex after:text-[0.8rem] after:content-[data-content]">
                  {step.title}
                </span>
              </div>
            </li>
          ) : (
            <li
              key={index}
              className={cn(
                'relative flex after:absolute after:left-[38px] after:top-5 after:h-1 after:w-full',
                index < currentStep ? 'after:bg-primary' : 'after:bg-stepcolor',
              )}
            >
              <div className="z-10 flex flex-col items-center">
                <span
                  className={cn(
                    'flex h-10 w-10 shrink-0 items-center justify-center rounded-full ',
                    index < currentStep ? 'bg-primary text-white' : 'border-4 border-stepcolor bg-white',
                    index === currentStep && 'border-primary',
                  )}
                >
                  {index < currentStep && (
                    <svg
                      className="text-midblue h-4 w-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 16 12"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="4"
                        d="M1 5.917 5.724 10.5 15 1.5"
                      />
                    </svg>
                  )}
                </span>
                <span className="flex pt-2 text-center text-sm after:flex after:text-[0.8rem] after:content-[data-content]">
                  {step.title}
                </span>
              </div>
            </li>
          )
        })}
      </ul>
    </>
  )
}
Stepper.displayName = 'Stepper'

export { Stepper }
