import { Input } from 'src/components/ui/input'
import { Button } from 'src/components/ui/button'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { z, ZodType } from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { IUser } from 'src/_models/user.model'
import { toast } from 'src/components/ui/use-toast'
import { useUserStore } from 'src/_store/user.store'
import ShippingDetails from 'src/pages/auth/signup/shipping-details'
import InputGoogleAutoComplete from 'src/components/ui/input-google-auto-complete'
import BackLogoutBtn from 'src/pages/auth/signup/back-logout-btn'
type IAccountSetupForm = {
  companyName: string
  companyNumber: string
  companyAddress: string
  companyStreetAddress: string
  companyContactDetails: string
  companyAccreditations: string
  latitude: string
  longitude: string
  companyKeyPerson: string
  companyKeyPersonDesignation: string
}
export default function AccountSetupSignUp({ goNext }: { goNext: () => void }) {
  const { t } = useTranslation('translation')
  const queryClient = useQueryClient()
  const { user, setUser } = useUserStore((store) => ({
    user: store.user,
    setUser: store.setUser,
  }))
  const [showSupplier, setShowSupplier] = useState<boolean>(false)
  const verifyAccountSetupSchema: ZodType<IAccountSetupForm> = z.object({
    companyName: z.string({ required_error: t('error.required') }).min(1, t('error.required')),
    companyNumber: z.string({ required_error: t('error.required') }).min(1, t('error.required')),
    companyAddress: z.string({ required_error: t('error.required') }).min(1, t('error.required')),
    companyStreetAddress: z.string({ required_error: t('error.required') }),
    companyContactDetails: z.string({ required_error: t('error.required') }).min(1, t('error.required')),
    companyAccreditations: z.string({ required_error: t('error.required') }).min(1, t('error.required')),
    latitude: z.string({ required_error: t('error.required') }).min(1, t('error.required')),
    longitude: z.string({ required_error: t('error.required') }).min(1, t('error.required')),
    companyKeyPerson: z.string({ required_error: t('error.required') }).min(1, t('error.required')),
    companyKeyPersonDesignation: z.string({ required_error: t('error.required') }).min(1, t('error.required')),
  })
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm<IAccountSetupForm>({
    resolver: zodResolver(verifyAccountSetupSchema),
    defaultValues: {
      companyName: user?.companyName || '',
      companyNumber: user?.companyNumber || '',
      companyStreetAddress: user?.companyStreetAddress || '',
      companyAddress: user?.companyAddress || '',
      companyContactDetails: user?.companyContactDetails || '',
      companyAccreditations: user?.companyAccreditations || '',
      latitude: user?.latitude,
      longitude: user?.longitude,
      companyKeyPerson: user?.companyKeyPerson || '',
      companyKeyPersonDesignation: user?.companyKeyPersonDesignation || '',
    },
  })
  // const [latitude, setLatitude] = useState<number>()
  // const [longitude, setLongitude] = useState<number>()
  const onLatLngChange = (lat: string, lng: string) => {
    setValue('latitude', lat)
    setValue('longitude', lng)
  }
  const [address, setAddress] = useState<string>()

  useEffect(() => {
    if (user) {
      setValue('companyName', user.companyName || '')
      setValue('companyNumber', user.companyNumber || '')
      setValue('companyAddress', user.companyAddress || '')
      setValue('companyStreetAddress', user.companyStreetAddress || '')
      setValue('companyContactDetails', user.companyContactDetails || '')
      setValue('companyAccreditations', user.companyAccreditations || '')
      setValue('latitude', user.latitude)
      setValue('longitude', user.longitude)
      setValue('companyKeyPerson', user.companyKeyPerson || '')
      setValue('companyKeyPersonDesignation', user.companyKeyPersonDesignation || '')
    }
  }, [user])

  const accountSetupMutation = useMutation({
    mutationFn: (data: IAccountSetupForm): Promise<AxiosResponse<IUser>> => {
      const token = localStorage.getItem('token')
      return axios.put(
        '/api/auth/update-details',
        {
          ...data,
          step: user?.userType === 'BUYER' && 'DOCUMENT_UPLOAD',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })
  const handleAccountSetup = (data: IAccountSetupForm) => {
    if (address !== data.companyAddress && data.companyAddress !== user?.companyAddress) {
      setError('companyAddress', { message: 'Please select from autocomplete.' })
      return
    }

    accountSetupMutation.mutate(data, {
      onSuccess: (success) => {
        queryClient.invalidateQueries({ queryKey: ['profile'] })
        if (user?.userType === 'SUPPLIER') {
          setShowSupplier(true)
        } else {
          goNext()
        }
      },
      onError: (err) => {
        const error = err as AxiosError<{ message: string }>
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: t(error?.response?.data?.message as string),
        })
      },
    })
  }

  return showSupplier ? (
    <ShippingDetails goPrev={() => setShowSupplier(false)} goNext={goNext} />
  ) : (
    <>
      <form
        onSubmit={handleSubmit(handleAccountSetup, (err) => {
          toast({
            variant: 'destructive',
            title: 'Please check the form and try again.',
          })
        })}
      >
        <h1 className="pb-10 text-2xl font-bold text-txtblack">{t('accountSetup.heading')}</h1>
        <div className="pb-5">
          <label htmlFor="companyname" className="block text-sm font-bold leading-6 text-txtblack">
            {t('accountSetup.companyNameLabel')}
          </label>
          <div className="mt-2">
            <Controller
              control={control}
              name="companyName"
              render={({ field, fieldState }) => {
                return (
                  <Input
                    id="cname"
                    name="companyName"
                    type="text"
                    placeholder={t('accountSetup.companyNamePlaceholder')}
                    value={field.value}
                    error={fieldState.error}
                    onChange={field.onChange}
                  />
                )
              }}
            />
          </div>
        </div>
        <div className="pb-5">
          <div className="flex items-center justify-between">
            <label htmlFor="companyno" className="block text-sm font-bold leading-6">
              {t('accountSetup.companyNoLabel')}
            </label>
          </div>
          <div className="mt-2">
            <Controller
              control={control}
              name="companyNumber"
              render={({ field, fieldState }) => {
                return (
                  <Input
                    type="tel"
                    placeholder={t('accountSetup.companyNoPlaceholder')}
                    error={fieldState.error}
                    {...field}
                  />
                )
              }}
            />
          </div>
        </div>

        <div className="relative pb-5">
          <div className="flex items-center justify-between ">
            <label htmlFor="companyaddress" className="block text-sm font-bold leading-6">
              Company Address
            </label>
          </div>
          <div className="mt-2">
            <Controller
              control={control}
              name="companyAddress"
              render={({ field, fieldState }) => {
                return (
                  <>
                    <InputGoogleAutoComplete
                      onLatLngChange={onLatLngChange}
                      getAddressValue={setAddress}
                      error={errors.latitude || errors.longitude || fieldState.error}
                      {...field}
                    />
                  </>
                )
              }}
            />
          </div>
        </div>

        {/* TODO --> change companyAddress to unit/office no in all refferences  */}
        <div className="pb-5">
          <div className="flex items-center justify-between">
            <label htmlFor="companyaddress" className="block text-sm font-bold leading-6">
              Unit No. / Office No. / Suite No.
            </label>
          </div>
          <div className="mt-2">
            <Controller
              control={control}
              name="companyStreetAddress"
              render={({ field, fieldState }) => {
                return (
                  <Input
                    type="text"
                    id="label"
                    placeholder="Unit No. or Office No."
                    error={fieldState.error}
                    {...field}
                  />
                )
              }}
            />
          </div>
        </div>

        {/* Todo --> make optional  */}
        <div className="pb-5">
          <div className="flex items-center justify-between">
            <label htmlFor="accreditations" className="block text-sm font-bold leading-6">
              Accreditations
            </label>
          </div>
          <div className="mt-2">
            <Controller
              control={control}
              name="companyAccreditations"
              render={({ field, fieldState }) => {
                return <Input type="text" placeholder="License No. / Permit No." error={fieldState.error} {...field} />
              }}
            />
          </div>
        </div>
        <div className="pb-5">
          <div className="flex items-center justify-between">
            <label htmlFor="companyContactDetails" className="block text-sm font-bold leading-6">
              Contact Info
            </label>
          </div>
          <div className="mt-2">
            <Controller
              control={control}
              name="companyContactDetails"
              render={({ field, fieldState }) => {
                return <Input type="text" placeholder="+1-212-456-7890" error={fieldState.error} {...field} />
              }}
            />
          </div>
        </div>
        <div className="pb-5">
          <div className="flex items-center justify-between">
            <label htmlFor="companyKeyPerson" className="block text-sm font-bold leading-6">
              Key Person
            </label>
          </div>
          <div className="mt-2">
            <Controller
              control={control}
              name="companyKeyPerson"
              render={({ field, fieldState }) => {
                return <Input type="text" placeholder="Mr./Mrs. Allen Smith" error={fieldState.error} {...field} />
              }}
            />
          </div>
        </div>
        <div className="pb-5">
          <div className="flex items-center justify-between">
            <label htmlFor="companyKeyPersonDesignation" className="block text-sm font-bold leading-6">
              Key Person Designation
            </label>
          </div>
          <div className="mt-2">
            <Controller
              control={control}
              name="companyKeyPersonDesignation"
              render={({ field, fieldState }) => {
                return <Input type="text" placeholder="CTO" error={fieldState.error} {...field} />
              }}
            />
          </div>
        </div>
      </form>
      <div className="flex gap-4 pb-5 pt-10">
        <BackLogoutBtn />
        {/*<Button type="submit" onClick={handleBack}>*/}
        {/*  Back*/}
        {/*</Button>*/}
        <Button
          onClick={handleSubmit(handleAccountSetup, (err) => {
            toast({
              variant: 'destructive',
              title: 'Please check the form and try again.',
            })
          })}
        >
          Next
        </Button>
      </div>
    </>
  )
}
