import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from 'src/lib/utils'
import { PropsWithChildren } from 'react'

const userTierBadgeVariants = cva(
  'inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        platinum:
          'flex justify-center rounded-[10px] py-[15px] px-[44px] leading-[17px] font-bold 2xl:text-sm text-xs text-white platinumbtn',
        golden:
          'flex justify-center rounded-[10px] py-[15px] px-[44px] leading-[17px] font-bold 2xl:text-sm text-xs text-white goldenbtn',
        silver:
          'flex justify-center rounded-[10px] py-[15px] px-[44px] leading-[17px] font-bold 2xl:text-sm text-xs text-white silverbtn',

        // platinum: 'flex justify-center rounded-[10px] text-white shadow-sm ring-2 ring-primary',
      },
    },
    defaultVariants: {
      variant: 'platinum',
    },
  },
)

export interface UserTierBadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof userTierBadgeVariants> {
  asChild?: boolean
}

const UserTierBadge = React.forwardRef<HTMLDivElement, PropsWithChildren<UserTierBadgeProps>>(
  ({ className, variant, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div'
    return <Comp className={cn(userTierBadgeVariants({ variant, className }))} ref={ref} {...props} />
  },
)
UserTierBadge.displayName = 'Button'

export { UserTierBadge, userTierBadgeVariants }
