import { Input } from 'src/components/ui/input'
import { Button } from 'src/components/ui/button'
import * as React from 'react'
import { string, z, ZodType } from 'zod'
import { useTranslation } from 'react-i18next'
import { useUserStore } from 'src/_store/user.store'
import { IUser } from 'src/_models/user.model'
import { Controller, useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import InputGoogleAutoComplete from 'src/components/ui/input-google-auto-complete'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { toast } from 'src/components/ui/use-toast'

type IProfileCompanyInformationForm = {
  companyName: string
  companyNumber: string
  companyKeyPerson: string
  companyKeyPersonDesignation: string
  companyAddress: string
  companyStreetAddress: string
  companyContactDetails: string
  companyAccreditations: string
  latitude: string
  longitude: string
}

const ProfileCompanyInformation = ({ user }: { user: IUser }) => {
  const { t } = useTranslation('translation')
  const queryClient = useQueryClient()

  const profileCompanyInformationFormZodType: ZodType<IProfileCompanyInformationForm> = z.object({
    companyName: z.string({ required_error: t('error.required') }).min(1, t('error.required')),
    companyNumber: z.string({ required_error: t('error.required') }).min(1, t('error.required')),
    companyAddress: z.string({ required_error: t('error.required') }).min(1, t('error.required')),
    companyStreetAddress: z.string({ required_error: t('error.required') }).min(1, t('error.required')),
    companyContactDetails: z.string({ required_error: t('error.required') }).min(1, t('error.required')),
    companyAccreditations: z.string({ required_error: t('error.required') }).min(1, t('error.required')),
    latitude: z.string(),
    longitude: z.string(),
    companyKeyPerson: z.string({ required_error: t('error.required') }).min(1, t('error.required')),
    companyKeyPersonDesignation: z.string({ required_error: t('error.required') }).min(1, t('error.required')),
  })

  const { control, reset, setValue, setError, handleSubmit } = useForm<IProfileCompanyInformationForm>({
    defaultValues: {
      companyName: user.companyName || '',
      companyNumber: user.companyNumber || '',
      companyAddress: user.companyAddress || '',
      companyContactDetails: user.companyContactDetails || '',
      companyAccreditations: user.companyAccreditations || '',
      latitude: user.latitude || '',
      longitude: user.longitude || '',
      companyKeyPerson: user.companyKeyPerson || '',
      companyKeyPersonDesignation: user.companyKeyPersonDesignation || '',
    },
    resolver: zodResolver(profileCompanyInformationFormZodType),
  })

  useEffect(() => {
    reset({
      companyName: user.companyName || '',
      companyNumber: user.companyNumber || '',
      companyAddress: user.companyAddress || '',
      companyStreetAddress: user.companyStreetAddress || '',
      companyContactDetails: user.companyContactDetails || '',
      companyAccreditations: user.companyAccreditations || '',
      latitude: user.latitude || '',
      longitude: user.longitude || '',
      companyKeyPerson: user.companyKeyPerson || '',
      companyKeyPersonDesignation: user.companyKeyPersonDesignation || '',
    })
  }, [user])

  const [address, setAddress] = useState<string>()

  const onLatLngChange = (lat: string, lng: string) => {
    setValue('latitude', lat)
    setValue('longitude', lng)
  }

  const updateCompanyInformationMutation = useMutation({
    mutationKey: ['updateProfileInformationMutation'],
    mutationFn: (data: IProfileCompanyInformationForm) => {
      const token = localStorage.getItem('token')
      return axios.put(
        '/api/profile/company-information',
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })

  const updateProfileCompanyInformation = (data: IProfileCompanyInformationForm) => {
    if (address !== data.companyAddress && data.companyAddress !== user?.companyAddress) {
      setError('companyAddress', { message: 'Please select from autocomplete.' })
      return
    }

    updateCompanyInformationMutation.mutate(data, {
      onSuccess: () => {
        toast({
          title: 'Updated company information.',
        })
        queryClient.invalidateQueries()
      },
      onError: (e) => {
        const err = e as AxiosError<{ message: string }>
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: err.response ? t(err.response.data.message) : 'Failed to update company information.',
        })
      },
    })
  }

  return (
    <div className="flex w-full">
      <div className="mx-10 w-full max-w-[500px] 2xl:mx-20 2xl:max-w-[600px]">
        <div className="flex w-full flex-col gap-[30px]">
          <div className="flex flex-col gap-[10px]">
            <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">Company Name</label>
            <Controller
              name="companyName"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  type="text"
                  // className="text-sm[appearance:textfield] border-1 border border-primary px-4 py-[15px] text-left focus:outline-0 focus:ring-0"
                  {...field}
                  error={fieldState.error}
                />
              )}
            />
          </div>
          <div className="flex flex-col gap-[10px]">
            <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">
              {t('accountSetup.companyNoLabel')}
            </label>
            <Controller
              name="companyNumber"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  type="text"
                  // className="text-sm[appearance:textfield] border-1 border border-primary px-4 py-[15px] text-left focus:outline-0 focus:ring-0"
                  {...field}
                  error={fieldState.error}
                />
              )}
            />
          </div>
          <div className="flex flex-col gap-[10px]">
            <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">Key Person</label>
            <Controller
              name="companyKeyPerson"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  type="text"
                  // className="text-sm[appearance:textfield] border-1 border border-primary px-4 py-[15px] text-left focus:outline-0 focus:ring-0"
                  {...field}
                  error={fieldState.error}
                />
              )}
            />
          </div>
          <div className="flex flex-col gap-[10px]">
            <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">
              Key Person Designation
            </label>
            <Controller
              name="companyKeyPersonDesignation"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  type="text"
                  // className="text-sm[appearance:textfield] border-1 border border-primary px-4 py-[15px] text-left focus:outline-0 focus:ring-0"
                  {...field}
                  error={fieldState.error}
                />
              )}
            />
          </div>

          <div className="flex flex-col gap-[10px]">
            <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">Company Address</label>
            <Controller
              control={control}
              name="companyAddress"
              render={({ field, fieldState }) => {
                return (
                  <>
                    <InputGoogleAutoComplete
                      onLatLngChange={onLatLngChange}
                      getAddressValue={setAddress}
                      error={fieldState.error}
                      {...field}
                    />
                  </>
                )
              }}
            />
          </div>

          <div className="flex flex-col gap-[10px]">
            <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">
              Unit No. / Office No. / Suite No.
            </label>
            <Controller
              name="companyStreetAddress"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  type="text"
                  // className="text-sm[appearance:textfield] border-1 border border-primary px-4 py-[15px] text-left focus:outline-0 focus:ring-0"
                  {...field}
                  error={fieldState.error}
                />
              )}
            />
          </div>

          <div className="flex flex-col gap-[10px]">
            <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">
              Company Accreditations
            </label>
            <Controller
              control={control}
              name="companyAccreditations"
              render={({ field, fieldState }) => (
                <Input
                  type="text"
                  // className="text-sm[appearance:textfield] border-1 border border-primary px-4 py-[15px] text-left focus:outline-0 focus:ring-0"
                  {...field}
                  error={fieldState.error}
                />
              )}
            />
          </div>

          <div className="flex flex-col gap-[10px]">
            <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">Contact Info</label>
            <Controller
              control={control}
              name="companyContactDetails"
              render={({ field, fieldState }) => (
                <Input
                  type="text"
                  // className="text-sm[appearance:textfield] border-1 border border-primary px-4 py-[15px] text-left focus:outline-0 focus:ring-0"
                  {...field}
                  error={fieldState.error}
                />
              )}
            />
          </div>
          <Button className="w-fit px-[82px] py-[16px]" onClick={handleSubmit(updateProfileCompanyInformation)}>
            Update Details
          </Button>
        </div>
      </div>

      <div className="w-full max-w-[417px] 2xl:max-w-[417px]"></div>
    </div>
  )
}

export default ProfileCompanyInformation
