import { Select, SelectItem, SelectTrigger, SelectValue } from 'src/components/ui/select'
import { SelectContent } from '@radix-ui/react-select'
import { Input } from 'src/components/ui/input'
import { Button } from 'src/components/ui/button'
import InputGoogleAutoComplete from 'src/components/ui/input-google-auto-complete'
import * as React from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { IInsuranceProvider } from 'src/_models/insurance-provider'
import { useState } from 'react'
import { toast } from 'src/components/ui/use-toast'

const ProfileInsuranceProviders = () => {
  const [insuranceProvider, setInsuranceProvider] = useState<string>('')
  const queryClient = useQueryClient()
  const { isLoading: isLoading, data: dataResp } = useQuery({
    queryKey: ['insurance-providers'],
    queryFn: (): Promise<
      AxiosResponse<{
        insuranceProviders: IInsuranceProvider[]
      }>
    > => {
      const token = localStorage.getItem('token')
      return axios.get('/api/supplier/insurance-providers', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const insuranceProviderMutation = useMutation({
    mutationFn: ({ name }: { name: string }) => {
      const token = localStorage.getItem('token')
      return axios.post(
        '/api/supplier/insurance-providers',
        { name },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })

  const insuranceProviderDeleteMutation = useMutation({
    mutationFn: ({ id }: { id: number }) => {
      const token = localStorage.getItem('token')
      return axios.delete('/api/supplier/insurance-providers/' + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const createInsuranceProvider = () => {
    if (!insuranceProvider) {
      toast({
        title: '',
        variant: 'destructive',
        description: 'Please provide a insurance provider name.',
      })
      return
    }
    if (insuranceProvider.length <= 1) {
      toast({
        title: '',
        variant: 'destructive',
        description: 'Please provide a insurance provider name.',
      })
      return
    }
    insuranceProviderMutation.mutate(
      { name: insuranceProvider },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['insurance-providers'])
          toast({
            title: '',
            description: 'Insurance provider created.',
          })
        },
        onError: () => {
          toast({
            title: '',
            variant: 'destructive',
            description: 'Insurance provider creation failed.',
          })
        },
      },
    )
  }

  const confirmDelete = (id: number) => {
    const confirmed = confirm('Are you sure you want to delete ?')
    if (confirmed) {
      insuranceProviderDeleteMutation.mutate(
        { id },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['insurance-providers'])
            toast({
              title: '',
              description: 'Insurance provider deleted.',
            })
          },
        },
      )
    }
  }

  return (
    <div className="flex w-full">
      <div className="relative mx-10 w-full max-w-[500px] 2xl:mx-20 2xl:max-w-[600px]">
        <div className="flex w-full flex-col gap-[30px]">
          <div className="flex flex-col gap-[10px]">
            <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">Insurance Provider</label>
            <Input value={insuranceProvider} onChange={(e) => setInsuranceProvider(e.target.value)} />
            <div className="absolute right-3 2xl:top-8 xl:top-9 w-auto px-5">
              <Button disabled={insuranceProviderMutation.isLoading} onClick={createInsuranceProvider}>
                Add
              </Button>
            </div>
          </div>

          {[...(dataResp?.data.insuranceProviders || [])].reverse().map((insuranceProvider, index) => (
            <div key={insuranceProvider.id} className="flex flex-col gap-[10px]">
              <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">
                Insurance Provider {index + 1}
              </label>
              <div className="relative flex w-full items-center align-middle">
                <Input
                  readOnly={true}
                  className="text-sm[appearance:textfield] border-1 relative flex w-full items-center border border-primary bg-white  py-[15px]  pr-20 text-left align-middle focus:outline-0 focus:ring-0"
                  value={insuranceProvider.name}
                />
                <div className="absolute right-3 w-auto px-5 py-[10px]">
                  <a className="cursor-pointer" onClick={() => confirmDelete(insuranceProvider.id)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17" fill="none">
                      <path
                        d="M1.83312 15.1111C1.83312 15.6121 2.03212 16.0925 2.38636 16.4468C2.74059 16.801 3.22104 17 3.72201 17H11.2776C11.7785 17 12.259 16.801 12.6132 16.4468C12.9674 16.0925 13.1665 15.6121 13.1665 15.1111V3.77778H1.83312V15.1111ZM3.72201 5.66667H11.2776V15.1111H3.72201V5.66667ZM10.8053 0.944444L9.86089 0H5.13867L4.19423 0.944444H0.888672V2.83333H14.1109V0.944444H10.8053Z"
                        fill="#333333"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="w-full max-w-[417px] 2xl:max-w-[417px]"></div>
    </div>
  )
}

export default ProfileInsuranceProviders
