import * as React from 'react'
import { useState } from 'react'
import { Input } from 'src/components/ui/input'
import { FieldError } from 'react-hook-form'

export interface InputPasswordProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: FieldError
}

const InputPassword = React.forwardRef<HTMLInputElement, InputPasswordProps>(({ id, name, error, ...props }, ref) => {
  const [inputType, setInputType] = useState<'password' | 'text'>('password')
  const passwordToggle = () => {
    setInputType((value) => {
      return value === 'password' ? 'text' : 'password'
    })
  }

  return (
    <div className="relative w-full">
      <Input
        id={id}
        name={name}
        type={inputType}
        autoComplete="current-password"
        placeholder="********"
        className="pe-12"
        error={error}
        {...props}
      />
      <span className="absolute -top-1 end-0 cursor-pointer rounded-e-md p-3.5" onClick={passwordToggle}>
        {inputType === 'text' ? (
          <img src="/icons/password-show.png" className="w-9" />
        ) : (
          <img src="/icons/password-no-show.png" className="w-9" />
        )}
      </span>
    </div>
  )
})

InputPassword.displayName = 'InputPassword'

export { InputPassword }
