import { Dispatch, SetStateAction, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { Button } from 'src/components/ui/button'
import { DialogClose } from 'src/components/ui/dialog'
import OrderDocumentStatus from 'src/components/order/document-status'
import OrderSignatureGuaranteeView from 'src/components/order/signature-guarantee-view'
import TotalPayableAmount from 'src/components/order/total-payable-amount'
import { IOrder } from 'src/_models/order.model'
import OrderSignatureGuarantee from 'src/components/order/signature-guarantee'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { toast } from 'src/components/ui/use-toast'

const BuyerOrderDeliveryConfirmation = ({ goNext, order }: { goNext: () => void; order: IOrder }) => {
  const canvasSignature = useRef<SignatureCanvas>(null)
  const [text, setText] = useState<string>('')
  const [file, setFile] = useState<File>()
  const [uploadMode, setUploadMode] = useState<'CANVAS' | 'INPUT' | 'FILE_UPLOAD' | null>(null)
  const queryClient = useQueryClient()
  // data.data.order.status === 'PRODUCT_SHIPPED' ||
  // data.data.order.status === 'DELIVERY_CONFIRMATION' ||
  // data.data.order.status === 'PRODUCT_CONFIRMATION'

  const orderDeliveryConfirmationTextMutation = useMutation({
    mutationKey: ['orderDeliveryConfirmationTextMutation'],
    mutationFn: ({ orderId, text }: { orderId: number; text: string }) => {
      const token = localStorage.getItem('token')
      return axios.post(
        order.status === 'PRODUCT_SHIPPED'
          ? `/api/buyer/order/delivery-confirmation/${orderId}/text`
          : `/api/buyer/order/product-confirmation/${orderId}/text`,
        { text },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })

  const orderDeliveryConfirmationFileMutation = useMutation({
    mutationKey: ['orderDeliveryConfirmationFileMutation'],
    mutationFn: ({ orderId, file }: { orderId: number; file: File }) => {
      const token = localStorage.getItem('token')
      const formData = new FormData()
      formData.append('file', file)
      return axios.post(
        order.status === 'PRODUCT_SHIPPED'
          ? `/api/buyer/order/delivery-confirmation/${orderId}/file`
          : `/api/buyer/order/product-confirmation/${orderId}/file`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })

  const deliveryConfirmation = () => {
    if (uploadMode === 'INPUT') {
      if (text.length < 0) {
        toast({
          title: 'Please enter something.',
        })
        return
      }
      orderDeliveryConfirmationTextMutation.mutate(
        { orderId: order.id, text },
        {
          onSuccess: () => {
            queryClient.invalidateQueries()
            setUploadMode(null)
            toast({
              title: 'Delivery confirmation done',
            })
          },
          onError: () => {
            toast({
              title: 'Delivery confirmation failed',
            })
          },
        },
      )
      return
    }
    let fileTemp: File
    if (uploadMode === 'CANVAS') {
      if (!canvasSignature.current) {
        toast({
          title: 'Unable to render canvas. Please try again.',
        })
        return
      }
      canvasSignature.current.getCanvas().toBlob((blob) => {
        if (!blob) {
          toast({
            title: 'Something went wrong.',
          })
          return
        }
        fileTemp = new File([blob], 'fileName.jpg', { type: 'image/jpeg' })
        orderDeliveryConfirmationFileMutation.mutate(
          { file: fileTemp, orderId: order.id },
          {
            onSuccess: () => {
              setUploadMode(null)
              queryClient.invalidateQueries()
              toast({
                title: 'Delivery confirmation done',
              })
            },
            onError: (err) => {
              const axiosError = err as AxiosError<{ message: string }>
              toast({
                title: axiosError?.response?.data?.message ?? 'Delivery confirmation failed',
                variant: 'destructive',
              })
            },
          },
        )
      })
    } else if (file) {
      orderDeliveryConfirmationFileMutation.mutate(
        { file, orderId: order.id },
        {
          onSuccess: () => {
            queryClient.invalidateQueries()
            toast({
              title: 'Delivery confirmation done',
            })
          },
          onError: (err) => {
            const axiosError = err as AxiosError<{ message: string }>
            toast({
              title: axiosError?.response?.data?.message ?? 'Delivery confirmation failed',
              variant: 'destructive',
            })
          },
        },
      )
    }
    // goNext()
  }

  return (
    <>
      <div className="flex max-h-[calc(90vh-538px)] flex-col overflow-y-scroll px-[30px]">
        <OrderDocumentStatus order={order} />
        {order.adminShipmentSentApprovalAt ? (
          <>
            {order.status !== 'PRODUCT_SHIPPED' ? (
              <OrderSignatureGuaranteeView
                order={order}
                type="SUPPLIER_DELIVERY_CONFIRMATION"
                title="Delivery Confirmation Signature"
                description="Please sign or upload signature to confirm that product has been delivered."
                orderGuaranteeText={order.supplierDeliveryConfirmationText}
                orderGuaranteeMediaKey={order.supplierDeliveryConfirmationMedia?.fileKey}
              />
            ) : (
              <OrderSignatureGuarantee
                canvasSignature={canvasSignature}
                text={text}
                setText={setText}
                file={file}
                setFile={setFile}
                uploadMode={uploadMode}
                setUploadMode={setUploadMode}
                order={order}
                type="BUYER"
                title="Delivery Confirmation Signature"
                description="Please sign or upload signature to confirm that product has been delivered."
              />
            )}
            {order.status !== 'DELIVERY_CONFIRMATION' ? (
              <OrderSignatureGuaranteeView
                order={order}
                type="SUPPLIER_PRODUCT_CONFIRMATION"
                title="Product Confirmation Signature"
                description="Please sign or upload signature to confirm that product has been verified."
                orderGuaranteeText={order.supplierProductConfirmationText}
                orderGuaranteeMediaKey={order.supplierProductConfirmationMedia?.fileKey}
              />
            ) : (
              <OrderSignatureGuarantee
                canvasSignature={canvasSignature}
                text={text}
                setText={setText}
                file={file}
                setFile={setFile}
                uploadMode={uploadMode}
                setUploadMode={setUploadMode}
                order={order}
                type="BUYER"
                title="Product Confirmation Signature"
                description="Please sign or upload signature to confirm that product has been verified."
              />
            )}
          </>
        ) : (
          <div className="text-center mt-5 text-orange">
            Pending approval from Admin for First Tranche fuel payment to Supplier
          </div>
        )}
      </div>
      <div className="mt-5  max-h-40 px-[30px]">
        <hr className=" mt-[10px] h-px border-0 bg-[#E0E0E0]" />
        <TotalPayableAmount order={order} />
        <div className="flex gap-5">
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          {order.status === 'PRODUCT_SHIPPED' && (
            <Button
              disabled={
                orderDeliveryConfirmationFileMutation.isLoading ||
                orderDeliveryConfirmationTextMutation.isLoading ||
                !order.adminShipmentSentApprovalAt
              }
              onClick={deliveryConfirmation}
            >
              Confirm Delivery
            </Button>
          )}
          {order.status === 'DELIVERY_CONFIRMATION' && (
            <Button
              disabled={
                orderDeliveryConfirmationFileMutation.isLoading ||
                orderDeliveryConfirmationTextMutation.isLoading ||
                !order.adminShipmentSentApprovalAt
              }
              onClick={deliveryConfirmation}
            >
              Confirm Product
            </Button>
          )}
        </div>
      </div>
    </>
  )
}

export default BuyerOrderDeliveryConfirmation
