import { format } from 'date-fns'
import { IFuelColor, IFuelType, IFuelWeightUnit } from 'src/_models/order.model'
import { t } from 'i18next'

export const formatAuctionDate = (date: string) => {
  return format(date, 'dd-MMM-yy hh:mm aa')
}

export const formatRecentUpdateDate = (date: string) => {
  return format(date, 'dd-MMM-yy')
}

export const formatRecentUpdateTime = (date: string) => {
  return format(date, 'hh:mm aa')
}

export const formatFuelColor = (fuelColor: IFuelColor) => {
  return fuelColor.name
}

export const formatFuelType = (fuelType: IFuelType) => {
  return fuelType.name
}

export const formatFuelWeightUnit = (fuelType: IFuelWeightUnit) => {
  return t('fuelWeightUnit.' + fuelType)
}
