import { UserTierBadge } from 'src/components/ui/user-tier-badge'
import * as React from 'react'
import {useTranslation} from "react-i18next";

const DashboardSharedTierStatus = ({ totalQuantity }: { totalQuantity: number }) => {
  const { t } = useTranslation('translation')
  // Silver Tier
  // They sell/buy more than 100,000 kg (100 tonnes/year) of Fuel, they become a Silver Tier customer
  //
  // Gold Tier
  // They sell/buy more than 1,000,000 kg (1000 tonnes/year) of Fuel
  //
  // Platinum Tier
  // They sell/buy more than 100,000,000kg (100,000 tonnes/year)
  const getTierStatusTag = () => {
    if (totalQuantity < 100_000) {
      return (
        <UserTierBadge variant="silver" className="text-center text-xs font-bold xl:py-[10px] 2xl:text-sm">
          Silver
        </UserTierBadge>
      )
    } else if (totalQuantity < 1_000_000) {
      return (
        <UserTierBadge variant="golden" className="text-center text-xs font-bold xl:py-[10px] 2xl:text-sm">
          Gold
        </UserTierBadge>
      )
    } else {
      return (
        <UserTierBadge variant="platinum" className="text-center text-xs font-bold xl:py-[10px] 2xl:text-sm">
          Platinum
        </UserTierBadge>
      )
    }
  }

  const getTotalAmountRequired = () => {
    if (totalQuantity < 100_000) {
      return ((100_000 - totalQuantity) / 1000) + ` ${t('fuelWeightUnit.TONNE')}`
    } else if (totalQuantity < 1_000_000) {
      return ((1_000_000 - totalQuantity) / 1000) + ` ${t('fuelWeightUnit.TONNE')}`
    } else {
      return '100,000 Ton'
    }
  }

  const getWidthPercentage = () => {
    if (totalQuantity < 100_000) {
      return (totalQuantity / 100_000) * 100
    } else if (totalQuantity < 1_000_000) {
      return (totalQuantity / 1_000_000) * 100
    } else {
      return (totalQuantity / 100_000_000) * 100
    }
  }

  return (
    <div className="shadow[0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)] mt-5 rounded-[20px] border-2 border-white bg-[#e9f9ff] p-5 2xl:p-[30px]">
      <div className="flex w-full items-center justify-between  align-middle">
        <div className="text-sm font-bold text-secoundary xl:mr-8 xl:leading-[18px] 2xl:text-lg 2xl:leading-[24px]">
          Your Current Tier Status
        </div>
        <div className="xl:w-[100px] 2xl:w-[159px]">{getTierStatusTag()}</div>
      </div>
      <div className="mt-5 flex w-full justify-between 2xl:mt-[30px]">
        <div className="text-xs font-medium 2xl:text-sm">Total volume traded</div>
        <div className="text-base font-normal  2xl:text-xl">{totalQuantity / 1000} {t('fuelWeightUnit.TONNE')}</div>
      </div>
      {totalQuantity < 1_000_000 && (
        <div className="my-[12px] w-full 2xl:my-[15px]">
          <div className="shadow-[0px 0px 3px 0px rgba(0, 0, 0, 0.30) inset] flex h-[5px] overflow-hidden rounded-[1.25rem] bg-[#F2F2F2]">
            <div
              style={{ width: `${getWidthPercentage()}%` }}
              className="flex flex-col justify-center whitespace-nowrap bg-[#19B200] text-center text-white shadow-none"
            ></div>
          </div>
        </div>
      )}

      {totalQuantity < 1_000_000 && (
        <div className="flex w-full items-center justify-between">
          <div className="text-xs font-medium 2xl:text-sm">Volume to reach next tier</div>
          <div className="text-base font-normal  2xl:text-xl">{getTotalAmountRequired()}</div>
        </div>
      )}
    </div>
  )
}

export default DashboardSharedTierStatus
