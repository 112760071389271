import { Button } from 'src/components/ui/button'
import * as React from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { ICard } from 'src/_models/card.model'
import { IStripeExternalAccount } from 'src/_models/stripe-external-account.model'
import { useNavigate } from 'react-router-dom'

const DashboardSharedPayment = () => {
  const navigate = useNavigate()
  const { isLoading: isLoading, data: dataCards } = useQuery({
    queryKey: ['paymentCardsQuery'],
    queryFn: (): Promise<
      AxiosResponse<{
        cards: ICard[]
        defaultPayment: ICard
      }>
    > => {
      const token = localStorage.getItem('token')
      return axios.get('/api/payments', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const { isLoading: isLoadingConnectBanks, data: dataConnectBanks } = useQuery({
    queryKey: ['externalAccountsQuery'],
    queryFn: (): Promise<
      AxiosResponse<{
        capabilities: { card_payments: 'active' | 'inactive'; transfers: 'active' | 'inactive' }
        future_requirements: {
          alternatives: string[]
          current_deadline: string | null
          currently_due: string | []
          disabled_reason: string | null
          errors: string[]
          eventually_due: string[]
          past_due: string[]
          pending_verification: string[]
        }
        externalAccounts: IStripeExternalAccount[]
        defaultExternalAccount: IStripeExternalAccount | null
      }>
    > => {
      const token = localStorage.getItem('token')
      return axios.get('/api/stripe/connect', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const onboardingMutation = useMutation({
    mutationKey: [''],
    mutationFn: ({ userId }: { userId: number | undefined }) => {
      const token = localStorage.getItem('token')
      return axios.post<{ onboardingUrl: string }>(
        '/api/stripe/connect/onboarding',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })

  const onboardingAction = () => {
    onboardingMutation.mutate(
      { userId: undefined },
      {
        onSuccess: (resp) => {
          window.open(resp.data.onboardingUrl, '_blank')
        },
        onError: () => {},
      },
    )
  }

  const stripeConnectApproved = dataConnectBanks?.data?.capabilities?.transfers == 'active'

  return (
    <div className="shadow[0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)] rounded-[20px] border-2 border-white bg-primary p-5 2xl:p-[30px]">
      <div className="mb-[10px] flex w-full flex-col 2xl:mb-5">
        <div className="mb-[10px] text-sm font-bold leading-[17px] text-white 2xl:text-lg 2xl:leading-[21px]">
          Accounts Receivable
        </div>
        {dataConnectBanks?.data &&
          (!stripeConnectApproved ? (
            <>
              <Button variant="secondary" disabled={onboardingMutation.isLoading} onClick={onboardingAction}>
                Onboard to Payment Provider
              </Button>
            </>
          ) : (
            <>
              {dataConnectBanks.data.defaultExternalAccount && (
                <div className="mb-[10px] flex w-full justify-between">
                  <div className="flex gap-[10px] align-middle">
                    <div className="2xl:line-height h-[19px] w-[19px] items-center rounded-sm bg-[#023047] p-[2px] text-center align-middle text-xs font-bold text-white">
                      D
                    </div>
                    <div className="2xl:line-height text-xs  font-bold leading-[14px] text-white 2xl:text-base">
                      ********{dataConnectBanks.data.defaultExternalAccount.last4}
                    </div>
                  </div>
                  <div className="2xl:line-height text-xs  font-bold leading-[14px] text-white 2xl:text-base">
                    {dataConnectBanks.data.defaultExternalAccount.bank_name}
                  </div>
                </div>
              )}
              {dataConnectBanks.data.externalAccounts[0] && (
                <div className="flex w-full justify-between">
                  <div className="flex gap-[10px]">
                    <div className="h-[19px] w-[19px]"></div>
                    <div className="line-height text-xs font-normal text-white 2xl:text-base">
                      ********{dataConnectBanks.data.externalAccounts[0].last4}
                    </div>
                  </div>
                  <div className="line-height text-xs font-normal text-white 2xl:text-base">
                    {dataConnectBanks.data.externalAccounts[0].bank_name}
                  </div>
                </div>
              )}
            </>
          ))}
      </div>

      {/* payable */}
      <div className="mb-[10px] flex w-full flex-col 2xl:mb-5">
        <div className="mb-[10px] text-sm font-bold leading-[17px] text-white 2xl:text-lg 2xl:leading-[21px]">
          Accounts Payable
        </div>
        {dataCards?.data && (
          <>
            {dataCards?.data.defaultPayment && (
              <div className="mb-[10px] flex w-full justify-between">
                <div className="flex gap-[10px] align-middle">
                  <div className="2xl:line-height h-[19px] w-[19px] items-center rounded-sm bg-[#023047] p-[2px] text-center align-middle text-xs font-bold text-white">
                    D
                  </div>
                  <div className="line-height text-sm font-bold text-white 2xl:text-base">
                    **** **** **** {dataCards?.data.defaultPayment.last4}
                  </div>
                </div>
                <div className="line-height text-sm font-bold text-white 2xl:text-base">
                  Exp. {dataCards?.data.defaultPayment.exp_month} / {dataCards?.data.defaultPayment.exp_year}
                </div>
              </div>
            )}
            {dataCards?.data.cards[0] && (
              <div className="flex w-full justify-between">
                <div className="flex gap-[10px]">
                  <div className="h-[19px] w-[19px]"></div>
                  <div className="line-height text-sm font-normal text-white 2xl:text-base">
                    **** **** **** {dataCards?.data.cards[0].last4}
                  </div>
                </div>
                <div className="line-height text-sm font-normal text-white 2xl:text-base">
                  Exp. {dataCards?.data.cards[0].exp_month} / {dataCards?.data.cards[0].exp_year}
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <Button
        onClick={() => navigate('/profile',  { state: { open_tab: 'payment-methods' } })}
        className="line-height bg-[#023047] font-medium text-white 2xl:py-3 2xl:text-sm"
      >
        Change Defaults
      </Button>
    </div>
  )
}

export default DashboardSharedPayment
