import { useEffect, useState } from 'react'
import { Button } from 'src/components/ui/button'
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover'
import { createSearchParams, useSearchParams } from 'react-router-dom'
import { FUEL_COLOR, FUEL_TYPE, fuelTypeEnum } from 'src/utils/utils'
import { z } from 'zod'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'src/components/ui/select'
import { useTranslation } from 'react-i18next'
import { Input } from 'src/components/ui/input'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { IPurityLevel } from 'src/_models/purity-level.model'
import { toast } from 'src/components/ui/use-toast'
import {IFuelColor, IFuelType} from "src/_models/order.model";
import MultipleSelector from "src/components/ui/multiselect";

type IAuctionFilter = {
  fuelColorIds: { value: string, label: string }[]
  fuelTypeIds: { value: string, label: string }[]
  fuelPurityLevelIds: { value: string, label: string }[]
  qtyMin: number | null
  qtyMax: number | null
  priceMin: number | null
  priceMax: number | null
  daysLeft: number | null
}

const AuctionFilterBtn = () => {
  const [purityLevels, setPurityLevels] = useState<IPurityLevel[]>([]);
  const { t } = useTranslation('translation')
  const { control, setValue, getValues, watch, handleSubmit, reset } = useForm<IAuctionFilter>({
    defaultValues: {
      fuelColorIds: [],
      fuelTypeIds: [],
      fuelPurityLevelIds: [],
      qtyMin: null,
      qtyMax: null,
      priceMin: null,
      priceMax: null,
      daysLeft: null,
    },
  })
  const [searchParams, setSearchParams] = useSearchParams()
  const [showFilter, setShowFilter] = useState<boolean>(false)

  const getFuelTypes = useQuery({
    queryKey: ['fuelTypes'],
    queryFn: (): Promise<AxiosResponse<{ fuelTypes: IFuelType[] }>> => {
      const token = localStorage.getItem('token')
      return axios.get('/api/fuelTypes', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
    enabled: true,
  })

  const getFuelColors = useQuery({
    queryKey: ['fuelColors'],
    queryFn: (): Promise<AxiosResponse<{ fuelColors: IFuelColor[] }>> => {
      const token = localStorage.getItem('token')
      return axios.get('/api/fuelColors', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
    enabled: true,
  })

  const fuelTypeWatch = watch('fuelTypeIds')

  const selectedFuelTypeIds = getValues().fuelTypeIds !== undefined && getValues().fuelTypeIds !== null && getValues().fuelTypeIds.length !== 0

  const purityLevelQuery = useQuery({
    queryKey: [`purityLevel-${fuelTypeWatch}`],
    queryFn: (): Promise<AxiosResponse<{ purityLevels: IPurityLevel[] }>> => {
      const fuelTypeIds = fuelTypeWatch
      const token = localStorage.getItem('token')
      return axios.get('/api/fuelPurityLevels/' + fuelTypeIds.map((fuelTypeId) => fuelTypeId.value.toString()).join(','), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: fuelTypeWatch.length > 0,
  })


  useEffect(() => {
    const fuelColorIds = searchParams.get('fuelColorIds')
    const fuelTypeIds = searchParams.get('fuelTypeIds')
    console.log('fuelColorIds', fuelColorIds);
    if (fuelColorIds) {
      const ids = fuelColorIds.split(',');
      const colors = getFuelColors.data?.data?.fuelColors.filter((f) => ids.indexOf(f.id.toString()) !== -1 )
      console.log('setValue(\'fuelColorIds\', colors?.map((c) => ({value: c.id.toString(), label: c.name})) || [])', ids, colors?.map((c) => ({value: c.id.toString(), label: c.name})));
      setValue('fuelColorIds', colors?.map((c) => ({value: c.id.toString(), label: c.name})) || [])
    }
    if (fuelTypeIds) {
      const ids = fuelTypeIds.split(',');
      const types = getFuelTypes.data?.data?.fuelTypes.filter((f) => ids.includes(f.id.toString()))
      setValue('fuelTypeIds', types?.map((c) => ({value: c.id.toString(), label: c.name})) || [])
    }
    // setValue('fuelPurityLevel', searchParams.get('fuelPurityLevel'))
    const qtyMin = searchParams.get('qtyMin')
    const qtyMax = searchParams.get('qtyMax')
    if (qtyMin) {
      setValue('qtyMin', parseInt(qtyMin))
    }
    if (qtyMax) {
      setValue('qtyMax', parseInt(qtyMax))
    }
    const priceMin = searchParams.get('priceMin')
    const priceMax = searchParams.get('priceMax')
    if (priceMin) {
      setValue('priceMin', parseInt(priceMin))
    }
    if (priceMax) {
      setValue('priceMax', parseInt(priceMax))
    }
    const daysLeft = searchParams.get('daysLeft')
    if (daysLeft) {
      setValue('daysLeft', parseInt(daysLeft))
    }
  }, [searchParams, getFuelColors.data, getFuelTypes.data])

  useEffect(() => {
    if(purityLevelQuery.data?.data?.purityLevels) {
      setPurityLevels(purityLevelQuery.data?.data.purityLevels)
    }
  }, [purityLevelQuery.data]);

  const applyFilters = (data: IAuctionFilter) => {
    if (data.qtyMin && data.qtyMax && data.qtyMin >= data.qtyMax) {
      toast({
        title: 'Error',
        description: 'Quantity Min should be less than Quantity Max',
        variant: 'destructive',
        duration: 3000,
      })
      return
    }
    if (data.priceMin && data.priceMax && data.priceMin >= data.priceMax) {
      toast({
        title: 'Error',
        description: 'Price Min should be less than Price Max',
        variant: 'destructive',
        duration: 3000,
      })
      return
    }
    console.log('data', data.fuelColorIds.map((fc) => fc.value))
    setSearchParams(
      createSearchParams({
        fuelColorIds: data.fuelColorIds.map((fc) => fc.value).join(','),
        fuelTypeIds: data.fuelTypeIds.map((fc) => fc.value).join(','),
        fuelPurityLevelIds: data.fuelPurityLevelIds ? data.fuelPurityLevelIds.map((fc) => fc.value).join(',') : '',
        qtyMin: data.qtyMin ? data.qtyMin.toString() : '',
        qtyMax: data.qtyMax ? data.qtyMax.toString() : '',
        priceMin: data.priceMin ? data.priceMin.toString() : '',
        priceMax: data.priceMax ? data.priceMax.toString() : '',
        daysLeft: data.daysLeft ? data.daysLeft.toString() : '',
      }).toString(),
    )
  }

  const clearFilters = () => {
    setSearchParams('')
    reset({})
    setShowFilter(false)
  }

  console.log('purityLevelQuery.data?.data?.purityLevels', purityLevelQuery.data?.data?.purityLevels.map((f) => ({label: f.purity, value: f.id.toString()})))

  // createSearchParams(params)

  return (
    <>
      <Popover open={showFilter} onOpenChange={setShowFilter}>
        <PopoverTrigger asChild>
          <Button variant="secondary" className="w-28">
            Filter&nbsp;By
          </Button>
        </PopoverTrigger>
        <PopoverContent align="end" className="w-72 rounded-[20px] p-0">
          <div className="flex flex-col gap-[10px] max-h-[60vh] overflow-y-scroll p-4">
            <div className="flex flex-col gap-[10px]">
              <label className="text-sm font-bold">Fuel Color</label>
              <Controller
                name="fuelColorIds"
                control={control}
                render={({ field }) => (
                  <MultipleSelector
                    defaultOptions={getFuelColors.data?.data?.fuelColors.map((f) => ({label: f.name, value: f.id.toString()}))}
                    placeholder="Select Fuel Color"
                    value={field.value || []}
                    onChange={(e) => field.onChange(e)}
                  />
                )}
              />
            </div>
            <div className="flex flex-col gap-[10px]">
              <label className="text-sm font-bold">Fuel Type</label>
              <Controller
                name="fuelTypeIds"
                control={control}
                render={({ field }) => (
                  <MultipleSelector
                    defaultOptions={getFuelTypes.data?.data?.fuelTypes.map((f) => ({label: f.name, value: f.id.toString()}))}
                    placeholder="Select Fuel Color"
                    value={field.value || []}
                    onChange={(e) => field.onChange(e)}
                  />
                )}
              />
            </div>
            {selectedFuelTypeIds && purityLevelQuery.isSuccess && purityLevels.length > 0 && (
              <div className="flex flex-col gap-[10px]">
                <label className="text-sm font-bold">Fuel Purity Level</label>
                <Controller
                  name="fuelPurityLevelIds"
                  control={control}
                  render={({ field }) => (
                    <MultipleSelector
                      defaultOptions={purityLevels.map((f) => ({label: f.purity, value: f.id.toString()}))}
                      placeholder="Select Purity Level"
                      value={field.value || []}
                      onChange={(e) => field.onChange(e)}
                    />
                  )}
                />
              </div>
            )}
            {/*<div className="flex flex-col gap-[10px]">*/}
            {/*  <label className="text-sm font-bold">Fuel Purity Level</label>*/}
            {/*  <Select>*/}
            {/*    <SelectTrigger className="h-[38px] w-full">*/}
            {/*      <SelectValue placeholder="Select" />*/}
            {/*    </SelectTrigger>*/}
            {/*    <SelectContent>*/}
            {/*      <SelectItem value="1998">1998</SelectItem>*/}
            {/*      <SelectItem value="1998">1998</SelectItem>*/}

            {/*      /!*<SelectItem value="1998">1998</SelectItem>*!/*/}
            {/*    </SelectContent>*/}
            {/*  </Select>*/}
            {/*</div>*/}
            <div className="flex flex-col gap-[10px]">
              <label className="text-sm font-bold">Quantity (KG)</label>
              <div className="flex gap-5">
                <div className="basis-1/2">
                  <Controller
                    name="qtyMin"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="Min"
                        {...field}
                        value={field.value || ''}
                        type="number"
                        className=" h-[38px] text-center [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                      />
                    )}
                  />
                </div>
                <div className="basis-1/2">
                  <Controller
                    name="qtyMax"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="Max"
                        {...field}
                        type="number"
                        value={field.value || ''}
                        className=" h-[38px] text-center [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-[10px]">
              <label className="text-sm font-bold">Days Left</label>
              <div className="flex gap-5">
                <Controller
                  name="daysLeft"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      value={field.value || ''}
                      className="h-[38px] w-full text-center [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex flex-col gap-[10px]">
              <label className="text-sm font-bold">Price</label>
              <div className="flex gap-5">
                <div className="basis-1/2">
                  <Controller
                    name="priceMin"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="Min"
                        {...field}
                        value={field.value || ''}
                        type="number"
                        className=" h-[38px] text-center [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                      />
                    )}
                  />
                </div>
                <div className="basis-1/2">
                  <Controller
                    name="priceMax"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="Max"
                        {...field}
                        type="number"
                        value={field.value || ''}
                        className=" h-[38px] text-center [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <Button onClick={handleSubmit(applyFilters)} className="w-full">
              Apply Filter
            </Button>
            <Button variant="secondary" onClick={clearFilters} className="w-full">
              Clear Filters
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </>
  )
}

export default AuctionFilterBtn
