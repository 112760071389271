import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/components/ui/table'
import { cn } from 'src/lib/utils'

interface DataTableProps<TData, TValue> {
  variant?: 'column-bg-grey'
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  headerHidden?: boolean
  className?: string
  emptyLabel?: string
}

export function DataTable<TData, TValue>({
  emptyLabel,
  variant,
  columns,
  data,
  headerHidden,
  className,
}: DataTableProps<TData, TValue>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <div className="rounded-md bg-none">
      <Table className={cn('', className)}>
        {!headerHidden && (
          <>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead
                        key={header.id}
                        className="bg px-0 py-[10px] text-center text-xs font-bold 2xl:mr-[33px] 2xl:py-[15px] 2xl:text-base"
                      >
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHead>
                    )
                  })}
                </TableRow>
              ))}
            </TableHeader>
            {/*<TableBody>*/}
            {/*  {table.getRowModel().rows?.length ? (*/}
            {/*    table.getRowModel().rows.map((row) => (*/}
            {/*      <TableRow key={row.id} className="bg-[#f2f2f2]" data-state={row.getIsSelected() && 'selected'}>*/}
            {/*        {row.getVisibleCells().map((cell) => (*/}
            {/*          <TableCell key={cell.id} className="px-4 py-4 text-center  text-base">*/}
            {/*            {}{' '}*/}
            {/*            {cell?.column?.columnDef?.accessorKey != 'action' ? (*/}
            {/*              flexRender(cell.column.columnDef.cell, cell.getContext())*/}
            {/*            ) : (*/}
            {/*              <a href="#" className="font-bold text-[#0077B6] underline">*/}
            {/*                View Order*/}
            {/*              </a>*/}
            {/*            )}*/}
            {/*          </TableCell>*/}
            {/*        ))}*/}
            {/*      </TableRow>*/}
            {/*    ))*/}
            {/*  ) : (*/}
            {/*    <TableRow>*/}
            {/*      <TableCell colSpan={columns.length} className="h-24 text-center">*/}
            {/*        No results.*/}
            {/*      </TableCell>*/}
            {/*    </TableRow>*/}
            {/*  )}*/}
            {/*</TableBody>*/}
          </>
        )}
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                className={cn(
                  'py-[10px] text-center text-xs font-medium 2xl:py-[15px] 2xl:text-base',
                  variant === 'column-bg-grey' && 'bg-[#F2F2F2]',
                )}
                data-state={row.getIsSelected() && 'selected'}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow className={variant === 'column-bg-grey' ? 'bg-[#F2F2F2]' : ''}>
              <TableCell colSpan={columns.length} className="h-4 text-center">
                {emptyLabel || 'No results.'}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}
