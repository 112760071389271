import { IFuelTradingOrderDocumentsType, IOrder } from 'src/_models/order.model'
import { useUserStore } from 'src/_store/user.store'
import { cn } from 'src/lib/utils'
import { Button } from 'src/components/ui/button'
import OrderDocumentsDownload from 'src/pages/fuel-trading/order-document-download'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { toast } from 'src/components/ui/use-toast'
import { useState } from 'react'
import ModalDocument from 'src/components/common/modalDocument'

const LoadingButtonAnimation = () => {
  return (
    <>
      <svg
        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>{' '}
      Loading
    </>
  )
}

const OrderDocumentStatus = ({ order }: { order: IOrder }) => {
  const [showUploadDocuments, setShowUploadDocuments] = useState<boolean>()
  const [documentUploadType, setDocumentUploadType] = useState<IFuelTradingOrderDocumentsType>()
  const { user } = useUserStore((state) => ({
    user: state.user,
  }))
  const { t } = useTranslation('translation')
  const queryClient = useQueryClient()

  const uploadFileToOrderMutation = useMutation({
    mutationFn: (data: { file: File }): Promise<AxiosResponse<{ companyDocs: any[] }>> => {
      const formData = new FormData()
      formData.append('file', data.file)
      const token = localStorage.getItem('token')
      return axios.post(`/api/order-document-upload/${order.id}/${documentUploadType}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const onFileSelection = (file: File) => {
    setShowUploadDocuments(false)
    uploadFileToOrderMutation.mutate(
      { file: file },
      {
        onSuccess: (resp) => {
          toast({
            title: 'File uploaded.',
          })
          queryClient.invalidateQueries()
        },
        onError: (err) => {
          const error = err as AxiosError<{ message: string }>
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description: t(error.response?.data?.message || error.message || 'err.somethingWrong'),
          })
        },
      },
    )
  }

  const contractAndOtherTransDisabled =
    ['DOCUMENT_UPLOAD', 'BUYER_ORDER_GUARANTEED', 'SELLER_ORDER_GUARANTEED'].indexOf(order.status) === -1

  const contractDocs = order.fuelTradingOrderDocuments.filter((fOD) => fOD.fuelTradingOrderDocumentType === 'CONTRACT')
  const contractDisabled =
    contractAndOtherTransDisabled ||
    (uploadFileToOrderMutation.isLoading && documentUploadType === 'CONTRACT') ||
    (contractDocs.length === 0 && user?.userType === 'BUYER')

  const contractLoading = uploadFileToOrderMutation.isLoading && documentUploadType === 'CONTRACT'

  const otherTransactionDocs = order.fuelTradingOrderDocuments.filter(
    (fOD) => fOD.fuelTradingOrderDocumentType === 'OTHER_TRANSACTION_DOCUMENT',
  )
  const otherTransDisabled =
    contractAndOtherTransDisabled ||
    (uploadFileToOrderMutation.isLoading && documentUploadType === 'OTHER_TRANSACTION_DOCUMENT') ||
    (otherTransactionDocs.length === 0 && user?.userType === 'BUYER')

  const otherTransLoading = uploadFileToOrderMutation.isLoading && documentUploadType === 'OTHER_TRANSACTION_DOCUMENT'

  const contractShippingStepDisabled =
    ['SHIPPING_CONTRACT_UPLOAD', 'SHIPPING_PAID', 'PRODUCT_SHIPPED'].indexOf(order.status) === -1

  // const shippingDataDisabledBuyer =
  //   contractShippingEnabled || order.shippingDocApproved || buyerDocumentApproveMutation.isLoading

  const shippingDataDisabled =
    contractShippingStepDisabled || (uploadFileToOrderMutation.isLoading && documentUploadType === 'SHIPPING_DOCUMENT')

  const shippingDataLoading = uploadFileToOrderMutation.isLoading && documentUploadType === 'SHIPPING_DOCUMENT'

  const shippingDocs = order.fuelTradingOrderDocuments.filter(
    (fOD) => fOD.fuelTradingOrderDocumentType === 'SHIPPING_DOCUMENT',
  )

  return (
    <>
      <div className="mb-[10px] text-sm font-bold 2xl:text-lg ">Contract &amp; Transaction Documents</div>

      <div
        className={cn(
          'mb-[10px] flex items-center justify-between align-middle',
          contractDocs.length === 0 && 'pl-[28px]',
        )}
      >
        <div className="flex gap-[6px] text-sm font-normal 2xl:text-base">
          {contractDocs.length > 0 && (
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path
                d="M22 10.5596L19.56 7.77961L19.9 4.09961L16.29 3.27961L14.4 0.0996094L11 1.55961L7.6 0.0996094L5.71 3.27961L2.1 4.08961L2.44 7.76961L0 10.5596L2.44 13.3396L2.1 17.0296L5.71 17.8496L7.6 21.0296L11 19.5596L14.4 21.0196L16.29 17.8396L19.9 17.0196L19.56 13.3396L22 10.5596ZM9 15.5596L5 11.5596L6.41 10.1496L9 12.7296L15.59 6.13961L17 7.55961L9 15.5596Z"
                fill="#00A206"
              />
            </svg>
          )}
          Contract
        </div>
        <div className="flex w-[170px] gap-[10px]">
          {/*{user.userType === 'BUYER' ? (*/}
          {/*  <Button disabled={contractDisabledBuyer} onClick={() => buyerDocumentApprove('CONTRACT')}>*/}
          {/*    Verify&nbsp;Doc*/}
          {/*  </Button>*/}
          {/*) : (*/}
          <Button
            disabled={contractDisabled}
            onClick={() => {
              setDocumentUploadType('CONTRACT')
              setShowUploadDocuments(true)
            }}
          >
            {contractLoading ? <LoadingButtonAnimation /> : <>Upload&nbsp;Doc</>}
          </Button>
          {/*)}*/}
          <OrderDocumentsDownload docType="Contract" disabled={false} orderDocuments={contractDocs} />
        </div>
      </div>
      <div
        className={cn(
          'mb-[10px] flex items-center justify-between align-middle',
          otherTransactionDocs.length === 0 && 'pl-[28px]',
        )}
      >
        <div className="flex gap-[6px] text-sm font-normal 2xl:text-base">
          {otherTransactionDocs.length > 0 && (
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path
                d="M22 10.5596L19.56 7.77961L19.9 4.09961L16.29 3.27961L14.4 0.0996094L11 1.55961L7.6 0.0996094L5.71 3.27961L2.1 4.08961L2.44 7.76961L0 10.5596L2.44 13.3396L2.1 17.0296L5.71 17.8496L7.6 21.0296L11 19.5596L14.4 21.0196L16.29 17.8396L19.9 17.0196L19.56 13.3396L22 10.5596ZM9 15.5596L5 11.5596L6.41 10.1496L9 12.7296L15.59 6.13961L17 7.55961L9 15.5596Z"
                fill="#00A206"
              />
            </svg>
          )}
          Other Transaction Documents
        </div>
        <div className="flex w-[170px] gap-[10px]">
          {/*{user.userType === 'BUYER' ? (*/}
          {/*  <Button*/}
          {/*    disabled={otherTransDisabledBuyer}*/}
          {/*    onClick={() => buyerDocumentApprove('OTHER_TRANSACTION_DOCUMENT')}*/}
          {/*  >*/}
          {/*    Verify&nbsp;Doc*/}
          {/*  </Button>*/}
          {/*) : (*/}
          <Button
            disabled={otherTransDisabled}
            onClick={() => {
              setDocumentUploadType('OTHER_TRANSACTION_DOCUMENT')
              setShowUploadDocuments(true)
            }}
          >
            {otherTransLoading ? <LoadingButtonAnimation /> : <>Upload&nbsp;Doc</>}
          </Button>
          {/*)}*/}
          <OrderDocumentsDownload
            docType="Other Transaction Documents"
            disabled={false}
            orderDocuments={otherTransactionDocs}
          />
        </div>
      </div>

      <div
        className={cn(
          'mb-[10px] flex items-center justify-between align-middle',
          shippingDocs.length === 0 && 'pl-[28px]',
        )}
      >
        <div className="flex gap-[6px] text-sm font-normal 2xl:text-base">
          {shippingDocs.length > 0 && (
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path
                d="M22 10.5596L19.56 7.77961L19.9 4.09961L16.29 3.27961L14.4 0.0996094L11 1.55961L7.6 0.0996094L5.71 3.27961L2.1 4.08961L2.44 7.76961L0 10.5596L2.44 13.3396L2.1 17.0296L5.71 17.8496L7.6 21.0296L11 19.5596L14.4 21.0196L16.29 17.8396L19.9 17.0196L19.56 13.3396L22 10.5596ZM9 15.5596L5 11.5596L6.41 10.1496L9 12.7296L15.59 6.13961L17 7.55961L9 15.5596Z"
                fill="#00A206"
              />
            </svg>
          )}
          Shipping Documents
        </div>
        <div className="flex w-[170px] gap-[10px]">
          {/*{user.userType === 'BUYER' ? (*/}
          {/*  <Button disabled={shippingDataDisabledBuyer} onClick={() => buyerDocumentApprove('SHIPPING_DOCUMENT')}>*/}
          {/*    Verify&nbsp;Doc*/}
          {/*  </Button>*/}
          {/*) : (*/}
          <Button
            disabled={shippingDataDisabled}
            onClick={() => {
              setDocumentUploadType('SHIPPING_DOCUMENT')
              setShowUploadDocuments(true)
            }}
          >
            {shippingDataLoading ? <LoadingButtonAnimation /> : <>Upload&nbsp;Doc</>}
          </Button>
          {/*)}*/}
          <OrderDocumentsDownload
            docType="Shipping Documents"
            disabled={
              order.fuelTradingOrderDocuments.filter((fOD) => fOD.fuelTradingOrderDocumentType === 'SHIPPING_DOCUMENT')
                .length === 0
            }
            orderDocuments={order.fuelTradingOrderDocuments.filter(
              (fOD) => fOD.fuelTradingOrderDocumentType === 'SHIPPING_DOCUMENT',
            )}
          />
        </div>
      </div>
      {showUploadDocuments && (
        <ModalDocument
          onCancel={() => setShowUploadDocuments(false)}
          onFileSelection={(file) => {
            onFileSelection(file)
            setShowUploadDocuments(false)
          }}
        ></ModalDocument>
      )}
    </>
  )
}

export default OrderDocumentStatus
