import * as React from 'react'
import { IAuction } from 'src/_models/auction.model'

const AuctionCardTopHeading = ({
  auction,
  highestBidCurrentUser,
}: {
  auction: IAuction
  highestBidCurrentUser?: boolean
}) => {
  if (highestBidCurrentUser) {
    return auction.auctionType === 'AUCTION' ? (
      <div className="mb-[5px] flex items-center justify-between rounded-[10px] bg-gray2 bg-opacity-20 px-2 py-2 align-middle text-sm font-bold text-secoundary 2xl:mb-[10px] 2xl:px-3">
        <span className="">
          <svg xmlns="http://www.w3.org/2000/svg" width="72" height="13" viewBox="0 0 87 13" fill="none">
            <path
              d="M0.900798 6.54395L6.6743 12.3174L12.4478 6.54395L6.6743 0.770442L0.900798 6.54395ZM6.6743 7.54395H86.6743V5.54395H6.6743V7.54395Z"
              fill="white"
            />
          </svg>
        </span>
        <span className="px-3 text-center text-xs font-bold text-white 2xl:text-sm 2xl:leading-[17px]">AUCTION</span>
        <span className="">
          <svg xmlns="http://www.w3.org/2000/svg" width="72" height="13" viewBox="0 0 87 13" fill="none">
            <path
              d="M86.0992 6.54395L80.3257 0.770442L74.5522 6.54395L80.3257 12.3174L86.0992 6.54395ZM0.325699 7.54395H80.3257V5.54395H0.325699V7.54395Z"
              fill="white"
            />
          </svg>
        </span>
      </div>
    ) : (
      <div className="mb-[8px] flex items-center justify-between rounded-[10px] bg-gray2 bg-opacity-20 px-2 py-2 text-center align-middle text-sm font-bold text-secoundary 2xl:mb-[10px] 2xl:px-3">
        <span className="">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="13" viewBox="0 0 57 13" fill="none">
            <path
              d="M0.400798 6.5L6.1743 12.2735L11.9478 6.5L6.1743 0.726497L0.400798 6.5ZM6.1743 7.5H56.1743V5.5H6.1743V7.5Z"
              fill="white"
            />
          </svg>
        </span>
        <span className="px-2 text-center text-xs font-bold text-white 2xl:text-sm 2xl:leading-[17px]">
          PURCHASE NOW
        </span>
        <span className="">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="13" viewBox="0 0 57 13" fill="none">
            <path
              d="M56.5992 6.5L50.8257 0.726497L45.0522 6.5L50.8257 12.2735L56.5992 6.5ZM0.825684 7.5H50.8257V5.5H0.825684V7.5Z"
              fill="white"
            />
          </svg>
        </span>
      </div>
    )
  }

  return auction.auctionType === 'AUCTION' ? (
    <div className="mb-[5px] flex items-center justify-between rounded-[10px] bg-gray2 px-2 py-2 text-center align-middle text-sm font-bold text-secoundary 2xl:mb-[10px] 2xl:px-3">
      <span className="">
        <svg xmlns="http://www.w3.org/2000/svg" width="72" height="13" viewBox="0 0 87 13" fill="none">
          <path
            d="M0.900798 6.54395L6.6743 12.3174L12.4478 6.54395L6.6743 0.770442L0.900798 6.54395ZM6.6743 7.54395H86.6743V5.54395H6.6743V7.54395Z"
            fill={highestBidCurrentUser ? 'white' : 'black'}
          />
        </svg>
      </span>
      <span className="px-3 text-center text-xs font-bold 2xl:text-sm 2xl:leading-[17px]">AUCTION</span>
      <span className="">
        <svg xmlns="http://www.w3.org/2000/svg" width="72" height="13" viewBox="0 0 87 13" fill="none">
          <path
            d="M86.0992 6.54395L80.3257 0.770442L74.5522 6.54395L80.3257 12.3174L86.0992 6.54395ZM0.325699 7.54395H80.3257V5.54395H0.325699V7.54395Z"
            fill="black"
          />
        </svg>
      </span>
    </div>
  ) : (
    <div className="mb-[5px] flex items-center justify-between rounded-[10px] bg-gray2 px-2 py-2 text-center align-middle text-sm font-bold text-secoundary 2xl:mb-[10px] 2xl:px-3">
      <span className="">
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="13" viewBox="0 0 57 13" fill="none">
          <path
            d="M0.400798 6.5L6.1743 12.2735L11.9478 6.5L6.1743 0.726497L0.400798 6.5ZM6.1743 7.5H56.1743V5.5H6.1743V7.5Z"
            fill="black"
          />
        </svg>
      </span>
      <span className="px-2 text-center text-xs  font-bold 2xl:text-sm 2xl:leading-[17px]">PURCHASE NOW</span>
      <span className="">
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="13" viewBox="0 0 57 13" fill="none">
          <path
            d="M56.5992 6.5L50.8257 0.726497L45.0522 6.5L50.8257 12.2735L56.5992 6.5ZM0.825684 7.5H50.8257V5.5H0.825684V7.5Z"
            fill="black"
          />
        </svg>
      </span>
    </div>
  )
}

export default AuctionCardTopHeading
