import { DataTable } from 'src/components/data-table'
import { columns } from 'src/components/columns'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { IOrder, ISupplierOrder } from 'src/_models/order.model'
import { useUserStore } from 'src/_store/user.store'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import DashboardOrdersTable from 'src/pages/fuel-trading/dashboard-orders-table'

const DashboardOrdersInProgressPage = () => {
  const orderInProgressQuery = useQuery({
    queryKey: ['orders', 'orders/in-progress'],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get<{ orders: IOrder[] }>('/api/orders/in-progress', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  return (
    <div className="w-1/2">
      {orderInProgressQuery.data && (
        <DashboardOrdersTable
          heading="Orders In Progress"
          url="/fuel-trading/orders-in-progress"
          orders={orderInProgressQuery.data.data.orders}
        />
      )}
    </div>
  )
}

export default DashboardOrdersInProgressPage
